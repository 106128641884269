import React from 'react';
import EnquireForm from '../components/EnquireForm';

function ContactPage() {
  return (
    <div className="container">
 
      <EnquireForm />
    </div>
  );
}

export default ContactPage;
