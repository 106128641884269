import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/healthwimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalised wellness gifts that reflect the recipient's preferences." },
    { title: "Branding on Gifts", detail: "Custom logos and branding elements that enhance your company's identity." },
    { title: "Value for Money", detail: "High-quality wellness products within your budget." },
    { title: "Concept Creation", detail: "Innovative wellness gift ideas that leave a lasting impact." },
    { title: "Diverse Range of Gifts", detail: "Extensive options for physical, mental, and nutritional wellness." },
    { title: "Quality Assurance", detail: "Premium products that meet rigorous quality standards." },
    { title: "Timely Delivery", detail: "Reliable logistics ensure your gifts arrive on time." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible wellness gifts." },
];
const HealthAndWellnessGifts = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
       
      }, []);
const faqs = [
   
      {
          "question": "What are the best health and wellness gifts for employees?",
          "answer": "The best health and wellness gifts for employees include personalised wellness hampers, branded fitness accessories, and mental health kits. These gifts cater to employees' well-being and promote a healthy lifestyle and work-life balance. Popular options include relaxation kits, healthy snacks, and fitness gear. Giftingy offers diverse wellness gifts tailored to suit your workforce's needs, ensuring they feel appreciated and motivated.",
          "open": false
      },
      {
          "question": "How do I choose the right health and wellness gifts for clients?",
          "answer": "Choosing the right health and wellness gifts for clients involves considering their preferences, the nature of your relationship, and the message you want to convey. Luxurious wellness hampers, personalised nutrition kits, and branded mental health products are thoughtful options. Giftingy's expertise in curating wellness gifts ensures that you select meaningful and impactful gifts that align with your brand's values and resonate with your clients.",
          "open": false
      },
      {
          "question": "What are some unique wellness gift ideas for corporate events?",
          "answer": "Unique wellness gift ideas for corporate events include eco-friendly wellness kits, branded meditation tools, and customised fitness accessories. Items like mental health journals, aromatherapy sets, and nutritious snacks are also popular. Giftingy provides innovative and unique health and wellness gifts for events that cater to diverse audiences, leaving a lasting impression on participants and guests.",
          "open": false
      },
      {
          "question": "Why are health and wellness gifts important in corporate culture?",
          "answer": "Health and wellness gifts are important in corporate culture because they promote a healthy lifestyle, boost morale, and demonstrate the company's commitment to employee well-being. These gifts help reduce stress, improve mental health, and encourage positive habits. Gifting wellness products also strengthens client relationships by showing care and consideration for their well-being. Giftingy's range of wellness gifts is designed to foster a positive and healthy corporate environment.",
          "open": false
      },
      {
          "question": "What are the benefits of branded health and wellness gifts?",
          "answer": "Branded health and wellness gifts enhance brand visibility while promoting well-being. These gifts, which include custom logos and messages, increase brand recall and create a positive association with your company. Branded wellness products like fitness gear, mental health kits, and nutrition packs reflect your brand's commitment to a healthy lifestyle. Giftingy offers a wide selection of branded wellness corporate gifts that can be customised to align with your brand and gifting strategy.",
          "open": false
      },
      {
          "question": "What are some luxury wellness gifts for corporate clients?",
          "answer": "Luxury wellness gifts for corporate clients include high-end spa kits, branded aromatherapy diffusers, premium organic skincare products, and customised wellness retreats. These gifts offer a sophisticated touch and show your clients you value their well-being. Giftingy specialises in sourcing exclusive and luxurious wellness items that cater to the tastes of high-profile clients, ensuring that your corporate relationships are strengthened through thoughtful and elegant gifting.",
          "open": false
      },
      {
          "question": "How do mental health gifts contribute to corporate gifting strategies?",
          "answer": "Mental health gifts contribute to corporate gifting strategies by promoting well-being, reducing stress, and encouraging mindfulness among recipients. Gifts like mental health journals, stress relief tools, and meditation kits demonstrate that your company cares about the overall well-being of employees and clients. Incorporating mental health gifts into your corporate gifting program creates a supportive environment that values mental wellness, leading to higher job satisfaction and stronger client relationships.",
          "open": false
      },
      {
          "question": "What are the best health and wellness gifts for special occasions?",
          "answer": "The best health and wellness gifts for special occasions include customised wellness hampers, branded fitness accessories, and relaxation kits. For festive events or company anniversaries, gifts such as luxury spa sets, organic health products, and personalised nutrition plans make meaningful and memorable presents. Giftingy offers a variety of wellness gifts that are perfect for celebrating special milestones while promoting a healthy and balanced lifestyle among your employees and clients.",
          "open": false
      },
      {
          "question": "How do branded wellness gifts benefit employee recognition programs?",
          "answer": "Branded wellness gifts benefit employee recognition programs by reinforcing your company's commitment to health and well-being. Personalised wellness items such as branded water bottles, fitness trackers, and relaxation kits help promote healthy habits while constantly reminding you of your brand's values. Giftingy offers a comprehensive range of branded wellness gifts that can be customised to fit your employee recognition programs, ensuring that your gifts are both practical and impactful.",
          "open": false
      },
      {
          "question": "What makes health and wellness gifts suitable for corporate events?",
          "answer": "Health and wellness gifts are suitable for corporate events because they cater to the well-being of participants and promote a healthy lifestyle. Whether it's a conference, seminar, or company celebration, gifting wellness products such as nutritious snacks, branded fitness gear, or stress-relief kits adds a thoughtful touch. Giftingy specialises in providing wellness gifts ideal for corporate events, ensuring that your guests leave with a positive impression and a commitment to better health.",
          "open": false
      },
      {
          "question": "What are some popular health and wellness gifts for employees in India?",
          "answer": "Popular health and wellness gifts for employees in India include branded yoga mats, herbal tea sets, personalised health supplements, and stress relief gadgets. These practical, thoughtful gifts cater to employees' diverse needs. Giftingy offers a curated selection of wellness gifts perfect for employee appreciation, recognition programs, and seasonal gifting, helping you promote a healthy and balanced lifestyle among your workforce.",
          "open": false
      },
      {
          "question": "Why are nutrition gifts important in corporate wellness programs?",
          "answer": "Nutrition gifts are important in corporate wellness programs because they encourage healthy eating habits and support overall well-being. Gifts like personalised nutrition plans, healthy snack boxes, and branded supplements contribute to a balanced diet and improved health. By incorporating nutrition gifts into your corporate wellness strategy, you promote a culture of health and well-being, leading to increased productivity and employee satisfaction. Giftingy provides a range of nutrition-focused wellness gifts that align with your corporate wellness goals.",
          "open": false
      },
      {
          "question": "How can luxury wellness gifts enhance client relationships?",
          "answer": "Luxury wellness gifts enhance client relationships by offering a premium experience that reflects your company's commitment to quality and care. High-end spa sets, branded wellness retreats, and personalised fitness gear show that you value your clients' well-being. Giftingy specialises in luxury wellness gifts perfect for impressing clients and building long-term partnerships. These exclusive gifts create a positive association with your brand and leave a lasting impression.",
          "open": false
      },
      {
          "question": "What are some unique wellness gift ideas for clients?",
          "answer": "Unique wellness gift ideas for clients include personalised meditation kits, branded stress-relief tools, and eco-friendly health products. Consider offering customised aromatherapy sets, luxury herbal teas, and holistic wellness plans. Giftingy offers various innovative and unique wellness gifts tailored to your clients' needs, ensuring that your gifts stand out and leave a lasting impact.",
          "open": false
      },
      {
          "question": "How do health and wellness gifts support employee engagement?",
          "answer": "Health and wellness gifts support employee engagement by promoting a culture of care and well-being within the organisation. Employees feel valued and appreciated when they receive gifts that focus on their health, such as fitness gear, relaxation tools, or mental health kits. This boosts morale, increases motivation, and fosters loyalty. Giftingy's range of health and wellness gifts enhances employee engagement, ensuring your team remains happy, healthy, and productive.",
          "open": false
      }
  
  
  ]
  
return (
<>
<MetaTags
        title="Premium Health and Wellness Gifts in India | Corporate Gifting Solutions"
        description="Discover health and wellness gifts for employees and clients. Giftingy offers branded wellness gifts, luxury options, and personalised health-related gifts."
        keywords="Health and wellness gifts, Health and Wellness Gifts in India, Health and wellness gifts for employees, Health and wellness gifts for clients, Health and wellness gifts for events, Health and wellness gifts as souvenirs, Luxury wellness gifts, Luxury wellness corporate gifts, Best wellness gifts, Health and wellness gifts for him, Health and wellness gifts for her, Health-related gifts, Nutrition gifts, Nutritionist gifts, Mental health gifts, Mental health gifts for her, Mental health gifts for men, Mental health gifts for kids, Branded wellness gifts, Branded wellness gifts corporate, Branded wellness gifts in bulk"
      />
<ServiceHeader title="Health and Wellness Gifts for Corporate Gifting" imageUrl={`${process.env.PUBLIC_URL}/images/healthwbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Growing Trend of Health and Wellness Gifts in Corporate Gifting</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Corporate gifting has evolved beyond traditional items, with health and wellness gifts gaining popularity. As companies increasingly prioritise employee well-being and healthy work culture, gifting products promoting mental, physical, and emotional wellness has become thoughtful and impactful. Offering health and wellness gifts for employees and clients showcases your commitment to their well-being, fostering loyalty and promoting a positive corporate environment.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      India's range of health and wellness gifts is diverse, from luxurious wellness hampers to customised products catering to individual needs. Whether you are looking for health and wellness gifts for events, souvenirs, or daily use, Giftingy offers a variety of options tailored to different preferences and occasions. The benefits of these gifts extend beyond simple gestures, contributing to better employee engagement, enhanced productivity, and stronger business relationships.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Here's why health and wellness gifts are essential in corporate gifting:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes Well-Being: Encourages a healthy lifestyle among employees and clients.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhances Engagement: Gifts that focus on health create a positive association with the company.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Boosts Morale: Thoughtful gifts show appreciation and care, boosting employee morale.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Customisation Options: Tailor the gifts to meet specific wellness needs and preferences.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Luxury Wellness Choices: Premium wellness gifts add a touch of elegance and exclusivity.</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Versatile for All Occasions: Suitable for events, recognition, or personal milestones.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Encourages Healthy Habits: Reinforces the company's commitment to employee health.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Branded Wellness Products: Strengthens brand visibility with customised wellness items.
               </p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Supports Holistic Wellness: Offers mental, physical, and nutritional well-being solutions.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes Long-Term Relationships: Regular gifting of wellness items maintains positive connections.</p>
            </li>
         </div>
      </ul>
    
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Health and Wellness Gifts?</h2>
      <p >Here's what makes Giftingy your preferred choice for health and wellness gifts:
      </p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/healthwimg2.jpg`} alt="Personalised Nutrition and Wellness Gifts" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy is the ideal partner for companies looking to incorporate health and wellness gifts for employees and clients into their corporate gifting strategy. We understand that well-being is a key factor in modern workplaces, and our curated selection of health and wellness gifts in India is designed to address this need. From premium luxury wellness corporate gifts to practical mental health and nutrition-based items, our offerings cater to diverse preferences and occasions.

               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               We specialise in creating tailored wellness hampers and personalised gifts that reflect your brand's commitment to health and well-being. As a leading provider of branded wellness gifts, we ensure each gift is meaningful and aligns with the company's values. Whether a simple mental health gift or a luxurious wellness set, our range includes thoughtful options that resonate with the recipient.
               </p>
            </div>
           
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customised Wellness Solutions: Personalise gifts based on the recipient's wellness needs and preferences.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive Product Range: From nutrition to mental health gifts, we have a wide selection for every need.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Branded Wellness Items: Enhance brand visibility with custom logos and branding.

            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Luxury Wellness Options: Offer premium wellness gifts that reflect exclusivity and sophistication.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expert Consultation: Our team helps you select the perfect wellness gifts based on your goals and audience.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Sustainable Choices: Choose eco-friendly and socially responsible wellness products.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable Delivery: We ensure timely delivery, whether bulk orders or individual gifts.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Tailored Gifting Experience: Our solutions cater to various events, employee recognition programs, and client appreciation initiatives.
            </p>
         </div>
         <p className='textWhite'>By choosing Giftingy for your health and wellness gifts, you will promote a healthier work culture and create lasting connections with your employees and clients.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Source for Exceptional Indian Traditional Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we bring a unique approach to corporate gifting by offering comprehensive health and wellness gifts that cater to your employees' and clients' needs. Our USPs make us a leading choice for health and wellness corporate gifting:
            </p>
           
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/healthwimg3.jpg`} alt="Branded Wellness Gifts for Employees" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="HealthAndWellnessGifts" />

<FaqPage faqs={faqs} />
</>
);
};
export default HealthAndWellnessGifts;