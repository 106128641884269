import React from 'react';
import '../components/InfoSection.css';

const InfoSection = () => {
  return (
    <div  className="info-section ">
  

  <div>
   
        
    <div class="value-corporate-gifting-section">
        <h2>Value of Corporate Gifting for Employees and Clients</h2>
        <p>Corporate gifts are an excellent way to show appreciation and foster loyalty among employees and clients. Here are some benefits of corporate gifting:</p>
        
        <div className="benefits">
         <div className='row '>  
        <div className='col-md-6'>
       
            <div className="benefits-section">
                
            <h3>    <img className='iconss' src="/images/employeesicon-removebg-preview.png" alt="Client Icon" /> For Employees</h3>
                <ul>
                    <li><b>Boosts Morale</b> <br></br> Recognizing achievements and milestones increases motivation and job satisfaction.</li>
                    <li><b>Encourages Productivity</b> <br></br> Thoughtful gifts can inspire employees to perform better.</li>
                    <li><b>Enhances Retention</b> <br></br> Employees feel valued and are more likely to stay with the company.</li>
                </ul>
            </div>
            </div>
            <div className='col-md-6'>
            

            <div className="benefits-section">
               
                <h3><img className='iconss' src="/images/clienticon-removebg-preview.png" alt="Client Icon" /> For Clients</h3>
                <ul>
                    <li><b>Builds Loyalty</b> <br></br> Personalized gifts show clients that you value their partnership.</li>
                    <li><b>Strengthens Relationships</b> <br></br> Regular gifting helps maintain and improve business relationships.</li>
                    <li><b>Promotes Goodwill</b> <br></br> Thoughtful gifts create a positive impression and foster goodwill.</li>
                </ul>
            </div>
            </div> 
            </div>
        </div>
    </div>

</div>


    </div>
  );
};

export default InfoSection;
