import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import './popup.css'; // Import the CSS file for styling
import Loader from './Loader'; // Import the Loader component

const Popup = ({ showPopup, togglePopup }) => {
  const [loading, setLoading] = useState(false); // State to control loader visibility
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.className === 'popup') {
        togglePopup();
      }
    };

    if (showPopup) {
      window.addEventListener('click', handleOutsideClick);

      // Load HubSpot form script and initialize form
      const script = document.createElement('script');
      script.charset = 'utf-8';
      script.type = 'text/javascript';
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      script.onload = () => {
        if (window.hbspt && window.hbspt.forms) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "2400919",
            formId: "8c4d11d1-62da-4efd-8c1e-018391bd6ddf",
            target: '#hubspot-form' // Ensure the form is injected into the correct element
          });
        } else {
          console.error('HubSpot form script failed to load.');
        }
      };
      script.onerror = () => {
        console.error('Failed to load HubSpot form script.');
      };
      document.body.appendChild(script);
    }

    return () => {
      window.removeEventListener('click', handleOutsideClick);
      // Clean up script if needed
      const existingScript = document.querySelector('script[src="//js.hsforms.net/forms/embed/v2.js"]');
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, [showPopup, togglePopup]);

  if (!showPopup) return null;

  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-btn" onClick={togglePopup}>&times;</button>
        <div id="hubspot-form" className="hubspot-form-container">
          {/* HubSpot form will be injected here */}
        </div>
      </div>
      {loading && <Loader />} {/* Show loader if loading state is true */}
    </div>
  );
};

export default Popup;
