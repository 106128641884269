import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/womenimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Wide Range of Options", detail: "From small tokens to luxury hampers, we offer a variety of gift ideas to suit different tastes and budgets." },
    { title: "Customisation", detail: "Personalise your gifts with your company logo, a special message, or the recipient’s name." },
    { title: "High-Quality Products", detail: "We source only the finest products to ensure your gifts are well-received and appreciated." },
    { title: "Timely Delivery", detail: "We ensure your gifts are delivered on time, whether for a single recipient or in bulk." },
    { title: "Expert Guidance", detail: "Our team provides specialist advice to help you choose the perfect gifts that align with your corporate values." },
    { title: "Eco-Friendly Options", detail: "Choose from sustainable and environmentally friendly gift options to align with your company’s green initiatives." },
    { title: "Tailored Packaging", detail: "We offer customized packaging solutions that add a personal touch and enhance the presentation of your gifts." },
    { title: "Flexible Budgeting", detail: "We work within your budget to provide options that maximize impact without compromising on quality." },
];
const WomensDayCorporateGift = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
      }, []);
const faqs =[
    {
        "question": "What are some popular Women's Day corporate gift ideas?",
        "answer": "Popular Women's Day corporate gift ideas include customised gift hampers, branded luxury items, tech gadgets, wellness kits, and eco-friendly gifts. These gifts cater to different tastes and budgets, making them suitable for many recipients. Small tokens like personalised keychains or mini planners are also great for budget-conscious gifting. Luxury options like designer handbags, jewellery, or high-end tech gadgets are perfect for top performers or high-profile clients. Giftingy offers various Women's Day gifts that can be customised to reflect your brand and the recipient's preferences.",
        "open": false
    },
    {
        "question": "Why is it important to give corporate gifts on Women's Day?",
        "answer": "Giving corporate gifts on Women's Day is important because it shows appreciation and recognition for the contributions of female employees, clients, and partners. It helps boost morale, strengthen relationships, and foster a positive and inclusive work environment. Corporate gifts on Women's Day also demonstrate your company's commitment to gender equality and inclusivity. Thoughtful gifting can enhance job satisfaction and loyalty, making it a valuable investment for your organisation. By acknowledging Women's Day, you celebrate diversity and empower the women in your workplace.",
        "open": false
    },
    {
        "question": "What are some affordable Women's Day gifts under 500?",
        "answer": "Affordable Women's Day gifts under 500 include personalised keychains, bookmarks, mini planners, and eco-friendly stationery. Other options are scented candles, small potted plants, and reusable shopping bags. These small tokens are budget-friendly yet meaningful, showing appreciation for the recipient's contributions. Giftingy offers a range of affordable Women's Day gifts that can be customised to add a personal touch. These gifts are perfect for companies celebrating Women's Day without exceeding their budget.",
        "open": false
    },
    {
        "question": "How can I customise Women's Day corporate gifts?",
        "answer": "Customizing Women's Day corporate gifts involves adding personal touches such as the recipient's name, a special message, or your company logo. Items like gift hampers, branded accessories, and tech gadgets can all be personalised to make the gift more meaningful and memorable. Customisation enhances the gift's value and reinforces your brand's identity. Giftingy offers extensive customisation options for all Women's Day gifts, ensuring that your gifts stand out and resonate with the recipient.",
        "open": false
    },
    {
        "question": "What are some luxury Women's Day gifts for young females?",
        "answer": "Luxury Women's Day gifts for young females include designer handbags, premium skincare sets, jewellery, and high-end tech gadgets. These gifts are perfect for recognising top performers or expressing gratitude to valued clients. Other luxury options include exclusive experiences like spa days, fine dining, or weekend getaways. Giftingy offers a curated selection of luxury Women's Day gifts that can be customised to reflect the recipient's preferences. These gifts are a token of appreciation and a way to create lasting memories and strengthen professional relationships.",
        "open": false
    },
    {
        "question": "What makes Women's Day corporate gifts unique?",
        "answer": "Women's Day corporate gifts are unique because they are designed to celebrate and empower the women in your organisation. These gifts are often personalised and thoughtful, reflecting the company's appreciation for the recipient's contributions. Unique Women's Day gifts may include customised tech gadgets, wellness kits, or eco-friendly products that align with the recipient's interests. Giftingy offers a variety of unique Women's Day gifts that cater to different tastes and preferences, making each gift special and memorable.",
        "open": false
    },
    {
        "question": "Can I order Women's Day gift hampers in bulk?",
        "answer": "You can order bulk Women's Day gift hampers from Giftingy. Bulk ordering is ideal for large organisations that want to celebrate Women's Day across multiple departments or locations. Giftingy offers a variety of Women's Day gift hampers that can be customised and ordered in bulk to meet your needs. Bulk orders ensure consistency in quality and presentation, making your Women's Day celebration seamless and impactful. Giftingy also provides timely delivery services to ensure your hampers arrive on time.",
        "open": false
    },
    {
        "question": "What are some Women's Day gift ideas for a tech team?",
        "answer": "Women's Day gift ideas for a tech team include unique tech gadgets like wireless earbuds, smartwatches, portable chargers, and customisable phone stands. These gifts are practical and align with the interests of tech-savvy recipients. Other options include personalised tech accessories like branded USB drives or customised laptop sleeves. Giftingy offers a range of tech-focused Women's Day gifts that can be customised to reflect your brand and the recipient's preferences. These gifts are perfect for celebrating the contributions of your tech team on Women's Day.",
        "open": false
    },
    {
        "question": "How do Women's Day gifts contribute to a positive work environment?",
        "answer": "Women's Day gifts contribute to a positive work environment by showing appreciation and recognition for female employees' contributions. Thoughtful gifting boosts morale, fosters a sense of belonging, and promotes a culture of inclusivity and respect. Employees who feel valued are more motivated and engaged, leading to increased productivity and job satisfaction. Giftingy offers a variety of Women's Day gifts that help create a positive and supportive workplace, making your celebration of Women's Day impactful and meaningful.",
        "open": false
    },
    {
        "question": "What are some Women's Day gifts for safety and well-being?",
        "answer": "Women's Day gifts for safety and well-being include wellness kits, personal safety alarms, eco-friendly water bottles, and fitness bands. These gifts show that you care about the health and safety of your female employees and clients. Wellness kits can include yoga mats, essential oils, and mindfulness journals, promoting self-care and mental well-being. Giftingy offers a range of Women's Day gifts that focus on safety and well-being, ensuring that your gifts are thoughtful and relevant to the recipient's needs.",
        "open": false
    },
    {
        "question": "What are the best Women's Day gift ideas for a remote team?",
        "answer": "The best Women's Day gift ideas for a remote team include digital gift cards, wellness kits delivered to their homes, personalised e-cards, and virtual experiences such as online classes or workshops. These gifts can help foster connection and appreciation even when employees work from different locations. Other ideas include sending a care package with snacks, stationery, or tech gadgets. Giftingy offers a range of customisable Women's Day gifts suitable for remote teams, ensuring they feel valued and celebrated.",
        "open": false
    },
    {
        "question": "How can I choose a Women's Day gift that reflects my company's values?",
        "answer": "To select a Women's Day gift that reflects your company’s values, consider gifts that align with your brand’s commitment to sustainability, wellness, or innovation. For example, eco-friendly products or wellness kits can focus on health and sustainability. High-tech gadgets can represent innovation and forward-thinking. Giftingy offers various Women's Day corporate gifts that can be customised to align with your company's values, helping you make a meaningful impact.",
        "open": false
    },
    {
        "question": "What are some Women's Day gifts that promote work-life balance?",
        "answer": "Some examples include wellness kits, meditation apps, ergonomic office accessories, and personalised planners. These gifts help employees manage their time effectively and encourage relaxation and self-care. Giftingy provides a selection of Women's Day gifts designed to promote work-life balance, making them ideal for supporting the well-being of your female employees.",
        "open": false
    },
    {
        "question": "How do I select the right Women's Day gift for different age groups?",
        "answer": "Selecting the right Women's Day gift for different age groups involves understanding the preferences and needs of each demographic. Younger employees might appreciate tech gadgets or trendy accessories, while older employees prefer wellness kits or personalised stationery. Giftingy offers a diverse range of Women's Day gifts catering to different age groups, ensuring each recipient feels appreciated.",
        "open": false
    },
    {
        "question": "What are some eco-friendly Women's Day gift options?",
        "answer": "Eco-friendly Women's Day gift options include reusable water bottles, bamboo stationery, organic skincare products, and eco-friendly tote bags. These gifts are sustainable and reflect your company's commitment to environmental responsibility. Giftingy offers a variety of eco-friendly Women's Day gifts that can be customised to include your company logo, making them a thoughtful choice for environmentally conscious organisations.",
        "open": false
    },
    {
        "question": "Can Women's Day gifts be used to promote company culture?",
        "answer": "Yes, Women's Day gifts can encourage company culture by reinforcing the values and principles that your organisation stands for. Customised gifts that reflect your company's mission and vision help build a sense of belonging and pride among employees. Giftingy offers a range of customisable Women's Day gifts that align with your company culture, making them an effective tool for employee engagement and brand reinforcement.",
        "open": false
    },
    {
        "question": "What are some unique Women's Day gift ideas for creative teams?",
        "answer": "Unique Women's Day gift ideas for creative teams include art supplies, personalised sketchbooks, creative software subscriptions, and DIY craft kits. These gifts encourage creativity and allow team members to explore their artistic talents. Giftingy offers a variety of innovative Women's Day gifts that can be customised to reflect the unique preferences of your creative team, making them feel appreciated and inspired.",
        "open": false
    },
    {
        "question": "What are some budget-friendly Women's Day gifts for large organisations?",
        "answer": "Budget-friendly Women's Day gifts for large organisations include branded stationery, customised mugs, small plant pots, and personalised notebooks. These items are cost-effective yet thoughtful, making them suitable for bulk gifting. Giftingy provides a range of affordable Women's Day gifts that can be customised and ordered in large quantities, ensuring that every employee receives a meaningful token of appreciation.",
        "open": false
    },
    {
        "question": "How can I ensure my Women's Day gifts are inclusive and respectful?",
        "answer": "To ensure Women's Day gifts are inclusive and respectful, choose items that cater to diverse preferences and avoid stereotypes. Customisation should be sensitive and considerate of cultural and personal differences. Giftingy offers a variety of Women's Day gifts that can be tailored to individual tastes and values, helping you provide thoughtful and respectful gifts that celebrate all women in your organisation.",
        "open": false
    },
    {
        "question": "What are some creative packaging ideas for Women's Day gifts?",
        "answer": "Creative packaging ideas for Women's Day gifts include custom gift boxes, eco-friendly wrapping paper, personalised gift bags, and decorative ribbons. Packaging can enhance the overall presentation and make the gift feel more special. Giftingy offers a range of packaging options that can be customised to match your brand's identity and the theme of your Women's Day celebration.",
        "open": false
    },
    {
        "question": "How do I ensure Women's Day gifts are delivered on time?",
        "answer": "Ensuring Women's Day gifts are delivered on time involves planning, placing orders early, and choosing a reliable company. Giftingy offers a seamless ordering process and reliable delivery services to ensure that your Women's Day gifts arrive on time, regardless of location. We also provide tracking options so you can monitor your order’s progress.",
        "open": false
    },
    {
        "question": "What are some Women's Day gifts that can be shared among teams?",
        "answer": "Women's Day gifts that can be shared among teams include gourmet snack boxes, office plants, team-building kits, and shared experience vouchers like virtual escape rooms. These gifts promote teamwork and camaraderie while celebrating Women's Day. Giftingy offers a range of Women's Day gifts designed for team sharing, making them perfect for fostering collaboration and connection.",
        "open": false
    },
    {
        "question": "Can Women's Day gifts be used for client appreciation?",
        "answer": "Women's Day gifts can be an excellent way to show appreciation to female clients. Personalised gifts, luxury hampers, and branded merchandise are all thoughtful ways to express gratitude and strengthen business relationships. Giftingy offers a wide selection of Women's Day corporate gifts tailored for client appreciation, ensuring your clients feel valued and respected.",
        "open": false
    },
    {
        "question": "What are some Women's Day gifts for remote employees?",
        "answer": "Women's Day gifts for remote employees include digital gift cards, wellness subscriptions, personalised e-cards, and care packages delivered to their homes. These gifts help bridge the distance and make remote employees feel connected and appreciated. Giftingy provides a range of Women's Day gifts suitable for remote employees, ensuring they feel included in the celebration.",
        "open": false
    },
    {
        "question": "How can I incorporate company branding into Women's Day gifts?",
        "answer": "You can incorporate company branding into Women's Day gifts by adding your company logo, using brand colors, and including branded packaging. Personalised messages and company mottos can also reinforce your brand identity. Giftingy offers extensive customisation options to help you create branded Women's Day gifts that resonate with your employees and clients.",
        "open": false
    },
    {
        "question": "What are some tech-focused Women's Day gift ideas?",
        "answer": "Tech-focused Women's Day gift ideas include smartwatches, wireless chargers, Bluetooth speakers, and personalised tech accessories like phone cases and laptop sleeves. These gifts are practical and align with the interests of tech-savvy recipients. Giftingy offers a variety of tech-themed Women's Day gifts that can be customised to reflect your brand and the recipient’s preferences.",
        "open": false
    },
    {
        "question": "How do Women's Day gifts impact employee retention?",
        "answer": "Women's Day gifts can positively impact employee retention by showing appreciation and making employees feel valued. Thoughtful and personalised gifts contribute to job satisfaction and loyalty, key factors in employee retention. Giftingy provides a range of Women's Day gifts that help foster a positive work environment and encourage long-term commitment from your employees.",
        "open": false
    },
    {
        "question": "What are some eco-friendly Women's Day gift packaging options?",
        "answer": "Eco-friendly Women's Day gift packaging options include reusable gift bags, biodegradable wrapping paper, recycled paper boxes, and plantable seed paper tags. These options reduce environmental impact while presenting your gifts thoughtfully and sustainably. Giftingy offers eco-friendly packaging solutions for all Women's Day gifts, ensuring that your gifts are as sustainable as beautiful.",
        "open": false
    },
    {
        "question": "How do I choose Women's Day gifts for a large and diverse workforce?",
        "answer": "Choosing Women's Day gifts for a large and diverse workforce involves selecting universally appreciated gifts, such as wellness kits, personalised stationery, or digital gift cards. Offering a range of gift options or allowing employees to choose from a selection can also ensure everyone receives a gift they will enjoy. Giftingy provides a variety of Women's Day gifts that cater to diverse preferences, making it easy to find the perfect gift for every employee.",
        "open": false
    },
    {
        "question": "What are some luxury Women's Day gifts for top executives?",
        "answer": "Luxury Women's Day gifts for top executives include designer handbags, fine jewellery, luxury skincare sets, and exclusive experiences like spa retreats or gourmet dining. These gifts are perfect for recognising the contributions of high-level female executives. Giftingy offers a curated selection of luxury Women's Day gifts that can be personalised and customised to make a lasting impression.",
        "open": false
    },
    {
        "question": "How can I ensure Women's Day gifts are inclusive and appropriate?",
        "answer": "Ensuring Women's Day gifts are inclusive and proper involves choosing gifts that respect cultural diversity and personal preferences. Avoiding overly personal or potentially sensitive items is key to maintaining professionalism. Giftingy offers a wide range of Women's Day gifts designed to be inclusive and respectful, ensuring all recipients feel valued and appreciated.",
        "open": false
    },
    {
        "question": "What are some personalised Women's Day gift ideas for a creative team?",
        "answer": "Personalised Women's Day gift ideas for a creative team include custom sketchbooks, branded art supplies, personalised notebooks, and DIY craft kits. These gifts encourage creativity and allow team members to explore their artistic talents. Giftingy offers a variety of creative Women's Day gifts that can be customised to reflect the unique preferences of your creative team.",
        "open": false
    },
    {
        "question": "How can Women's Day gifts enhance corporate branding?",
        "answer": "Women's Day gifts can enhance corporate branding by incorporating your company logo, using brand colours, and including a personalised message that reflects your company’s values. Branded gifts reinforce your brand identity and leave a lasting impression on recipients. Giftingy provides extensive customisation options to help you create Women's Day gifts that align with your brand and make a meaningful impact.",
        "open": false
    },
    {
        "question": "What are some Women's Day gifts that support work-life balance?",
        "answer": "Women's Day gifts that support work-life balance include wellness kits, ergonomic office accessories, meditation apps, and personalised planners. These gifts help employees manage their time effectively and encourage relaxation and self-care. Giftingy provides a selection of Women's Day gifts designed to promote work-life balance, making them ideal for supporting the well-being of your female employees.",
        "open": false
    },
    {
        "question": "How do I choose a Women's Day gift for a diverse team?",
        "answer": "Choosing a Women's Day gift for a diverse team involves selecting universally appreciated gifts, such as personalised stationery, wellness kits, or digital gift cards. Offering a range of gift options or allowing employees to choose from a selection can also ensure everyone receives a gift they will enjoy. Giftingy provides a variety of Women's Day gifts that cater to diverse preferences, making it easy to find the perfect gift for every employee.",
        "open": false
    },
    {
        "question": "What are some budget-friendly Women's Day gifts for a large team?",
        "answer": "Budget-friendly Women's Day gifts for a large team include branded pens, mini plants, personalised bookmarks, and eco-friendly tote bags. These affordable yet meaningful gifts allow you to celebrate Women's Day across a large workforce without exceeding your budget. Giftingy offers a range of budget-friendly Women's Day gifts that can be customised and ordered in bulk, ensuring consistency and quality.",
        "open": false
    },
    {
        "question": "How can I choose eco-friendly Women's Day gifts?",
        "answer": "Choosing eco-friendly Women's Day gifts involves selecting items from sustainable materials, such as reusable water bottles, bamboo stationery, organic skincare products, and eco-friendly tote bags. These gifts reflect your company’s commitment to environmental responsibility and sustainability. Giftingy offers a variety of eco-friendly Women's Day gifts that can be customised to include your company logo, making them a thoughtful choice for environmentally conscious organisations.",
        "open": false
    },
    {
        "question": "How can I ensure Women's Day gifts are inclusive and appropriate?",
        "answer": "Ensuring Women's Day gifts are inclusive and proper involves choosing gifts that respect cultural diversity and personal preferences. Avoiding overly personal or potentially sensitive items is key to maintaining professionalism. Giftingy offers a wide range of Women's Day gifts designed to be inclusive and respectful, ensuring all recipients feel valued and appreciated.",
        "open": false
    },
    {
        "question": "What are some personalised Women's Day gift ideas for a creative team?",
        "answer": "Personalised Women's Day gift ideas for a creative team include custom sketchbooks, branded art supplies, personalised notebooks, and DIY craft kits. These gifts encourage creativity and allow team members to explore their artistic talents. Giftingy offers a variety of creative Women's Day gifts that can be customised to reflect the unique preferences of your creative team.",
        "open": false
    },
    {
        "question": "How can Women's Day gifts enhance corporate branding?",
        "answer": "Women's Day gifts can enhance corporate branding by incorporating your company logo, using brand colours, and including a personalised message that reflects your company’s values. Branded gifts reinforce your brand identity and leave a lasting impression on recipients. Giftingy provides extensive customisation options to help you create Women's Day gifts that align with your brand and make a meaningful impact.",
        "open": false
    },
    {
        "question": "What are some Women's Day gifts that support work-life balance?",
        "answer": "Women's Day gifts that support work-life balance include wellness kits, ergonomic office accessories, meditation apps, and personalised planners. These gifts help employees manage their time effectively and encourage relaxation and self-care. Giftingy provides a selection of Women's Day gifts designed to promote work-life balance, making them ideal for supporting the well-being of your female employees.",
        "open": false
    },
    {
        "question": "How do I choose a Women's Day gift for a diverse team?",
        "answer": "Choosing a Women's Day gift for a diverse team involves selecting universally appreciated gifts, such as personalised stationery, wellness kits, or digital gift cards. Offering a range of gift options or allowing employees to choose from a selection can also ensure everyone receives a gift they will enjoy. Giftingy provides a variety of Women's Day gifts that cater to diverse preferences, making it easy to find the perfect gift for every employee.",
        "open": false
    },
    {
        "question": "What are some budget-friendly Women's Day gifts for a large team?",
        "answer": "Budget-friendly Women's Day gifts for a large team include branded pens, mini plants, personalised bookmarks, and eco-friendly tote bags. These affordable yet meaningful gifts allow you to celebrate Women's Day across a large workforce without exceeding your budget. Giftingy offers a range of budget-friendly Women's Day gifts that can be customised and ordered in bulk, ensuring consistency and quality.",
        "open": false
    },
    {
        "question": "How can I choose eco-friendly Women's Day gifts?",
        "answer": "Choosing eco-friendly Women's Day gifts involves selecting items from sustainable materials, such as reusable water bottles, bamboo stationery, organic skincare products, and eco-friendly tote bags. These gifts reflect your company’s commitment to environmental responsibility and sustainability. Giftingy offers a variety of eco-friendly Women's Day gifts that can be customised to include your company logo, making them a thoughtful choice for environmentally conscious organisations.",
        "open": false
    },
    {
        "question": "What are some digital Women's Day gift options for remote employees?",
        "answer": "Digital Women's Day gift options for remote employees include e-gift cards, digital subscriptions, online courses, and personalised e-cards. These gifts are convenient and can be delivered directly to employees' inboxes, making them perfect for remote teams. Giftingy offers a range of digital Women's Day gifts that can be customised and personalised to make remote employees feel appreciated and connected.",
        "open": false
    },
    {
        "question": "What are some Women's Day gifts that can be used year-round?",
        "answer": "Women's Day gifts that can be used year-round include personalised planners, reusable water bottles, desk organisers, and wellness kits. These practical gifts are appreciated throughout the year and constantly remind you of your company's appreciation. Giftingy offers a variety of Women's Day gifts designed for year-round use, making them a meaningful and lasting gesture.",
        "open": false
    },
    {
        "question": "How can I ensure Women's Day gifts are delivered on time?",
        "answer": "Ensuring Women's Day gifts are delivered on time involves planning, placing orders early, and choosing a reliable company. Giftingy offers a seamless ordering process and reliable delivery services to ensure that your Women's Day gifts arrive on time, regardless of location. We also provide tracking options so you can monitor your order’s progress.",
        "open": false
    },
    {
        "question": "What are some luxury Women's Day gifts for high-level executives?",
        "answer": "Luxury Women's Day gifts for high-level executives include designer handbags, fine jewellery, luxury skincare sets, and exclusive experiences like spa retreats or gourmet dining. These gifts are perfect for recognising the contributions of high-level female executives. Giftingy offers a curated selection of luxury Women's Day gifts that can be personalised and customised to make a lasting impression.",
        "open": false
    },
    {
        "question": "How can Women's Day gifts promote inclusivity in the workplace?",
        "answer": "Women's Day gifts encourage inclusivity in the workplace by recognising and celebrating the contributions of female employees. Thoughtful and personalised gifts help to create a culture of respect and appreciation, fostering a sense of belonging and unity. Giftingy offers a variety of Women's Day gifts designed to be inclusive and respectful, ensuring that all recipients feel valued and appreciated.",
        "open": false
    },
    {
        "question": "What are some Women's Day gifts that promote health and wellness?",
        "answer": "Wellness kits, fitness trackers, healthy snack boxes, and organic skincare products are some examples. These gifts show that you care about your employees' well-being and encourage a healthy lifestyle. Giftingy offers a variety of health-focused Women's Day gifts that can be personalised to reflect your company’s commitment to wellness and employee health.",
        "open": false
    },
    {
        "question": "What are some Women's Day gifts that reflect company culture?",
        "answer": "Women's Day gifts that reflect company culture include branded merchandise, custom stationery, and personalised office supplies that align with your company's values and mission. These gifts help reinforce company culture and connect employees to the organisation. Giftingy offers a range of customisable Women's Day gifts that can be tailored to reflect your company culture and make a meaningful impact.",
        "open": false
    },
    {
        "question": "How do I choose a Women's Day gift for a multicultural team?",
        "answer": "Choosing a Women's Day gift for a multicultural team involves selecting universally appreciated and culturally appropriate gifts. Options include personalised stationery, wellness kits, or digital gift cards that cater to a diverse workforce. Giftingy offers a variety of Women's Day gifts designed to be inclusive and respectful, ensuring that all recipients feel valued and appreciated.",
        "open": false
    },
    {
        "question": "What are some unique Women's Day gift ideas for a creative team?",
        "answer": "Unique Women's Day gift ideas for a creative team include custom sketchbooks, branded art supplies, personalised notebooks, and DIY craft kits. These gifts encourage creativity and allow team members to explore their artistic talents. Giftingy offers a variety of creative Women's Day gifts that can be customised to reflect the unique preferences of your creative team.",
        "open": false
    },
    {
        "question": "How can I make Women's Day gifts more personal?",
        "answer": "You can make Women's Day gifts more personal by adding the recipient’s name, a thoughtful message, or a custom design that reflects their personality or interests. Personalisation shows that you’ve put thought into the gift, making it more meaningful and appreciated. Giftingy offers extensive customisation options to help you create personalised Women's Day gifts that leave a lasting impression.",
        "open": false
    },
    {
        "question": "What are some Women's Day gifts that support work-life balance?",
        "answer": "Women's Day gifts that support work-life balance include wellness kits, ergonomic office accessories, meditation apps, and personalised planners. These gifts help employees manage their time effectively and encourage relaxation and self-care. Giftingy provides a selection of Women's Day gifts designed to promote work-life balance, making them ideal for supporting the well-being of your female employees.",
        "open": false
    },
    {
        "question": "How can I choose Women's Day gifts for remote employees?",
        "answer": "Choosing Women's Day gifts for remote employees involves selecting items easily delivered to their homes, such as digital gift cards, wellness kits, or personalised e-cards. These gifts help remote employees feel connected and appreciated, even from a distance. Giftingy offers a range of Women's Day gifts suitable for remote employees, ensuring they feel included in the celebration.",
        "open": false
    },
    {
        "question": "What are some Women's Day gifts that can be shared among teams?",
        "answer": "Women's Day gifts that can be shared among teams include gourmet snack boxes, office plants, team-building kits, and shared experience vouchers like virtual escape rooms. These gifts promote teamwork and camaraderie while celebrating Women's Day. Giftingy offers a range of Women's Day gifts designed for team sharing, making them perfect for fostering collaboration and connection.",
        "open": false
    }
]

return (
<>
<MetaTags
        title="Thoughtful Women's Day Corporate Gift Ideas | Giftingy"
        description="Discover a wide range of Women's Day corporate gift ideas. Giftingy offers customised and branded gifts from luxury hampers to small tokens for employees and clients."
        keywords="Women's Day corporate gift ideas, Women's Day corporate gifting, Women's Day corporate gifting company, Women's Day corporate gift company, Women's Day corporate gifts, Small gift ideas for Women's Day, Women's Day gift hampers, Women's Day gift hampers bulk, Women's Day gift under 500, Women's Day gift under 1000, Women's Day gift under 2000, Luxury Women's Day gift, Branded Women's Day gift, Customised Women's Day gift, Luxury Women's Day gift for young females, Women's Day corporate gifts employees, Women's Day gift ideas for employees, Women's Day corporate gifts for event, Women's Day corporate gifts for clients, Unique Women's Day gift, Unique women's day gift for tech team, Unique Women's Day gift for safety, Women's Day gift ideas for employees"
      />

<ServiceHeader title="Women's Day Corporate Gift Ideas: Celebrate and Empower" imageUrl={`${process.env.PUBLIC_URL}/images/womenbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Significance of Women's Day</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Celebrate and empower the incredible women in your organisation with thoughtful Women's Day corporate gifts. Women's Day is an opportunity to show appreciation and recognition to female employees, clients, and partners who contribute significantly to your business. With a wide range of gift options, from small token gifts to luxury hampers, you can find the perfect way to honour the women in your workplace.
      </p>
     
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
    <h5>Why Women's Day Corporate Gifting Matters</h5>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Corporate gifting on Women's Day is more than just a gesture; it's an opportunity to express gratitude, boost morale, and strengthen professional relationships. Thoughtful gifts can make female employees feel valued and appreciated, increasing motivation and loyalty. Women's Day gifts can also foster a positive work environment and highlight your company's commitment to gender equality and inclusivity.
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Recognition and Appreciation: Show your female employees and clients that their contributions are valued.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Boost Morale: Thoughtful gifts can enhance job satisfaction and motivation.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Strengthen Relationships: Gifting helps in building and maintaining strong professional relationships.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promote Inclusivity: Celebrate diversity and inclusivity in your workplace.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Brand Visibility: Customised and branded gifts help reinforce your company’s brand.</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Encourage Empowerment: Empower the women in your organisation by recognising their achievements.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Celebrate Achievements: Use this occasion to celebrate the accomplishments of your female team members.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Support Personal Well-being: Gifts that cater to self-care and wellness show a genuine concern for employees’ overall well-being.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhance Team Unity: Inclusive gifting fosters a sense of belonging and unity within the team.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Align with Corporate Values: Demonstrates your company’s commitment to values like gender equality and social responsibility.</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>Choosing the right Women's Day gifts can make a lasting impact on your employees and clients, creating a positive and inclusive workplace culture.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Top Women's Day Corporate Gift Ideas</h2>
      <p >Finding the perfect Women's Day corporate gift requires understanding your female employees' and clients' preferences and needs. Here are some popular Women's Day corporate gift ideas that cater to different tastes and budgets:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/womenimg2.jpg`} alt="High-End Branded Women's Day Gifts for Employees" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <div className="box-content ">
              <ul>
                <li>
                Customised Gift Hampers: Personalised hampers filled with curated items like skincare products, chocolates, and scented candles.
                </li>
                <li>
                Branded Luxury Items: High-end branded gifts like designer handbags, branded watches, or jewellery.
                </li>
                <li>
                Tech Gadgets: Provide unique tech gifts, such as smartwatches or wireless earbuds, for the tech-savvy women in your organisation.

                </li>
                <li>
                Gift Vouchers: Offer flexibility with gift vouchers for online stores or wellness retreats.
                </li>
                </ul>
               </div>
            </div>
            <div className="box box-one wow fadeInUp">
               <div className="box-content">
               <ul>
                <li>
                Wellness Kits: Promote self-care with wellness kits that include yoga mats, essential oils, and herbal teas.
                </li>
                <li>
                Eco-Friendly Gifts: Sustainable gifts like reusable water bottles, eco-friendly stationery, or organic skincare products.

                </li>
                <li>
                Small Token Gifts: Affordable yet meaningful gifts under 500, such as personalised keychains, bookmarks, or mini planners.
                </li>
                </ul>
               </div>
            </div>
        
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
    <h5> Luxury Women's Day Corporate Gifts</h5>
     <p className='textWhite'>
     For those looking to make a grand gesture, luxury Women's Day gifts are an excellent choice. These gifts are perfect for recognising top-performing employees or expressing gratitude to high-profile clients. Luxury gifts could include:
     </p>
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Designer Handbags: A stylish and practical gift that any woman would appreciate.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Premium Skincare Sets: High-quality skincare products that offer a touch of luxury.

            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Jewellery: Timeless pieces like bracelets, necklaces, or earrings that make a lasting impression.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Exclusive Experiences: Gift an unforgettable experience, such as a spa day, fine dining, or a weekend getaway.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-End Tech Gadgets: The latest gadgets like premium smartwatches, noise-cancelling headphones, or fitness trackers.
            </p>
         </div>
         
         <p className='textWhite'>These luxury gifts are a token of appreciation and a way to create lasting memories and deepen professional relationships.
         </p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Customizing Women's Day Gifts with Giftingy</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we specialise in creating personalised and customised Women's Day corporate gifts that resonate with your recipients. Whether you’re looking for small gift ideas for Women's Day or luxury Women's Day gifts for young females, we offer a range of options to suit your needs. Our team works closely with you to design and customise gifts that reflect your brand and the unique preferences of your employees and clients.
            </p>
           
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/womenimg3.jpg`} alt="Luxury Women's Day Corporate Gifts" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="luxuryHomeDecor" />

<FaqPage faqs={faqs} />
</>
);
};
export default WomensDayCorporateGift;