import React from 'react';
import PropTypes from 'prop-types';
import './CardFlip.css'; // Import the page-specific CSS

const CardFlip = ({ features }) => {
    return (
        <section className='cardFsection'>
            <div className="container givespace">
                <h2 className="section-title2">Our Gift Features</h2>
                <div className="row">
                    {features.map((feature, index) => (
                        <div key={index} className="col-md-6 col-lg-3 mb-4">
                            <div className="card">
                                <div className="card-inner">
                                    <div className="card-front">
                                        <h3>{feature.title}</h3>
                                    </div>
                                    <div className="card-back">
                                        <p className='letterspaces'>{feature.detail}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

CardFlip.propTypes = {
    features: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            detail: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default CardFlip;
