import React, { useState } from 'react';
import './ThreeColumnsSection.css';
import Popup from './Popup'; // Import the Popup component

const data = [
  {
    id: 1,
    title: 'Home Gifting',
    description: 'Elevate your space with thoughtfully curated home gifting options that add style and comfort.',
    image: '/images/pick1.jpg'
  },
  {
    id: 2,
    title: 'Plants and Eco Gifting',
    description: 'Sustainable and nature-inspired gifts that bring positivity and greenery to any environment.',
    image: '/images/pick2.jpg'
  },
  {
    id: 3,
    title: 'Elite Gifting',
    description: 'Exclusive and luxurious gifting options that make a lasting impression and reflect sophistication.',
    image: '/images/pick3.jpg'
  }
];

function ThreeColumnsSection() {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="three-columns-container">
      <h2 className="three-columns-header">
        <i className="fas fa-crown"></i> Challenge Us to Craft Special Gifting for Your Unique Needs <i className="fas fa-crown"></i>
      </h2>
      <div className="three-columns-section">
        {data.map(item => (
          <div key={item.id} className="column-box">
            <img src={item.image} alt={item.title} />
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {/* <button className="view-more-btn" onClick={togglePopup}>Enquire Now</button> */}
          </div>
        ))}
      </div>

      {/* <Popup showPopup={showPopup} togglePopup={togglePopup} /> */}
    </div>
  );
}

export default ThreeColumnsSection;
