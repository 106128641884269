import React from 'react';
import '../components/CategorySection.css'; // Make sure to create this CSS file

const categories = [
  { icon: '/images/giftham.jpg', label: 'GIFTS FOR HER' },
  { icon: '/images/giftham1.jpg', label: 'DRYFRUITS' },
  { icon: '/images/giftham7.jpg', label: 'MODERN LAMPS' },
  { icon: '/images/giftham4.jpg', label: 'CHOCOLATES' },
  { icon: '/images/giftham5.jpg', label: 'GOLF GIFTING' },
];

const CategorySection = () => {
    return (
      <div className="container mt-5">
        <div className="text-center mb-4">
          <h2>Get branded and personalised gifts</h2>
          <p className="text-muted">Be known and remembered for thoughtful gifting</p>
        </div>
        <div className="row justify-content-center twoinMobile">
          {categories.map((category, index) => (
            <div
              key={index}
              className={`unique-col-mobile col-6 col-md-4 col-lg-2  text-center ${category.label.toLowerCase().replace(/\s+/g, '-')}`}
            >
              <div className="unique-card unique-hover-card h-100">
                <img
                  src={category.icon}
                  alt={category.label}
                  className="card-img-top img-fluid"
                />
                <div className="card-body">
                  <p className="unique-card-text">{category.label}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default CategorySection;
