const pdfs = [
  { 
  title: 'Rings', 
  url: `${process.env.PUBLIC_URL}/Pdfs/Bron.pdf`,
  imageUrl: `${process.env.PUBLIC_URL}/images/wedding-rings.png`  // Add your image URL here
  },
  { 
  title: 'Necklaces', 
  url: `${process.env.PUBLIC_URL}/Pdfs/MiGifting Catalogue 2024-2025 July.pdf.pdf`,
  imageUrl: `${process.env.PUBLIC_URL}/images/necklace.png`  // Add your image URL here
  },
  { 
  title: 'Braceletes', 
  url: `${process.env.PUBLIC_URL}/Pdfs/Bron.pdf`,
  imageUrl: `${process.env.PUBLIC_URL}/images/bracelet.png`  // Add your image URL here
  },
  { 
  title: 'Earrings', 
  url: `${process.env.PUBLIC_URL}/Pdfs/Bron.pdf`,
  imageUrl: `${process.env.PUBLIC_URL}/images/earrings.png`  // Add your image URL here
  },
  { 
  title: 'Watches', 
  url: `${process.env.PUBLIC_URL}/Pdfs/Bron.pdf`,
  imageUrl: `${process.env.PUBLIC_URL}/images/watch.png`  // Add your image URL here
  },
  { 
  title: 'Brooches', 
  url: `${process.env.PUBLIC_URL}/Pdfs/Bron.pdf`,
  imageUrl: `${process.env.PUBLIC_URL}/images/brooch.png`  // Add your image URL here
  },
  { 
  title: 'Rings', 
  url: `${process.env.PUBLIC_URL}/Pdfs/Bron.pdf`,
  imageUrl: `${process.env.PUBLIC_URL}/images/wedding-ring.png`  // Add your image URL here
  },
  { 
  title: 'Necklaces', 
  url: `${process.env.PUBLIC_URL}/Pdfs/MiGifting Catalogue 2024-2025 July.pdf.pdf`,
  imageUrl: `${process.env.PUBLIC_URL}/images/wedding-ring.png`  // Add your image URL here
  },
  { 
  title: 'Braceletes', 
  url: `${process.env.PUBLIC_URL}/Pdfs/Bron.pdf`,
  imageUrl: `${process.env.PUBLIC_URL}/images/wedding-ring.png`  // Add your image URL here
  },
  { 
  title: 'Earrings', 
  url: `${process.env.PUBLIC_URL}/Pdfs/Bron.pdf`,
  imageUrl: `${process.env.PUBLIC_URL}/images/wedding-ring.png`  // Add your image URL here
  },
  { 
  title: 'Watches', 
  url: `${process.env.PUBLIC_URL}/Pdfs/Bron.pdf`,
  imageUrl: `${process.env.PUBLIC_URL}/images/wedding-ring.png`  // Add your image URL here
  },
  { 
  title: 'Brooches', 
  url: `${process.env.PUBLIC_URL}/Pdfs/Bron.pdf`,
  imageUrl: `${process.env.PUBLIC_URL}/images/wedding-ring.png`  // Add your image URL here
  },
  // Add more PDFs here with correct title, URL, and image URL
  ];
  export default pdfs;