import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/teaimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Customization", detail: "Personalized tea gift hampers tailored to your brand and recipient's preferences." },
    { title: "Cultural Significance", detail: "Gifts that celebrate the rich tradition of tea." },
    { title: "Premium Quality", detail: "Only the finest teas from renowned regions are selected for our corporate gifts." },
    { title: "Sustainability", detail: "Sourced from organic and ethical tea plantations, supporting sustainable practices." },
    { title: "Timely Delivery", detail: "Reliable and timely delivery across India, ensuring your gifts arrive in perfect condition." },
    { title: "Customer Satisfaction", detail: "Committed to excellence in both product quality and service." },
    { title: "Quality Assurance", detail: "Each gift undergoes rigorous quality checks to ensure excellence." },
    { title: "Eco-Friendly Options", detail: "Choose from our range of sustainable and responsible gifting options." },
   
];
const TeaCorporateGifts = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
       
      }, []);
const faqs = [
    {
        "question": "Why is tea a popular choice for corporate gifts?",
        "answer": "Tea is popular for corporate gifts because it combines cultural significance with health benefits. It is a versatile gift for people of all ages and backgrounds. Tea also has a calming and comforting effect, making it a thoughtful gesture for employees, clients, and business partners. Additionally, tea gifts can be customized to reflect the company's branding and the recipient's preferences, making them personalized and memorable gifts.",
        "open": false
    },
    {
        "question": "What types of tea corporate gifts does Giftingy offer?",
        "answer": "Giftingy provides a wide range of tea corporate gifts, including gourmet tea hampers, premium tea gift sets, assorted tea gift sets, and exotic tea gift sets. Our collection features teas from renowned regions such as Darjeeling and Assam and various herbal and green teas. We also provide customization options for packaging and branding, allowing you to create a unique and personalized gift that reflects your company's values and the recipient's tastes.",
        "open": false
    },
    {
        "question": "How can tea corporate gifts be customized?",
        "answer": "Tea corporate gifts can be customized in several ways to suit the occasion and the recipient. Customization options include selecting specific types of tea, personalizing the packaging with the company logo or a message, and creating bespoke tea blends tailored to the recipient's preferences. Giftingy offers a range of customization options, ensuring that your tea gifts are unique and memorable. Whether you're gifting employees or clients, customized tea hampers add a special touch to the corporate gifting experience.",
        "open": false
    },
    {
        "question": "What are the benefits of choosing tea corporate gifts for employees?",
        "answer": "Tea corporate gifts are excellent for employees as they promote wellness and relaxation. Offering a selection of high-quality teas encourages employees to take a break and enjoy a moment of calm during their busy workday. Additionally, tea gifts reflect the company's appreciation for their hard work and dedication. Tea is also a versatile gift that can be enjoyed by all employees, making it a thoughtful and inclusive choice for corporate gifting.",
        "open": false
    },
    {
        "question": "Can tea corporate gifts be ordered in bulk for large events?",
        "answer": "Tea corporate gifts can be ordered in bulk for large events such as corporate gatherings, conferences, or festive celebrations. Giftingy offers bulk ordering options for tea hampers, gift sets, and customized tea gifts, ensuring that all recipients receive the same high-quality product. Bulk orders often come with discounts, making them a cost-effective option for large-scale corporate gifting. We also provide timely delivery and consistent quality across all orders, making the process seamless and hassle-free.",
        "open": false
    },
    {
        "question": "Why should I choose Giftingy for tea corporate gifts?",
        "answer": "Giftingy is a leading tea corporate gifting company known for its premium quality products, customization options, and excellent customer service. We source our teas from the finest regions in India, ensuring that each gift is of the highest standard. Our team works closely with clients to create personalized and memorable tea gifts that reflect their brand and values. Whether you need tea gifts for employees, clients, or corporate events, Giftingy offers a comprehensive solution that meets your needs.",
        "open": false
    },
    {
        "question": "What makes Darjeeling tea a special choice for corporate gifts?",
        "answer": "Darjeeling tea is known as the 'Champagne of Teas' due to its exquisite flavour and aroma. It is one of the most sought-after teas globally, making it a prestigious choice for corporate gifts. Gifting Darjeeling tea reflects a commitment to quality and sophistication, making it a meaningful gesture for clients and business partners. Its unique flavour profile and premium status make Darjeeling tea a standout choice for corporate gifting, especially when packaged in elegant and customized gift sets.",
        "open": false
    },
    {
        "question": "How can tea corporate gifts promote wellness?",
        "answer": "Tea corporate gifts promote wellness by offering health benefits associated with various types of tea, such as green tea, herbal tea, and black tea. These teas are rich in antioxidants, support digestion, and have calming effects that reduce stress. By gifting high-quality teas, companies can encourage healthy habits among employees and clients, contributing to their overall well-being. Wellness-focused tea gifts are a thoughtful way to show care and consideration in corporate relationships.",
        "open": false
    },
    {
        "question": "Can tea corporate gifts be paired with other gourmet items?",
        "answer": "Tea corporate gifts can be paired with other gourmet items to create a luxurious and comprehensive gift set. Common pairings include chocolates, biscuits, honey, or fine china. These combinations enhance the gifting experience by offering a complete package that can be enjoyed together. Giftingy offers various tea gift hampers that include these pairings, providing an elevated gifting experience that is both indulgent and thoughtful.",
        "open": false
    },
    {
        "question": "What are the advantages of ordering tea corporate gifts from suppliers in India?",
        "answer": "Ordering tea corporate gifts from suppliers in India offers several benefits, including access to high-quality teas sourced directly from renowned tea regions like Darjeeling and Assam. Indian suppliers often have extensive knowledge of tea varieties and can provide expert recommendations for corporate gifting. Additionally, sourcing from Indian suppliers supports local businesses and ensures that the tea gifts are fresh and authentic. Giftingy is proud to work with trusted tea suppliers in India, ensuring that our corporate gifts meet the highest standards of quality and taste.",
        "open": false
    },
    {
        "question": "What is the best occasion to give tea corporate gifts?",
        "answer": "Tea corporate gifts are suitable for various occasions, including festive celebrations, company anniversaries, employee recognition programs, and client appreciation events. They are also an excellent choice for year-end gifts or as a goodwill gesture during negotiations or partnerships. Tea's versatility makes it a timeless and appropriate gift for any occasion, and its universal appeal ensures that it will be well-received by all recipients.",
        "open": false
    },
    {
        "question": "How can tea gift hampers be personalized for corporate clients?",
        "answer": "Tea gift hampers can be personalized for corporate clients by selecting a variety of teas that cater to their tastes, adding branded packaging, and including a personalized note or company logo. Giftingy offers bespoke options for tea hampers, allowing you to tailor the contents and presentation to reflect your company's brand and the recipient's preferences. Personalized tea hampers leave a lasting impression and strengthen business relationships by showing attention to detail and care.",
        "open": false
    },
    {
        "question": "Why are tea gift hampers a good choice for employee appreciation?",
        "answer": "Tea gift hampers are a good choice for employee appreciation because they offer a relaxing and enjoyable experience. Employees can unwind with a cup of premium tea, which shows that the company values their well-being. The variety in a tea hamper allows employees to explore different flavours, making it a thoughtful and versatile gift. Additionally, tea hampers can be shared with family or friends, extending the gesture of appreciation beyond the workplace.",
        "open": false
    },
    {
        "question": "What are some unique tea gift ideas for corporate gifting?",
        "answer": "Unique tea gift ideas for corporate gifting include themed tea sets, such as wellness-focused or exotic tea collections, paired with elegant accessories like tea infusers or teapots. Custom-blended teas that reflect the company's values or the recipient's preferences are also a creative option. Giftingy offers a range of unique tea gifts, from luxurious hampers to exclusive tea blends, ensuring that your corporate gift stands out.",
        "open": false
    },
    {
        "question": "How can tea corporate gifts be used in employee onboarding?",
        "answer": "Tea corporate gifts can be a warm and welcoming addition to employee onboarding kits. Including a selection of premium teas in a welcome package shows new employees that their well-being is a priority and helps create a positive first impression. Tea gifts can also serve as an icebreaker, encouraging new hires to connect with colleagues over a cup of tea. This thoughtful gesture sets the tone for a supportive and inclusive workplace culture.",
        "open": false
    },
    {
        "question": "What are the benefits of bulk ordering tea gift hampers for corporate events?",
        "answer": "Bulk ordering tea gift hampers for corporate events offers several benefits, including cost savings, consistency in quality, and the ability to cater to a large audience. Bulk orders often come with discounts, making them cost-effective for large-scale gifting. Additionally, ordering in bulk ensures that all recipients receive the same high-quality product, maintaining the brand's image. Giftingy offers bulk ordering options, making sourcing tea hampers for large corporate events convenient.",
        "open": false
    },
    {
        "question": "How do tea gifts reflect a company's commitment to sustainability?",
        "answer": "Tea gifts reflect a company's commitment to sustainability when sourced from organic and ethically managed plantations. Many premium teas are grown using environmentally friendly practices, and choosing such teas for corporate gifts demonstrates a company's dedication to responsible and ethical business practices. Additionally, sustainable packaging options, such as recyclable or biodegradable materials, further enhance the eco-friendliness of the gift. Giftingy prioritizes sustainability in our tea gifts, ensuring that your corporate gifts align with modern values.",
        "open": false
    },
    {
        "question": "Can tea corporate gifts be part of a corporate wellness program?",
        "answer": "Yes, tea corporate gifts can be integral to a corporate wellness program. Incorporating tea into wellness initiatives encourages employees to take breaks, enjoy moments of relaxation, and benefit from the health properties of various teas. Offering a selection of wellness teas in corporate gifts can promote a culture of health and well-being within the workplace. Giftingy’s tea gifts are well-suited for wellness programs, supporting your company’s efforts to enhance employee well-being.",
        "open": false
    }
]

return (
<>
<MetaTags
        title="Premium Tea Corporate Gifts | Giftingy"
        description="Discover the finest tea corporate gifts with Giftingy. Explore gourmet and exotic tea gift sets for clients and employees, perfect for corporate gifting."
        keywords="Tea corporate gifts, Tea corporate gifting company, Tea corporate gifts suppliers, Tea corporate gifting, Manufacturers of tea corporate gifts, Suppliers of tea corporate gifts, Gourmet tea corporate gifts, Tea gift hampers India, Exotic tea gift sets, Exotic tea gift sets wholesale, Premium tea gift sets, Assorted tea gift set, Darjeeling tea gift set, Tea hamper gift set corporate gifting, Tea hamper gift set bulk, Green tea gift hamper, Tea gift hamper for clients, Tea gift hampers for employees, Corporate Gifting Tea Collection Employee Gift Set, Corporate gifts for tea lovers, Gifts for tea lovers"
      />
<ServiceHeader title="Tea Corporate Gifts"  imageUrl={`${process.env.PUBLIC_URL}/images/teabn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Art of Tea Corporate Gifting</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Tea has long been cherished for bringing people together, fostering conversation, and providing a moment of calm. As a thoughtful and versatile gift, tea corporate gifts offer a unique way to show appreciation to employees, clients, and business partners. Whether it's a premium Darjeeling tea gift set or an assorted tea gift set featuring various flavours, tea gifts are perfect for celebrating milestones, expressing gratitude, and enhancing corporate relationships.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Leading tea corporate gifting companies provide a wide range of options, from gourmet corporate gifts to green tea gift hampers tailored to various tastes and preferences. These gifts reflect your company's appreciation and nod to the timeless tradition of tea. With the ability to customize and personalize, tea gifts are a memorable and elegant choice for corporate gifting.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Here's why tea makes an ideal corporate gift:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Cultural Significance: Tea is a symbol of hospitality and warmth, making it a meaningful gift.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Health Benefits: With options like green tea and herbal blends, tea gifts promote wellness.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Versatility: Suitable for various occasions and recipients.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Customization: Offers personalized packaging and tea blends to match the company's branding.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Sustainability: Many tea gifts are sourced from ethical and organic plantations.</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Wide Appeal: Loved by tea enthusiasts and appreciated by all.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Supports Local Producers: Often sourced from renowned tea regions, supporting local economies.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Suitable for various occasions, from employee recognition to client appreciation</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Makes a lasting impression on both employees and clients</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Customisable packaging options are available for a personalised touch</p>
            </li>
         </div>
      </ul>
   
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Tea Corporate Gifts?</h2>
      <p >Selecting the perfect tea corporate gifts requires understanding quality, variety, and presentation. Giftingy is a leading corporate tea gifting company that offers a curated selection of premium teas. Here's why Giftingy should be your go-to partner for tea corporate gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/teaimg2.jpg`} alt="Elegant Tea Gift Packaging" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we specialize in creating high-quality tea corporate gifts that are both elegant and thoughtful. Our offerings include:
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               Whether you're looking to impress clients with a Darjeeling tea gift set or reward employees with an assorted tea gift set, our collection ensures a memorable gifting experience.
               We understand the importance of personalization in corporate gifting. Our tea gift hampers can be customized to include your company's branding, personalized messages, and a selection of teas that reflect the recipient's tastes. As a premier tea corporate gifts supplier, Giftingy ensures that each gift is crafted with care, attention to detail, and a commitment to quality.
               </p>
            </div>
           
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Beautifully packaged tea hamper gift sets.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Exotic tea gift sets.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Gourmet tea corporate gifts that cater to diverse tastes and preferences.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality tea ensures an enjoyable and memorable experience
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Can be purchased in bulk or wholesale for large-scale corporate events
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reflects care and thoughtfulness in the selection
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your Diwali corporate gifting needs ensures that your gifts are beautiful, meaningful, and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Trusted Partner for Tea Corporate Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            Giftingy is a reliable partner for all your tea corporate gifting needs. Our unique selling propositions (USPs) ensure that your gifts leave a lasting impression on the recipients. Here's what makes us unique:

            </p>
            
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/teaimg3.jpg`} alt="Premium Tea Gift Hampers for Clients" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="TeaCorporateGifts" />

<FaqPage faqs={faqs} />
</>
);
};
export default TeaCorporateGifts;