import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage';
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/employeesimg1.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  
  };


const features = [
    { title: "Custom Designs", detail: "Tailored gifting solutions that cater to your employee's unique preferences." },
    { title: "Branding on Gifts", detail: "Reinforce your brand identity with custom logos and messaging." },
    { title: "Value for Money", detail: "High-quality gifts that fit within your budget, delivering maximum impact." },
    { title: "Concept Creation", detail: "Innovative gift ideas designed to create a memorable experience." },
    { title: "Diverse Range of Gifts", detail: "We offer options for every occasion, from luxury to practical gifts." },
    { title: "Quality Assurance", detail: "Meticulous quality checks ensure that every gift meets the highest standards." },
    { title: "Timely Delivery", detail: "Reliable logistics and punctual delivery services for all orders." },
    { title: "Eco-Friendly Options", detail: "Promote sustainability with our range of green gifting solutions." },
];
const GiftsforEmployees = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
      
        
      }, []);
const faqs = 
    
        [
            {
                "question": "What are the best corporate gifts for employees in India?",
                "answer": "The best corporate gifts for employees in India include customised items, branded merchandise, and premium accessories. The options are vast, from tech gadgets and stationery to luxury hampers and personalised gifts. Selecting the right gift depends on your company culture, the occasion, and budget. Giftingy offers a comprehensive range of corporate gift ideas that can be tailored to meet your employees' preferences and expectations.",
                "open": false
            },
            {
                "question": "How do I choose the right corporate gift company for employee gifting?",
                "answer": "Choosing the right corporate gift company for employee gifting involves evaluating product range, customisation options, and delivery reliability. Look for companies with positive client testimonials, a diverse selection of gifts, and the ability to handle bulk orders. Giftingy is a leading supplier known for providing high-quality, customised gifts for employees in India. Our expert consultation services ensure you choose gifts that align with your brand and employee needs.",
                "open": false
            },
            {
                "question": "Why is employee gifting important for companies?",
                "answer": "Employee gifting is important because it fosters a positive work environment, boosts morale, and enhances loyalty. Recognising employees with thoughtful gifts shows appreciation, encouraging higher productivity and job satisfaction. Regular gifting also helps with employee retention, as valued employees are less likely to leave the organisation. Corporate gifting is crucial in maintaining a motivated and engaged workforce, whether for milestones, achievements, or festive occasions.",
                "open": false
            },
            {
                "question": "What are some unique corporate gifts for employees?",
                "answer": "Unique corporate gifts for employees include personalised items, eco-friendly products, luxury hampers, and branded accessories. Consider gifts that reflect the recipient's preferences, such as customised tech gadgets, stylish office supplies, or bespoke wellness kits. Giftingy specialises in creating unique gifting solutions tailored to your employees' tastes and your company's brand identity.",
                "open": false
            },
            {
                "question": "How do I find a reliable supplier for corporate gifts for employees in India?",
                "answer": "Finding a reliable supplier for corporate gifts for employees in India involves researching companies with a strong reputation for delivering quality products and excellent service. Look for suppliers that offer a wide range of customisation options, competitive pricing, and timely delivery. Giftingy is a trusted supplier known for providing innovative and high-quality corporate gifts for employees, making it easier for businesses to show appreciation in meaningful ways.",
                "open": false
            },
            {
                "question": "What are the benefits of offering customised corporate gifts for employees?",
                "answer": "Offering customised corporate gifts for employees adds a personal touch that shows you value their contributions. Personalisation makes gifts more meaningful, leading to better engagement and appreciation. It also aligns the gifts with your company's brand, enhancing brand recall. Customised gifts can cater to individual preferences, ensuring every employee feels valued. Giftingy provides many personalisation options, from engraved names to company logos, helping you create memorable and impactful gifts.",
                "open": false
            },
            {
                "question": "What are some luxury corporate gifts for employees?",
                "answer": "Luxury corporate gifts for employees include premium leather accessories, high-end tech gadgets, and exclusive gift hampers. These gifts reflect the company's commitment to quality and excellence. Items like designer bags, branded office supplies, and elegant wellness kits are popular. Giftingy specialises in sourcing luxury items that are not only practical but also leave a lasting impression. These high-end gifts are perfect for recognising outstanding performance or celebrating special milestones within the company.",
                "open": false
            },
            {
                "question": "How can corporate gifts for employees enhance company culture?",
                "answer": "Corporate gifts for employees enhance company culture by promoting a sense of belonging, appreciation, and recognition. Regular gifting shows that the company values its employees, which fosters loyalty and job satisfaction. Thoughtful gifts create a positive work environment where employees feel appreciated and motivated. Corporate gifting is crucial in building a strong and inclusive company culture during festive seasons, employee recognition events, or work anniversaries.",
                "open": false
            },
            {
                "question": "What are some unique corporate gift ideas for employees in India?",
                "answer": "Unique corporate gift ideas for employees in India include personalised stationery, eco-friendly products, wellness kits, and branded tech accessories. Gifts that cater to individual interests, such as hobby-related items or customised merchandise, are also highly appreciated. Giftingy offers a wide selection of unique and bespoke gifts that reflect your company's brand and values, making them perfect for employee appreciation and recognition.",
                "open": false
            },
            {
                "question": "How do I choose the right vendor for corporate gifts for employees in India?",
                "answer": "Choosing the right vendor for corporate gifts for employees in India involves considering factors like product range, customisation capabilities, and delivery reliability. A reputable vendor should offer diverse gifts, flexible personalisation options, and a seamless ordering process. Important steps include checking client reviews, requesting samples, and evaluating the vendor's customer service. Giftingy stands out as a trusted partner for corporate gifting, providing top-quality products, expert advice, and dependable service for businesses across India.",
                "open": false
            },
            {
                "question": "What are some promotional gift ideas for employees?",
                "answer": "Promotional gift ideas for employees include branded merchandise, customised office supplies, and practical tech gadgets. These gifts are designed to increase brand visibility while providing value to the recipients. Popular promotional items include branded notebooks, customised USB drives, and stylish drinkware. Giftingy offers a variety of promotional gifts that can be personalised to align with your company's branding, ensuring maximum impact and appreciation from your employees.",
                "open": false
            },
            {
                "question": "Why should companies invest in corporate gifting for employees?",
                "answer": "Investing in corporate gifting for employees is crucial for boosting morale, fostering loyalty, and enhancing overall job satisfaction. Recognising employees' hard work through thoughtful gifts builds stronger relationships and promotes a positive workplace culture. Regular gifting also helps with employee retention and improves motivation, as valued employees are more likely to remain committed to the company. Giftingy provides tailored gifting solutions that cater to your specific employee needs, ensuring that every gift is meaningful and impactful.",
                "open": false
            },
            {
                "question": "How can luxury corporate gifts for employees improve retention?",
                "answer": "Luxury corporate gifts for employees improve retention by making employees feel valued and appreciated. High-end gifts such as premium leather accessories, designer products, and exclusive gift hampers reflect the company's commitment to quality and recognition. These gifts are often given during special occasions or as rewards for outstanding performance, reinforcing positive behaviour and loyalty. Giftingy offers a range of luxury gifts that impress and contribute to employee satisfaction and retention.",
                "open": false
            },
            {
                "question": "What are some creative corporate gift ideas for employee recognition?",
                "answer": "Creative corporate gift ideas for employee recognition include personalised awards, customised wellness kits, and unique experience-based gifts. Items such as engraved plaques, branded merchandise, and bespoke gift hampers are popular. Giftingy specialises in crafting creative and meaningful gifts that align with your company's values and resonate with your employees, making recognition programs more effective and memorable.",
                "open": false
            },
            {
                "question": "How do personalised corporate gifts for employees benefit the company?",
                "answer": "Personalised corporate gifts for employees benefit the company by enhancing employee engagement, building loyalty, and strengthening company culture. Personalisation adds a unique touch to the gifts, making employees feel truly valued. Customising gifts with the employee's name, a motivational message, or the company logo increases the emotional connection, leading to better job satisfaction and productivity. Giftingy offers a wide range of personalised gifting solutions designed to meet your company's goals while making a lasting impact on your employees.",
                "open": false
            }
        ]
      
return (
<>
<MetaTags
        title="Premium Corporate Gifts for Employees in India | Giftingy"
        description="Discover unique and customisable corporate gifts for employees. Giftingy offers branded and luxury gifts tailored to enhance motivation and job satisfaction."
        keywords="Corporate gift for employees, Corporate gift for employees in India, Corporate gift company for employee gifting, Vendor for a corporate gift for employees, Supplier for corporate gifts for employees, Supplier for corporate gifts for employees in India, Customised corporate gifts for employees, Branded corporate gifts for employees, Corporate gift ideas for employees in India, Luxury corporate gifts for employees, Unique gifts for corporate employees, Promotional gift ideas for employees, Unique client gift ideas, Unique corporate gifts for employees"
      />
<ServiceHeader title="Corporate Gifts for Employees" imageUrl={`${process.env.PUBLIC_URL}/images/employeesbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">Appreciating Your Workforce with Corporate Gifts for Employees</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Corporate gifting is an essential tool for expressing gratitude and appreciation toward employees. Offering the right corporate gift to employees can significantly enhance motivation, job satisfaction, and loyalty. In India, where the corporate culture places great value on relationships and recognition, corporate gifts for employees India are vital in fostering a positive work environment and celebrating milestones.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Selecting the perfect gift requires understanding your workforce's preferences and aligning gifts with the company's values. Whether customised corporate gifts for employees or luxury corporate gifts for employees, Giftingy has an extensive range of options. These gifts are not just tokens of appreciation but also help reinforce the company's brand identity while ensuring the recipients feel valued and motivated.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Here's why corporate gifting for employees is crucial:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Boosts Morale:Recognising efforts and achievements enhance job satisfaction.
               </p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes Loyalty: Thoughtful gifts make employees feel valued, leading to increased loyalty.
               </p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Encourages Productivity:Appreciated employees are more likely to perform better.
               </p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Fosters a Positive Culture:Regular gifting promotes a more inclusive and positive workplace environment.
               </p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Builds Team Spirit: Shared gifts or team celebrations enhance camaraderie among employees.
               </p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhances Employee Retention: Employees who feel valued are less likely to leave the organisation.
               </p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Aligns with Brand Values: Branded gifts reinforce your company's identity.
               </p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Offers Personalisation Options:Tailored gifts ensure that they resonate with each employee.
               </p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Suitable for All Occasions:Corporate gifts are versatile, whether it's an employee's work anniversary or the festive season.
               </p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes Long-Term Relationships:Regular gifting helps maintain a positive connection with employees.
               </p>
            </li>
         </div>
      </ul>
   
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Corporate Gifts for Employees?</h2>
      <p className="wow fadeInUp">Here's what sets Giftingy apart:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/employeesimg2.jpg`} alt="Luxury Gifts for Employees in India" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy is a trusted partner for businesses seeking meaningful and high-quality corporate gifts for employees in India. We understand that the right gift can make a lasting impact, motivating employees and boosting overall morale. As a leading corporate gift company for employee gifting, we specialise in offering a wide range of customisable gifts that cater to different needs, preferences, and occasions.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               Our selection includes everything from branded corporate gifts for employees to unique and luxurious options that reflect your company's appreciation for its workforce. We take pride in our ability to deliver personalised gifting solutions that are both thoughtful and practical. Whether you're looking for budget-friendly gifts or premium items, Giftingy provides a comprehensive range of products to suit your requirements.
               </p>
            </div>
          
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div>
         <div className="gift-idea-pointer"></div>
         
      </div>
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
          <b>Personalisation Options:</b>Tailor gifts to match your employees' preferences, from customised stationery to premium gift hampers.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
          <b>Extensive Range of Products:</b>We offer solutions for all occasions, from luxury items to practical corporate gifts.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
          <b>Expert Consultation:</b>Our experienced team helps you select gifts that align with your company culture and employee needs.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
          <b>Reliable Delivery: </b>We ensure that your gifts are delivered on time, whether in bulk ordersit's or individually.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
          <b>Trusted Supplier Network:</b>We source products from trusted vendors as a reliable supplier of corporate gifts for employees in India.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
          <b>Quality Assurance:</b>We maintain the highest product quality and design standards, ensuring that your gifts are well-received.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
          <b>Custom Branding:</b>Add your company's logo or branding elements to make the gifts more personal and meaningful.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
          <b>Diverse Gifting Options:</b>Our selection, which includes tech gadgets and eco-friendly products, is tailored to suit various employee profiles.
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your corporate gift for employees ensures that your gifts are meaningful, memorable, and aligned with your company's values.
         </p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Trusted Partner for Corporate Client Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            Giftingy is dedicated to providing high-quality corporate gifts that elevate your business relationships. Our commitment to excellence, creativity, and customer satisfaction positions us as a leading corporate gift company for client gifting in India. Here's why you should choose Giftingy:
            </p>
           
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/employeesimg3.jpg`} alt="Premium Employee Recognition Gifts" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId='incentiveGifts'/>
<FaqPage faqs={faqs} />
</>
);
};
export default GiftsforEmployees;