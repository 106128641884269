import React from 'react';

const Disclaimer = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Disclaimer</h1>
      <p>
        The content on this Website, <a href="https://www.giftingy.com" style={styles.link}>https://www.giftingy.com</a> ("the Website"),
        including images, product descriptions, and catalogues (including attached PDFs), is provided
        for informational purposes only. By accessing and using the Website, you agree to the terms outlined
        in this disclaimer. If you disagree with any part of this disclaimer, please refrain from using the Website.
      </p>

      <h2 style={styles.heading}>Product Images and Representations</h2>
      <p>
        All images, colours, shades, and sizes displayed on the Website or within the attached PDF catalogues are 
        for representative purposes only. Actual product specifications, including colour, shade, size, and design, 
        may vary due to differences in screen settings, manufacturing processes, and other factors. We recommend 
        that customers view actual samples or contact us for precise product details.
      </p>

      <h2 style={styles.heading}>Divinity Gifting</h2>
      <p>
        Giftingy offers a range of divinity gifts designed to celebrate cultural and traditional values. Our offerings 
        are not intended to represent or promote any specific religious beliefs, nor are they meant to offend or hurt 
        any religious sentiments. We respect all faiths and cultures and encourage responsible gifting.
      </p>

      <h2 style={styles.heading}>Accuracy of Information</h2>
      <p>
        While we strive to ensure that the information on the Website is accurate and up-to-date, we do not make any 
        warranties or representations regarding the accuracy, completeness, reliability, or suitability of the information, 
        products, or services listed. Any reliance you place on such information is strictly at your own risk. For specific 
        product inquiries or to view actual samples, please get in touch with us at <a href="mailto:vineet@giftingy.com" style={styles.link}>vineet@giftingy.com</a>.
      </p>

      <h2 style={styles.heading}>Use of the Website</h2>
      <p>
        Your use of the Website is entirely at your own risk. Giftingy shall not be liable for any direct, indirect, incidental, 
        consequential, or punitive damages arising from your access to or use of the Website. This includes any damage to, 
        or viruses that may infect, your computer equipment or other property.
      </p>

      <h2 style={styles.heading}>Third-Party Links</h2>
      <p>
        The Website may include links to third-party websites for your convenience. These links do not signify our endorsement 
        of the content, products, or services offered by these third-party sites. We have no control over the nature, content, 
        and availability of those websites. The inclusion of any links does not necessarily imply a recommendation or endorsement by us.
      </p>

      <h2 style={styles.heading}>Privacy and Security</h2>
      <p>
        While we take reasonable steps to protect your personal information, we cannot guarantee the security of data transmitted 
        over the Internet. You acknowledge that any information you share on the Website is done at your own risk.
      </p>

      <h2 style={styles.heading}>Limitation of Liability</h2>
      <p>
        In no event shall Giftingy, its affiliates, employees, or partners be liable for any direct, indirect, incidental, special, 
        punitive, or consequential damages or any loss of profits or revenues arising out of or related to your use of the Website 
        or the products/services offered.
      </p>

      <h2 style={styles.heading}>Changes to Disclaimer</h2>
      <p>
        We reserve the right to modify or replace this disclaimer at any time. It is your responsibility to review this page 
        periodically for updates. Continued use of the Website after any changes constitutes your acceptance of the updated terms.
      </p>

      <h2 style={styles.heading}>Contact Us</h2>
      <p>
        If you have any questions or concerns about this disclaimer or require specific product details, please email us at 
        <a href="mailto:vineet@giftingy.com" style={styles.link}>vineet@giftingy.com</a>.
      </p>

      <p>
        By using the Website <a href="https://www.giftingy.com" style={styles.link}>https://www.giftingy.com</a>, 
        you acknowledge that you have read, understood, and agreed to the terms and conditions outlined in this disclaimer.
      </p>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '94vw',
    margin: '0 auto',
    padding: '20px',
    lineHeight: '1.6',
    color: '#333',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
  },
  heading: {
    textAlign: 'left',
    marginTop: '40px', // margin to add space after each section
    marginBottom: '20px',
  },
};

export default Disclaimer;
