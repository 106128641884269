import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import '../components/FooterCategory.css';

const FooterCategory = () => {
    return (
        <footer className="footer1">
            <div className='footerMainDiv'>
                <h5 className='footerMain'>Our Categories</h5>
            </div>
            <div className="container">
                <div className="row">
                    {/* Categories Section */}
                    <div className="col-md-4">
                        <ul className="footer-links1 footerUls">
                            <li><Link to="/services/corporate-gifting">Corporate Gifting</Link></li>
                            <li><Link to="/services/incentive-gifts">Incentive Gifts</Link></li>
                            <li><Link to="/services/exhibition-and-events-gifting">Exhibition & Events Gifting</Link></li>
                            <li><Link to="/services/branded-medals-and-trophies">Medals & Trophies</Link></li>
                            <li><Link to="/services/corporate-leather-gifting">Corporate Leather Gifting</Link></li>
                            <li><Link to="/services/pongal-corporate-gifting">Pongal Corporate Gifting</Link></li>
                            <li><Link to="/services/kartikay-dibam-corporate-gifting">Kartikay Dibam Gifting</Link></li>
                            <li><Link to="/services/chocolate-corporate-gifting">Chocolate Corporate Gifting</Link></li>
                            <li><Link to="/services/easter-corporate-gifts">Easter Corporate Gifts</Link></li>
                            <li><Link to="/services/dussehra-corporate-gifts">Dussehra Corporate Gifts</Link></li>
                            <li><Link to="/services/handicraft-corporate-gifting">Handicraft Corporate Gifting</Link></li>
                            <li><Link to="/services/coffee-as-corporate-gift">Coffee As Corporate Gift</Link></li>
                            <li><Link to="/services/blankets-for-corporate-gifting">Blankets for Corporate Gifting</Link></li>
                        </ul>
                    </div>

                    {/* Additional Categories */}
                    <div className="col-md-4">
                        <ul className="footer-links1 footerUls">
                            <li><Link to="/services/Vastu-corporate-gifting">Vastu Corporate Gifting</Link></li>
                            <li><Link to="/services/corporate-gifting-for-milestones-and-Celebrations">Milestones & Celebrations</Link></li>
                            <li><Link to="/services/divinity-gifts">Divinity Gifts</Link></li>
                            <li><Link to="/services/indian-traditional-gifts">Indian Traditional Gifts</Link></li>
                            <li><Link to="/services/festival-gifting">Festival Gifting</Link></li>
                            <li><Link to="/services/navratri-corporate-gifting">Navratri Corporate Gifting</Link></li>
                            <li><Link to="/services/branded-apparel-gifting">Branded Apparel Gifting</Link></li>
                            <li><Link to="/services/dryfruits-and-sweets-gifting">Dryfruits & Sweets Gifting</Link></li>
                            <li><Link to="/services/travel-gifts-for-businesses">Travel Gifts in Corporate Gifting</Link></li>
                            <li><Link to="/services/ganesh-chaturthi-corporate-gifting">Ganesh Chaturthi Corporate Gifts</Link></li>
                            <li><Link to="/services/promotional-giveaways-corporate-gifts">Promotional Giveaways Corporate Gifts</Link></li>
                            <li><Link to="/services/drinkware-products-for-corporate-gifts">Drinkware Products for Corporate Gifts</Link></li>
                            <li><Link to="/services/backpack-as-corporate-gift">Backpack As Corporate Gift</Link></li>
                        </ul>
                    </div>

                    <div className="col-md-4">
                        <ul className="footer-links1 footerUls">
                            <li><Link to="/services/akshay-tritiya-corporate-gifting">Akshay Tritiya Gifting</Link></li>
                            <li><Link to="/services/gifts-for-her">Gifts for Her</Link></li>
                            <li><Link to="/services/gifts-for-male">Gifts for Male</Link></li>
                            <li><Link to="/services/eid-corporate-gifting">Eid Corporate Gifting</Link></li>
                            <li><Link to="/services/onboarding-employee-kit">Onboarding Employee Kit</Link></li>
                            <li><Link to="/services/golf-corporate-gifting">Golf Corporate Gifting</Link></li>
                            <li><Link to="/services/corporate-gifts-for-clients">Gifts for Clients</Link></li>
                            <li><Link to="/services/corporate-gifts-for-employees">Gifts for Employees</Link></li>
                            <li><Link to="/services/planters-corporate-gifting">Planters Corporate Gifting</Link></li>
                            <li><Link to="/services/kitchen-and-dining-corporate-gifting">Kitchen & Dining Corporate Gifts</Link></li>
                            <li><Link to="/services/corporate-jewellery-gifts">Corporate Jewellery Gifts</Link></li>
                            <li><Link to="/services/utility-corporate-gift">Utility Corporate Gift</Link></li>
                            <li><Link to="/services/womens-day-corporate-gift">Women's Day Corporate Gift</Link></li>
                        </ul>
                    </div>

                    <div className="col-md-4">
                        <ul className="footer-links1 footerUls">
                            <li><Link to="/services/dhanteras-corporate-gifting">Dhanteras Corporate Gifting</Link></li>
                            <li><Link to="/services/personalised-corporate-gifts">Personalised Corporate Gifts</Link></li>
                            <li><Link to="/services/bar-accessories-corporate-gifting">Bar Accessories Gifting</Link></li>
                            <li><Link to="/services/diwali-corporate-gifting">Diwali Corporate Gifting</Link></li>
                            <li><Link to="/services/wedding-return-gifts">Wedding Return Gifts</Link></li>
                            <li><Link to="/services/luxury-gifting">Luxury Gifting</Link></li>
                            <li><Link to="/services/luxury-home-decor-gifting">Luxury Home Decor</Link></li>
                            <li><Link to="/services/health-and-wellness-gifts">Health & Wellness Gifts</Link></li>
                            <li><Link to="/services/makar-sankranti-corporate-gifts">Makar Sankranti Gifts</Link></li>
                            <li><Link to="/services/beauty-and-fashion-gifting">Beauty & Fashion Gifting</Link></li>
                            <li><Link to="/services/tea-corporate-gifts">Tea Corporate Gifts</Link></li>
                            <li><Link to="/services/bedsheets-for-corporate-gifting">Bedsheets for Corporate Gifting</Link></li>
                            <li><Link to="/services/brass-items-as-corporate-gifts">Brass Items As Corporate Gifts</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterCategory;
