import React from 'react';
import '../components/HomeAboutSection.css'; // Make sure to create this CSS file for styling

const HomeAboutSection = () => {
    return (
        <section  className="about-us">
        <div className="about-us-container">
            

            <div className="about-us-content">
                
                <div className="about-us-section">
                   
                    <div className="section-text">
                        <h2 className="wow fadeInUp headingnew" id='about' >About Giftingy: Your Partner in Corporate Gifting</h2>
                        <p>
                            At Giftingy, corporate gifting is more than just giving presents; it's about creating memorable experiences, fostering strong relationships, and enhancing brand visibility. As one of India's top corporate gifting companies, we understand the importance of making a lasting impression. Our extensive range of unique and branded corporate gifts is designed to cater to diverse needs and occasions, ensuring you always find the perfect gift.
                        </p>
                    </div>
                 
                </div>

                <div className="about-us-section">
                  
                    <div className="section-text">
                        <h2 className="wow fadeInUp headingnew">Our Mission and Vision</h2>
                        <p>
                            Our mission is to revolutionise the corporate gifting landscape by providing innovative, high-quality gifting solutions that exceed our clients' expectations. We aim to be India's preferred corporate gifting supplier, known for our creativity, reliability, and customer-centric approach.
                        </p>
                        <p>
                            Our vision is to become the go-to partner for businesses seeking exceptional corporate gifts that reflect their brand's values and enhance their relationships with employees, clients, and partners. We strive to set new standards in the corporate gifting industry, consistently delivering excellence and value.
                        </p>
                    </div>
                </div>

                <div className="about-us-section">
                  
                    <div className="section-text">
                        <h2 className="wow fadeInUp headingnew">Our Corporate Gifting Solutions</h2>
                        <p>
                            <ul className='ulCont'>
                                <li className='liCont'><b>Corporate Gifting for Unique Ideas:</b> Giftingy specialises in creating unique corporate gifts that stand out. Our creative team constantly explores new trends and ideas to bring you innovative gifting solutions that leave a lasting impression.</li>
                                <li className='liCont'><b>Value for Corporate Gifting:</b> We believe in providing value through corporate gifts. Our products are designed to enhance relationships, boost morale, and promote brand loyalty. By investing in quality gifts, you demonstrate appreciation and create meaningful connections with recipients.</li>
                                <li className='liCont'><b>Corporate Gifting for Employees:</b> Recognising and rewarding employees is essential for maintaining a motivated and productive workforce. Our employee gifts include personalised items, luxury hampers, and branded merchandise that show your appreciation for their hard work and dedication.</li>
                                <li className='liCont'><b>Corporate Gifting for Clients:</b> Strengthening client relationships is key to business success. Our client gifts are curated to impress and delight, helping you foster loyalty and goodwill. We have options for every client, from elegant gift sets to premium branded products.</li>
                                <li className='liCont'><b>Corporate Gifting for Special Occasions:</b> Celebrate special corporate milestones, festivals, and events with our thoughtfully curated gifts. Whether it's Diwali, Christmas, or a company anniversary, our festive gifts add a touch of elegance and joy to your celebrations.</li>
                            </ul>
                        </p>
                    </div>
                  
                
                  
                
                </div>

                <div className="about-us-section">
                   
                    <div className="section-text">
                        <h2  className="wow fadeInUp headingnew">Our Commitment to Sustainability</h2 >
                        <p>
                            At Giftingy, we are committed to sustainability and responsible gifting. We offer a range of eco-friendly corporate gifts made from sustainable materials that support environmental conservation. By choosing our green gifting options, you contribute to a better future while showcasing your company's commitment to sustainability.
                        </p>
                    </div>
                  
                </div>

                <div className="about-us-section colorbg">
                    <h3>Join the Giftingy Experience</h3>
                  
                    <p>
                        Discover the difference thoughtful, high-quality corporate gifts can make. Partner with Giftingy, India's premier corporate gifting company, and elevate your gifting experience. Whether you need gifts for employees, clients, or special events, we are here to provide exceptional solutions that leave a lasting impact.
                    </p>
                   
                    <p>
                       
                         Contact our team to explore our range of corporate gifts and find the perfect solutions for your business needs. Let's create memorable gifting experiences that reflect your brand's identity and enhance your professional relationships.
                         
                    </p>
                    
                </div>
                <div className='ctaButton'>
                <a className='buttons' href='/contact'>Contact Us Today</a >
                </div>
                
            </div>
        </div>
    </section>
    );
}

export default HomeAboutSection;
