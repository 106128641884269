import React from 'react';

import HomeAboutSection from '../components/HomeAboutSection'; 
function AboutPage() {
  
  return (
     <>
       <HomeAboutSection />
      </>
  );
}

export default AboutPage;
