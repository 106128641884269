import React from 'react';
import './Loader.css'; // Import CSS for the loader

const Loader = ({ isVisible }) => {
  if (!isVisible) {
    return null; // Do not render anything if loader is not visible
  }

  return (
    <div className="loader-overlay">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
