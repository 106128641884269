import React from 'react';
import Slider from 'react-slick';
import { ReactTyped } from 'react-typed';
import './HomeSlider.css';

function HomeSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const slides = [
    {
      image: '/images/homeBanner1.jpg',
      id: 1,
      width: 1920, // Example width in pixels
      height: 1080  // Example height in pixels
    },
    {
      image: '/images/homeBanner2.jpg',
      id: 2,
      width: 1920, // Example width in pixels
      height: 1080  // Example height in pixels
    },
    {
      image: '/images/homeBanner3.jpg',
      id: 3,
      width: 1920, // Example width in pixels
      height: 1080  // Example height in pixels
    }
  ];

  return (
    <div className="home-slider-container container-94vw">
      <div className="home-slider-text">
   
        <p>
          <span className="gift-for-text">Corporate Gifts for </span>
          <br></br>
          <span className="typed-text">
            <ReactTyped
              strings={[
                "FESTIVALS",
                "OCCASIONS",
                "HIM & HER",
                "BRANDING",
                "EVENTS",
                "PERSONALISATIONS",
                "LUXURY",
               
              ]}
              typeSpeed={100}
              backSpeed={50}
              loop
            />
          </span>
        </p>
        <p>Premier Corporate Gifting Company in India</p>
        <a href="#shop" className="btn btn-primary">Shop Now</a>
        <a href='/contact'  className="btn btn-secondary">Contact us</a >
      </div>
      <div className="home-slider">
        <Slider {...settings}>
          {slides.map(slide => (
            <div key={slide.id}>
              <img src={slide.image} alt={`Slide ${slide.id}`} className="slider-image" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default HomeSlider;
