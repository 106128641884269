import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage';
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/incentivegfimg1.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  
  };


const features = [
    { title: "Custom Designs", detail: "Personalised incentive gifts tailored to your preferences." },
    { title: "Branding on Gifts", detail: "Keeps your brand memorable and visible." },
    { title: "Value for Money", detail: "High-quality incentive gifts within your budget." },
    { title: "Concept Creation", detail: "Unique and innovative gift ideas." },
    { title: "Diverse Range of Gifts", detail: "Extensive selection for all occasions." },
    { title: "Quality Assurance", detail: "Premium quality guaranteed." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible gifting." },
];
const IncentiveGifts = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
       
      }, []);
const faqs = [
    {
        "question": "What is incentive gifting?",
        "answer": "Incentive gifting involves providing gifts to employees, sales teams, or customers to reward their performance, loyalty, or achievements. It is a strategic tool companies use to motivate and recognise their stakeholders. Incentive gifts can range from small tokens of appreciation to significant rewards. These gifts help boost morale, increase productivity, and foster loyalty. Incentive gifting is widely practised in various industries and sectors.",
        "open": false
    },
    {
        "question": "Why is incentive gifting important?",
        "answer": "Incentive gifting is important because it helps motivate employees and recognise their hard work and dedication. It also strengthens customer relationships by showing appreciation for their loyalty and support. Incentive gifts can lead to higher productivity, improved performance, and a positive work environment. They also play a crucial role in retaining top talent and fostering a culture of recognition within the organisation. Overall, incentive gifting contributes to the overall success of a business.",
        "open": false
    },
    {
        "question": "What are some popular incentive gift ideas?",
        "answer": "Popular incentive gift ideas include gift cards, personalised items, tech gadgets, and luxury products. For sales teams, incentive ideas include travel vouchers, high-end merchandise, and performance-based rewards. Unique customer appreciation gifts include customised gifts, branded merchandise, and special discounts. Inexpensive customer appreciation gifts might be branded stationery, promotional items, or small tokens of gratitude. The key is to choose meaningful and valued gifts by the recipients.",
        "open": false
    },
    {
        "question": "How do I choose the right incentive gifts for my team?",
        "answer": "Choosing the right incentive gifts involves understanding the preferences and needs of your team members. Consider the impact you want to achieve and the message you want to convey with the gift. Customisation can add a personal touch, making the gift more meaningful. Budget is also an important factor; ensure that the gifts are high-quality but within your financial constraints. Consulting an incentive gifting company can provide additional insights and recommendations.",
        "open": false
    },
    {
        "question": "What are the benefits of working with an incentive gifting company in Gurgaon?",
        "answer": "Working with an incentive gifting company in Gurgaon offers several benefits, including access to a wide range of high-quality products. These companies provide customisation options to make your gifts unique and personal. They ensure timely delivery and professional service, enhancing your overall gifting experience. Local companies understand the market trends and preferences, providing relevant and impactful gift ideas. Partnering with a reputable company ensures reliability and satisfaction.",
        "open": false
    },
    {
        "question": "Can incentive gifts be customised?",
        "answer": "Yes, incentive gifts can be customised to reflect the recipient's preferences and the company's branding. Customisation options may include engraving names, adding personal messages, or incorporating the company logo. Personalised gifts are often more meaningful and appreciated by the recipients. They show that extra thought and effort have been put into selecting the gift. Customised incentive gifts can significantly enhance the impact of your appreciation.",
        "open": false
    },
    {
        "question": "What are inexpensive customer appreciation gifts?",
        "answer": "Inexpensive customer appreciation gifts include branded pens, keychains, promotional products, and small tokens of gratitude. These gifts are cost-effective yet thoughtful, showing customers that they are valued. Despite being inexpensive, these gifts can leave a lasting impression when chosen carefully. They can strengthen customer relationships and foster loyalty. The key is to ensure the gifts are practical and relevant to the recipients.",
        "open": false
    },
    {
        "question": "How can incentive gifts boost employee morale?",
        "answer": "Incentive gifts boost employee morale by recognising and rewarding their hard work and achievements. They create a sense of appreciation and motivation, encouraging employees to perform better. These gifts can improve job satisfaction and foster a positive work environment. Regular recognition through incentive gifts helps retain top talent and reduce turnover rates. Overall, they contribute to a more engaged and productive workforce.",
        "open": false
    },
    {
        "question": "What are some unique customer appreciation gift ideas?",
        "answer": "Unique customer appreciation gift ideas include personalised items, bespoke gift hampers, and exclusive branded merchandise. Customised gifts that reflect the recipient's preferences can make a significant impact. Experiential gifts such as event tickets or curated experiences are also popular. The aim is to choose memorable gifts that convey a message of appreciation. Unique gifts help in building strong, lasting relationships with customers.",
        "open": false
    },
    {
        "question": "Why is it important to recognise the sales team with incentive gifts?",
        "answer": "Recognising the sales team with incentive gifts is crucial as it motivates them to achieve higher targets and maintain their performance. It shows appreciation for their efforts and contributions to the company's success. Incentive gifts can drive competition and encourage teamwork within the sales department. They help retain top sales talent and reduce turnover. Overall, they contribute to a positive and productive sales environment.",
        "open": false
    },
    {
        "question": "What are complimentary gifts for customers?",
        "answer": "Complimentary gifts are free items given to customers to show appreciation for their loyalty and support. These gifts can include branded merchandise, promotional products, or small tokens of gratitude. Complimentary gifts help strengthen customer relationships and enhance brand loyalty. They create a positive impression and encourage repeat business. Choosing thoughtful and relevant complimentary gifts can significantly impact customer satisfaction.",
        "open": false
    },
    {
        "question": "How can I ensure the timely delivery of incentive gifts?",
        "answer": "Ensuring the timely delivery of incentive gifts involves planning and working with reliable gifting companies. Choose a company with a proven track record of punctual delivery and excellent service. Communicate your deadlines clearly and confirm the delivery schedule in advance. Consider using expedited shipping options if necessary. Regular follow-ups and tracking of the shipment can also ensure timely delivery.",
        "open": false
    },
    {
        "question": "What are the best incentive gift ideas for a sales team?",
        "answer": "The best incentive gift ideas for a sales team include performance-based rewards such as travel vouchers, high-end gadgets, and luxury merchandise. Customised awards or trophies can also be motivating. Gift cards, experiential gifts, and personalised items are popular choices. The goal is to select meaningful and valued gifts from the sales team. Recognising their efforts with thoughtful gifts can boost morale and drive performance.",
        "open": false
    },
    {
        "question": "What factors should I consider when choosing incentive gifts for customers?",
        "answer": "When choosing incentive gifts for customers, consider their preferences, the occasion, and your budget. Select gifts that are practical and relevant to the recipient. Personalisation can add a unique touch, making the gift more memorable. Ensure the gifts reflect your brand values and enhance your relationship with the customer. Consulting with an incentive gifting company can provide additional insights and recommendations.",
        "open": false
    },
    {
        "question": "How do I find the best incentive gifting company in Delhi?",
        "answer": "Finding the best incentive gifting company in Delhi involves researching their reputation, product range, and customer reviews. Look for companies that offer customisation options and high-quality products. Ensure they have a proven track record of timely delivery and excellent service. Consulting with colleagues or industry contacts for recommendations can also be helpful. Choose a company that understands your specific needs and preferences.",
        "open": false
    },
    {
        "question": "What are the benefits of inexpensive customer appreciation gifts?",
        "answer": "Inexpensive customer appreciation gifts are cost-effective yet thoughtful, making them accessible for businesses with various budgets. They help in showing gratitude and strengthening customer relationships. These gifts can enhance brand loyalty and encourage repeat business. Despite being inexpensive, they can leave a lasting impression when chosen carefully. The key is to select practical and relevant items that reflect your appreciation.",
        "open": false
    },
    {
        "question": "Why should companies invest in incentive gifts?",
        "answer": "Companies should invest in incentive gifts to recognise and reward their employees, sales teams, and customers. Incentive gifts boost morale, increase productivity, and foster loyalty. They help retain top talent and reduce turnover. Investing in incentive gifts also strengthens customer relationships and enhances brand loyalty. Overall, it contributes to the company's success and growth.",
        "open": false
    },
    {
        "question": "What are some incentive ideas for sales teams?",
        "answer": "Incentive ideas for sales teams include performance-based rewards such as bonuses, travel vouchers, and high-end gadgets. Customised trophies or awards can also be motivating. Gift cards, experiential gifts, and personalised items are popular choices. The goal is to select meaningful and valued incentives by the sales team. Recognising their efforts with thoughtful incentives can boost morale and drive performance.",
        "open": false
    },
    {
        "question": "How can I make customer appreciation gifts unique?",
        "answer": "Making customer appreciation gifts unique involves adding personal touches and customisation. Consider engraving names, adding personal messages, or selecting items that reflect the recipient's preferences. Customised packaging can also enhance the uniqueness of the gift. Choose practical, relevant, and memorable gifts. Working with an incentive gifting company can provide additional ideas and options.",
        "open": false
    },
    {
        "question": "What are the key benefits of incentive gifting?",
        "answer": "The key benefits of incentive gifting include boosting morale, increasing productivity, and fostering loyalty. Incentive gifts help recognise and reward employees, sales teams, and customers. They contribute to a positive work environment and enhance brand loyalty. Investing in incentive gifts also strengthens relationships and encourages repeat business. Overall, incentive gifting is valuable for promoting business success and growth.",
        "open": false
    }
]
 
return (
<>
<MetaTags
        title="Leading Incentive Gifting Company in India"
        description="Discover the best incentive gift ideas and unique customer appreciation gifts from the leading incentive gifting companies in Gurgaon, Delhi, Noida, and India."
        keywords="Incentive gifting, Incentive gift ideas, Incentive gifting company Gurgaon, Incentive gifting company Delhi, Incentive gifting company India, Incentive gifting company Noida, Incentive ideas for the sales team, Incentive gifts for customers, Unique customer appreciation gifts, Inexpensive customer appreciation gifts, Complimentary gifts for customers"
      />
<ServiceHeader title="Incentive Gifts" imageUrl={`${process.env.PUBLIC_URL}/images/incentiveGiftbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Importance of Incentive Gifts</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Incentive gifts are crucial in motivating and rewarding employees, sales teams, and customers. These gifts recognise achievements, encourage performance, and foster loyalty. The right incentive gift can create a lasting impact, driving personal and professional growth. Companies often use incentive gifts to boost morale, increase productivity, and maintain a positive work environment.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Incentive gifting is not limited to employees; it also extends to customers. Offering unique or inexpensive customer appreciation gifts can strengthen customer relationships and enhance brand loyalty. Customer incentive gifts can range from complimentary gifts to more personalised items tailored to show appreciation and value.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Incentive gifting companies in India have various options to cater to various needs and budgets. Here are some key benefits of incentive gifts:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Boosts employee morale and motivation</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Encourages higher productivity and performance</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Strengthens customer loyalty and satisfaction</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhances team spirit and collaboration</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Provides recognition and appreciation</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes a positive work culture</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Offers a range of options to suit different needs</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">It can be customised to reflect the company's brand</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Ideal for both internal teams and external clients</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Increases overall business performance and success</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>Incentive gifts are an effective tool for fostering a culture of appreciation and recognition within any organisation.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Incentive Gifts?</h2>
      <p className="wow fadeInUp">Selecting the perfect incentive gifts requires understanding the recipient's preferences and the impact you wish to achieve. Giftingy is a leading incentive gifting company in Gurgaon, Delhi, Noida, and India, offering diverse incentive gift ideas. Here's why Giftingy should be your preferred choice for incentive gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/incentivegfimg2.jpg`} alt="Gifting Image 1" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we specialise in creating incentive gifts that motivate and inspire. Our selection includes unique customer appreciation gifts, inexpensive gifts, and incentive ideas for sales teams. We understand that each recipient is unique, and our customisation options ensure your gifts are personal and memorable.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               One of our strengths is our ability to offer high-quality gifts at competitive prices. Whether you are looking for complimentary gifts for customers or incentive gifts for your sales team, we provide solutions that fit your budget without compromising quality. Our expertise as an incentive gifting company in Delhi, Gurgaon, and Noida ensures that we easily cater to local and national clients.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy also offers a seamless and hassle-free gifting experience. Our reliable delivery services ensure your gifts reach their recipients on time and in perfect condition. We pride ourselves on our commitment to customer satisfaction and ability to create lasting impressions with our gifts.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div>
         <div className="gift-idea-pointer"></div>
         
      </div>
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisable incentive gifts to suit individual preferences
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide range of incentive gift ideas for various occasions
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality products at competitive prices
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise as an incentive gifting company in Gurgaon, Delhi, and Noida
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable and timely delivery services
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Commitment to customer satisfaction
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Unique and memorable customer appreciation gifts
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Comprehensive solutions for both internal and external recipients
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your incentive gifting needs ensures that your gifts are impactful, meaningful, and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Partner for Impactful Incentive Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on delivering exceptional incentive gifting solutions that cater to your needs and preferences. Our unique selling propositions (USPs) set us apart as India's leading incentive gifting company. Here's what makes us unique:
            </p>
            <p className='wow fadeInUp section-partpara section-partlheight'>Giftingy offers custom designs that add a personal touch to your incentive gifts, making them more meaningful and memorable. Our branding on gifts ensures that your company remains at the forefront of the recipient's mind, enhancing brand recall and visibility. We provide value for money by offering high-quality gifts that fit within your budget, ensuring you get the best return on your investment.</p>
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/incentivegf3.jpg`} alt="Gifting Image 1" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId='incentiveGifts'/>
<FaqPage faqs={faqs} />
</>
);
};
export default IncentiveGifts;