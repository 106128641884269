import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import TopHeader from './TopHeader';
import './Header.css';

function Header() {
  useEffect(() => {
    document.querySelector(".nav-link.active")?.click();
  }, []);

  const handleNavLinkClick = () => {
    // Collapse the navbar
    const navbarCollapse = document.querySelector("#navbarSupportedContent");
    if (navbarCollapse && navbarCollapse.classList.contains("show")) {
      const collapseButton = document.querySelector(".navbar-toggler");
      if (collapseButton) collapseButton.click();
    }
    
    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <TopHeader />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container custom-container" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
        <NavLink className="navbar-brand" to="/" onClick={handleNavLinkClick}>
  <img src="/images/Logo.png" alt="Giftingy Logo" className="logo-img" />
</NavLink>

          <button className="navbar-toggler btns1" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to="/" onClick={handleNavLinkClick}>Home</NavLink>
              </li>
              <li className="nav-item dropdown mx-2">
                <a className="nav-link dropdown-toggle btns1" href="#" id="servicesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Services
                </a>
                <div className="dropdown-menu services-menu" aria-labelledby="servicesDropdown">
                  <div className="row">
                    <div className="d-none d-lg-block col-md-3">
                      <img src="https://gethirednow.in/giftingy/giftingImg.jpg" alt="Service Image" className="img-fluid" />
                    </div>
                    <div className="col-lg-9">
                      <div className="row">
                        <div className="col-md-3 border-right">
                          <NavLink className="dropdown-item" to="/services/corporate-gifting" onClick={handleNavLinkClick}>Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/incentive-gifts" onClick={handleNavLinkClick}>Incentive Gifts</NavLink>
                          <NavLink className="dropdown-item" to="/services/exhibition-and-events-gifting" onClick={handleNavLinkClick}>Exhibition & Events Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/branded-medals-and-trophies" onClick={handleNavLinkClick}>Branded Medals And Trophies</NavLink>
                          <NavLink className="dropdown-item" to="/services/corporate-leather-gifting" onClick={handleNavLinkClick}>Corporate Leather Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/pongal-corporate-gifting" onClick={handleNavLinkClick}>Pongal Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/kartikay-dibam-corporate-gifting" onClick={handleNavLinkClick}>Kartikay Dibam Gifting</NavLink>
                        </div>
                        <div className="col-md-3 border-right">
                          <NavLink className="dropdown-item" to="/services/chocolate-corporate-gifting" onClick={handleNavLinkClick}>Chocolate Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/luxury-home-decor-gifting" onClick={handleNavLinkClick}>Luxury Home Decor</NavLink>
                          <NavLink className="dropdown-item" to="/services/personalised-corporate-gifts" onClick={handleNavLinkClick}>Personalised Corporate Gifts</NavLink>
                          <NavLink className="dropdown-item" to="/services/dryfruits-and-sweets-gifting" onClick={handleNavLinkClick}>Dryfruits And Sweets Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/bar-accessories-corporate-gifting" onClick={handleNavLinkClick}>Bar Accessories Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/Vastu-corporate-gifting" onClick={handleNavLinkClick}>Vastu Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/corporate-gifting-for-milestones-and-Celebrations" onClick={handleNavLinkClick}>Milestones & Celebrations</NavLink>
                        </div>
                        <div className="col-md-3 border-right">
                          <NavLink className="dropdown-item" to="/services/divinity-gifts" onClick={handleNavLinkClick}>Divinity Gifts</NavLink>
                          <NavLink className="dropdown-item" to="/services/indian-traditional-gifts" onClick={handleNavLinkClick}>Indian Traditional Gifts</NavLink>
                          <NavLink className="dropdown-item" to="/services/festival-gifting" onClick={handleNavLinkClick}>Festival Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/navratri-corporate-gifting" onClick={handleNavLinkClick}>Navratri Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/akshay-tritiya-corporate-gifting" onClick={handleNavLinkClick}>Akshay Tritiya Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/gifts-for-her" onClick={handleNavLinkClick}>Gifts for Her</NavLink>
                          <NavLink className="dropdown-item" to="/services/gifts-for-male" onClick={handleNavLinkClick}>Gifts for Male</NavLink>
                        </div>
                        <div className="col-md-3">
                          <NavLink className="dropdown-item" to="/services/luxury-gifting" onClick={handleNavLinkClick}>Luxury Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/wedding-return-gifts" onClick={handleNavLinkClick}>Wedding Return Gifts</NavLink>
                          <NavLink className="dropdown-item" to="/services/diwali-corporate-gifting" onClick={handleNavLinkClick}>Diwali Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/eid-corporate-gifting" onClick={handleNavLinkClick}>Eid Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/dhanteras-corporate-gifting" onClick={handleNavLinkClick}>Dhanteras Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/golf-corporate-gifting" onClick={handleNavLinkClick}>Golf Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/corporate-gifts-for-clients" onClick={handleNavLinkClick}>Gifts for Clients</NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to='/about' onClick={handleNavLinkClick}>About</NavLink>
              </li>
              <li className="nav-item mx-2">
                <a className="nav-link" href="https://giftingy.com/blogs/" onClick={handleNavLinkClick}>Blogs</a>
              </li>
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to="/contact" onClick={handleNavLinkClick}>Contact</NavLink>
              </li>
              <li className="nav-item mx-2">
                <form className="form-inline my-2 my-lg-0">
                  <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                  <button className="btn btn-outline-success my-2 my-sm-0 btns1" type="submit">Search</button>
                </form>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
