import React from 'react';
import './OnSaleProductsSection.css';

const mainData = {
  title: 'Bags & Leather',
  description: 'Exquisite bags and leather accessories personalized to match your style and purpose.',
  image: '/images/bigimg.jpg'
};

const sideData = [
  {
    id: 1,
    title: 'Frames & Home Decors',
    description: 'Elegant frames and decor items customized to bring warmth and character to any space.',
    image: '/images/frames.jpg'
  },
  {
    id: 2,
    title: 'Vastu & Props',
    description: 'Meaningful Vastu gifts and symbolic props tailored for balance and positivity.',
    image: '/images/timer.jpg'
  }
];

function OnSaleProductsSection() {
  return (
    <div className='darkbg'>
    <div className="onsale-container container">
      <h2 className="onsale-header lightBg">
        <i className="fas fa-tag"></i> Personalised Gifting That’s Used & Remembered <i className="fas fa-tag"></i>
      </h2>
   
      <div className="row onsale-content">
        <div className="col-lg-6 onsale-main">
          <div className="image-wrapper">
            <img src={mainData.image} alt={mainData.title} className="img-fluid" />
            <div className="overlay">
              <h3>{mainData.title}</h3>
              <p>{mainData.description}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 onsale-side">
          {sideData.map(item => (
            <div key={item.id} className="image-wrapper mb-3">
              <img src={item.image} alt={item.title} className="img-fluid" />
              <div className="overlay">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
}

export default OnSaleProductsSection;
