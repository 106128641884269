import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/ganeshimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalised Ganesh Chaturthi gift ideas tailored to your preferences and corporate identity." },
    { title: "Branding on Gifts", detail: "Enhance brand visibility with custom logos and messaging on your corporate gifts." },
    { title: "Value for Money", detail: "High-quality Ganesh Chaturthi gifts that fit within your budget." },
    { title: "Concept Creation", detail: "Innovative gift ideas that capture the spirit of Ganesh Chaturthi." },
    { title: "Diverse Range of Gifts", detail: "We offer various gifts, from traditional idols to modern hampers." },
    { title: "Quality Assurance", detail: "Each gift undergoes rigorous quality checks to ensure excellence." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services for all your corporate gifting needs." },
    { title: "Eco-Friendly Options", detail: "Choose from our range of sustainable and responsible gifting options." },
];
const GaneshChaturthiCorporateGifting = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
       
      }, []);
const faqs =[
   {
       "question": "What are some popular Ganesh Chaturthi corporate gifting ideas?",
       "answer": "Popular Ganesh Chaturthi corporate gifting ideas include traditional idols, sweet boxes, decorative items, and personalised gift hampers. These gifts are ideal for both employees and clients and reflect the festive spirit and cultural significance of Ganesh Chaturthi. Customisation options like adding your company logo or a personalised message can make these gifts more meaningful.",
       "open": false
   },
   {
       "question": "Why is corporate gifting important during Ganesh Chaturthi?",
       "answer": "Corporate gifting during Ganesh Chaturthi strengthens relationships with employees, clients, and partners. It is a way to show appreciation and goodwill during a significant cultural festival. Thoughtful gifts during this time can enhance brand loyalty, promote a positive work environment, and reflect the company's values.",
       "open": false
   },
   {
       "question": "How do I choose the best Ganesh Chaturthi corporate gifts for employees?",
       "answer": "Choosing the best Ganesh Chaturthi corporate gifts for employees involves considering their preferences and the cultural relevance of the gift. Traditional gifts like idols and sweets are always appreciated, while modern options like personalised hampers or eco-friendly items can add a unique touch. Giftingy offers many options, ensuring your gifts are meaningful and memorable.",
       "open": false
   },
   {
       "question": "Can I order Ganesh Chaturthi corporate gifts in bulk?",
       "answer": "Giftingy offers bulk ordering for Ganesh Chaturthi corporate gifting. Bulk orders are ideal for large teams or client bases, ensuring consistency and cost-effectiveness. We handle everything from customisation to timely delivery, ensuring that your gifts reach all recipients in perfect condition and on time.",
       "open": false
   },
   {
       "question": "What are the benefits of personalised Ganesh Chaturthi corporate gifts?",
       "answer": "Personalised Ganesh Chaturthi corporate gifts add a special touch that makes the recipient feel valued and appreciated. Customisation can include adding the recipient's name, a personal message, or the company logo, making the gift more meaningful. Personalised gifts are more memorable and can enhance the overall impact of your corporate gifting efforts.",
       "open": false
   },
   {
       "question": "What makes Giftingy the best choice for Ganesh Chaturthi corporate gifting?",
       "answer": "Giftingy is the best choice for Ganesh Chaturthi corporate gifting because of our extensive range of high-quality gifts, customisation options, and commitment to customer satisfaction. We understand the cultural significance of Ganesh Chaturthi and offer gifts that are both appropriate and impactful, ensuring that your corporate gifting efforts are successful.",
       "open": false
   },
   {
       "question": "What are some unique Ganesh Chaturthi return gift ideas?",
       "answer": "Unique Ganesh Chaturthi's return gift ideas include eco-friendly products, personalised keepsakes, traditional idols with a modern twist, and gourmet sweet hampers. These gifts are perfect for expressing gratitude to employees and clients during the festival and leaving a lasting impression.",
       "open": false
   },
   {
       "question": "How can I ensure the timely delivery of Ganesh Chaturthi corporate gifts?",
       "answer": "To ensure timely delivery of Ganesh Chaturthi corporate gifts, plan and place your orders early. Giftingy has a reliable delivery network guarantees your gifts will reach recipients on time, in perfect condition, and ready to be presented during the festival.",
       "open": false
   },
   {
       "question": "What are the latest trends in Ganesh Chaturthi corporate gifting?",
       "answer": "The latest Ganesh Chaturthi corporate gifting trends include eco-friendly gifts, personalised hampers, and handmade traditional items. There is also a growing preference for sustainable gifts that reflect a commitment to environmental responsibility. Giftingy offers a variety of trendy and conventional gifts that cater to modern preferences.",
       "open": false
   },
   {
       "question": "Can I customise Ganesh Chaturthi gift hampers for corporate clients?",
       "answer": "Giftingy offers customisation options for Ganesh Chaturthi gift hampers tailored to your corporate clients' preferences. From selecting specific items to adding personalised messages or branding, we ensure your hampers are unique and memorable, positively impacting your business relationships.",
       "open": false
   },
   {
       "question": "What are the advantages of choosing eco-friendly Ganesh Chaturthi corporate gifts?",
       "answer": "Eco-friendly Ganesh Chaturthi corporate gifts reflect a commitment to sustainability and environmental responsibility. These gifts are made from sustainable materials and are highly appreciated by recipients who value green practices. By choosing eco-friendly gifts, companies can enhance their brand image as socially responsible and environmentally conscious, promoting positive associations with their brand.",
       "open": false
   },
   {
       "question": "How do I choose the right Ganesh Chaturthi gifts for clients?",
       "answer": "Choosing the right Ganesh Chaturthi gifts for clients involves understanding their preferences and cultural significance. Traditional gifts like idols and sweet boxes are always appreciated, while personalised hampers or luxury items can make a lasting impression. It's important to select gifts that reflect your company's values and show appreciation for the client's partnership. Giftingy offers a variety of high-quality options that cater to different client needs.",
       "open": false
   },
   {
       "question": "What are some cost-effective Ganesh Chaturthi gift options for corporate gifting?",
       "answer": "Cost-effective Ganesh Chaturthi gift options include smaller idols, traditional sweet boxes, decorative items, and personalised tokens. These affordable yet meaningful gifts make them ideal for large-scale corporate gifting. Giftingy provides a range of cost-effective gifts that maintain quality, ensuring that your corporate gifting efforts are impactful and budget-friendly.",
       "open": false
   },
   {
       "question": "Why is customisation important in Ganesh Chaturthi corporate gifting?",
       "answer": "Customisation adds a personal touch that makes the gift more meaningful and memorable. It allows the company to reflect its brand identity while showing appreciation for the recipient. Customising gifts with the company logo, personalised messages, or the recipient's name enhances the gift's value, making it stand out and fostering stronger business relationships.",
       "open": false
   },
   {
       "question": "How can Ganesh Chaturthi's corporate gifts help in strengthening business relationships?",
       "answer": "Ganesh Chaturthi corporate gifts help strengthen business relationships by showing appreciation and respect for cultural values. Thoughtful and well-chosen gifts demonstrate the company's commitment to maintaining and enhancing relationships with employees, clients, and partners. Regular gifting during significant festivals like Ganesh Chaturthi fosters goodwill, loyalty, and positive associations with the brand, leading to long-term business success.",
       "open": false
   }
]
 
return (
<>
<MetaTags
        title="Premier Ganesh Chaturthi Corporate Gifting Solutions in India"
        description="Discover unique Ganesh Chaturthi corporate gifting ideas with Giftingy. We offer customised solutions for employees and clients, from bulk gifts to exclusive hampers."
        keywords="Ganesh Chaturthi corporate gifting, Ganesh Chaturthi's corporate gifting ideas, Ganesh Chaturthi corporate gifting for employees, Ganesh Chaturthi corporate gifting for clients, Ganesh Chaturthi corporate gifting bulk, Ganesh Chaturthi gifting bulk buy, Ganesh Chaturthi gift ideas, Ganesh Chaturthi return gift ideas, Ganesh Chaturthi gift hampers, Ganesh Chaturthi gift hampers wholesale, Ganesh Chaturthi festival gifts, Ganesh Chaturthi festival gifts corporate, Ganesh Chaturthi festival gifts for clients, Ganesh Chaturthi Corporate Gifts India"
      />
<ServiceHeader title="Ganesh Chaturthi Corporate Gifting" imageUrl={`${process.env.PUBLIC_URL}/images/ganeshbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">Celebrate with Thoughtful Ganesh Chaturthi Corporate Gifting</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Ganesh Chaturthi is a time of joy, devotion, and new beginnings. The festival brings people together, making it the perfect occasion to express gratitude and appreciation through corporate gifting. Ganesh Chaturthi corporate gifting is a meaningful way to strengthen relationships with employees, clients, and partners. Whether you're looking for traditional Ganesh Chaturthi gift ideas or modern, customised hampers, there are numerous options to make this festival memorable.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      At Giftingy, we understand the significance of Ganesh Chaturthi and offer a curated selection of Ganesh Chaturthi corporate gifts in India. From elegant Ganesh Chaturthi gift hampers to thoughtful Ganesh Chaturthi return gift ideas, our collection is designed to cater to all your corporate gifting needs. Whether you require bulk orders or special gifts for key clients, we ensure that your gifts reflect the festival's spirit and your company's values.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Ganesh Chaturthi corporate gifting ideas are versatile and can include a range of products such as traditional idols, sweet boxes, home decor items, and personalised gifts that carry the blessings of Lord Ganesha. These gifts are a token of goodwill and a way to wish prosperity and success to your employees and clients.

      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Choosing the perfect Ganesh Chaturthi corporate gift requires considering the cultural significance and the recipient's preferences. Here are some key aspects that make Ganesh Chaturthi gifts a popular choice:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Symbolises blessings and prosperity, making it a meaningful gift
               </p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Reflects respect for traditions and cultural values</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Suitable for various corporate occasions, from festive celebrations to employee appreciation</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Offers a range of options, from decorative idols to personalised gift hampers</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Makes a lasting impression by celebrating the spirit of Ganesh Chaturthi</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Adds value to the recipient’s festive décor and celebrations</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Ideal for both personal and corporate gifting during the festive season</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Customisable options available for a more thoughtful and personalised gift</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">High-quality materials ensure durability and cultural significance</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Can be purchased in bulk or wholesale for corporate and large-scale festive events</p>
            </li>
         </div>
      </ul>
     
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Ganesh Chaturthi Corporate Gifting?</h2>
      <p >Selecting the perfect Ganesh Chaturthi corporate gifts requires understanding cultural significance, quality, and personalisation. At Giftingy, we specialise in creating premium Ganesh Chaturthi corporate gifting solutions that resonate with the recipient and reflect the festive spirit. Here's why Giftingy should be your top choice:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/ganeshimg2.jpg`} alt="Festive Dussehra Corporate Gifting Ideas" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <div className="box-content ">
              <ul>
               <li>
               Wide Range of Options: Our collection includes traditional Ganesh Chaturthi gift ideas, modern hampers, and personalised corporate gifts catering to diverse tastes and preferences.
               </li>
               <li>
               Customisation and Branding: We offer customisation options that allow you to add a personal touch to your gifts. Whether it's adding your company logo or individual messages, we ensure your gifts are unique and memorable.
               </li>
               <li>
               Bulk Order Management: Whether you need Ganesh Chaturthi corporate gifting bulk for your entire workforce or exclusive gifts for key clients, Giftingy provides seamless bulk order management to ensure timely delivery and consistency in quality.
               </li>
              </ul>
               </div>
            </div>
            <div className="box box-one wow fadeInUp">
               <div className="box-content">
               <ul>
               <li>
               High-Quality Products: All our Ganesh Chaturthi festival gifts are sourced from reputable vendors and undergo strict quality checks to ensure that every gift is of the highest standard.

               </li>
               <li>
               Cultural Sensitivity: We understand the cultural significance of Ganesh Chaturthi and ensure that our gifts are appropriate and respectful, making them ideal for corporate gifting.
               </li>
               <li>
               Timely Delivery: We guarantee timely delivery of all orders, ensuring that your gifts reach your recipients in perfect condition and are ready to be presented during the festival.

               </li>
               <li>
               Eco-Friendly Options: We offer a selection of eco-friendly Ganesh Chaturthi corporate gifts that reflect your company's commitment to sustainability and social responsibility.

               </li>
              </ul>
               </div>
            </div>
       
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide Range of Gifts: We offer diverse gifts catering to various tastes and budgets.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisation Available: Personalise your gifts with company logos or special messages.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Quality Assurance: Each gift is carefully selected to meet our high standards.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable Delivery: We ensure that your gifts are delivered on time, every time.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Bulk Order Expertise: Our bulk buying options allow cost-effective and consistent gifting.

            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Festive Packaging: Our gifts come in beautifully designed packaging that enhances the festive spirit.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Eco-Friendly Options: We offer sustainable gifts that align with corporate social responsibility goals.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Client and Employee-Focused: Our gifts are designed to appeal to clients and employees, making them versatile for any corporate need.
            </p>
         </div>
         <p className='textWhite'>Expertise in Corporate Gifting: With years of experience, we understand the nuances of corporate gifting and provide tailored solutions.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Trusted Partner for Ganesh Chaturthi Corporate Gifting</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on offering exceptional Ganesh Chaturthi corporate gifting solutions that cater to your unique needs and preferences. Our unique selling propositions (USPs) make us the top choice for businesses looking to celebrate Ganesh Chaturthi with thoughtful gifts:
            </p>
           
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/ganeshimg3.jpg`} alt="Elegant Dussehra Corporate Gifts" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="Dussehracorporategifts" />

<FaqPage faqs={faqs} />
</>
);
};
export default GaneshChaturthiCorporateGifting;