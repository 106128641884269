import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaFilePdf } from 'react-icons/fa';
import './CatalogueView.css'; // Ensure this file contains the required styles

const ITEMS_PER_PAGE = 6;
const ICONS_PER_ROW = 3;

const CatalogueView = ({ title, onClick, imageUrl }) => (
  <div className="col-md-4 mb-3" onClick={onClick} style={{ cursor: 'pointer' }}>
    <div className="card1 text-center p-3">
      <img src={imageUrl} alt={title} className="pdf-image" />
      <p className="mt-2">{title}</p>
    </div>
  </div>
);

CatalogueView.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

const Catalogue = ({ pdfs = [] }) => {
  const [selectedPdf, setSelectedPdf] = useState(pdfs.length > 0 ? pdfs[0].url : '');
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(pdfs.length / ITEMS_PER_PAGE);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const currentPdfs = pdfs.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <div className="hideThis container-fluid">
      <div className="row pdfimg">
        {/* PDF Icons */}
        <div className="col-md-7 pdfedits">
          <div className="row pdfimginner">
            {currentPdfs.length > 0 ? (
              currentPdfs.map((pdf, index) => (
                <CatalogueView
                  key={index}
                  title={pdf.title}
                  imageUrl={pdf.imageUrl}
                  onClick={() => setSelectedPdf(pdf.url)}
                />
              ))
            ) : (
              <p>No catalogues available.</p>
            )}
          </div>
        </div>
        
        {/* PDF Viewer */}
        <div className="col-md-5 pdfedit" >
          {selectedPdf ? (
            <iframe
              src={`${selectedPdf}#toolbar=0&navpanes=0&scrollbar=0`}
              title="PDF Viewer"
              className="pdf-viewer"
              style={{ width: '100%', height: '500px', border: 'none' }}
            ></iframe>
          ) : (
            <p>Select a catalogue item to view the PDF.</p>
          )}
        </div>
      </div>

      {/* Pagination */}
      <div className="col-md-12 text-center mt-3">
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button 
                className={`page-link ${currentPage === 1 ? 'disabled' : ''}`}
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button 
                className={`page-link ${currentPage === totalPages ? 'disabled' : ''}`}
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

Catalogue.propTypes = {
  pdfs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Catalogue;
