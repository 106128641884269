import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/makarimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalise your Makar Sankranti gifts with unique designs that resonate with the festival's essence and your company's branding." },
    { title: "Branding on Gifts", detail: "Enhance brand visibility with custom-branded packaging and products that keep your company top-of-mind for recipients." },
    { title: "Value for Money", detail: "High-quality gifts offer great value, ensuring a memorable experience for both the giver and the receiver." },
    { title: "Concept Creation", detail: "Our creative team brings innovative ideas to life, offering unique gift concepts tailored to your needs." },
    { title: "Diverse Range of Gifts", detail: "Choose from a wide selection of traditional and modern Makar Sankranti gifts, perfect for any corporate setting." },
    { title: "Quality Assurance", detail: "Every gift is crafted carefully, ensuring premium quality that reflects your brand's commitment to excellence." },
    { title: "Timely Delivery", detail: "Rely on our efficient delivery network to ensure your gifts arrive on time and are ready to be presented during the festival." },
    { title: "Eco-Friendly Options", detail: "Show your commitment to sustainability with our range of eco-friendly Makar Sankranti gifts." },
];
const MakarSankrantiCorporateGifts = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
      }, []);
const faqs =[
    {
        "question": "What are popular Makar Sankranti corporate gift ideas?",
        "answer": "Popular Makar Sankranti corporate gift ideas include traditional sweets, personalised hampers, branded gift sets, and eco-friendly products. Companies often choose items that reflect the festival's cultural significance, such as tilgul (sesame and jaggery sweets), decorative kites, and handcrafted items. Giftingy offers a variety of options to suit different corporate needs.",
        "open": false
    },
    {
        "question": "How can I customise Makar Sankranti corporate gifts?",
        "answer": "Customising Makar Sankranti corporate gifts involves adding personal touches such as the recipient's name, a personalised message, or your company logo. Giftingy offers extensive customisation options, allowing you to select specific items for hampers, choose packaging designs, and include branding elements that enhance the gift's impact.",
        "open": false
    },
    {
        "question": "What are the benefits of choosing eco-friendly Makar Sankranti gifts?",
        "answer": "Choosing eco-friendly Makar Sankranti gifts reflects a commitment to sustainability and environmental responsibility. These gifts are made from sustainable materials and practices, which recipients increasingly value. Eco-friendly gifts also enhance your brand's image as socially responsible and environmentally conscious, promoting positive associations with your brand.",
        "open": false
    },
    {
        "question": "How do I choose the right Makar Sankranti gifts for employees?",
        "answer": "Selecting the right Makar Sankranti gifts for employees involves understanding their preferences and the festival's cultural significance. Traditional items like sweets and decorative kites are always appreciated, while personalised gifts can add a special touch. Giftingy offers a range of high-quality options that reflect thoughtfulness and appreciation for your employees' contributions.",
        "open": false
    },
    {
        "question": "Can I order Makar Sankranti gifts in bulk for corporate gifting?",
        "answer": "Giftingy provides Makar Sankranti gifts in bulk for corporate gifting. Bulk orders are ideal for large-scale celebrations and ensure consistency in quality and design across all gifts. Giftingy offers competitive pricing for bulk orders and customisation options to make your gifts unique and memorable.",
        "open": false
    },
    {
        "question": "What are some unique Makar Sankranti corporate gift ideas?",
        "answer": "Unique Makar Sankranti corporate gift ideas include personalised kites, eco-friendly gift hampers, luxury dry fruit boxes, and branded wellness kits. These gifts stand out by combining tradition with modernity, offering a memorable way to celebrate the festival. Giftingy specialises in curating such unique gifts, ensuring they resonate with the festival's significance and your brand's image.",
        "open": false
    },
    {
        "question": "How can Makar Sankranti corporate gifts enhance business relationships?",
        "answer": "Makar Sankranti corporate gifts strengthen business relationships by demonstrating thoughtfulness and cultural sensitivity. Gifting during this festival shows appreciation for clients' and employees' contributions, fostering goodwill and loyalty. Thoughtfully chosen gifts that reflect the cultural importance of Makar Sankranti can strengthen professional ties and create lasting positive impressions.",
        "open": false
    },
    {
        "question": "Why should companies invest in branded Makar Sankranti gifts?",
        "answer": "Companies should invest in branded Makar Sankranti gifts to enhance brand visibility and reinforce their corporate identity. Branded gifts are a constant reminder of your company to the recipients, helping maintain top-of-mind awareness. Giftingy offers a range of branded gift options that can be customised with your company logo and branding elements, ensuring your gifts leave a lasting impact.",
        "open": false
    },
    {
        "question": "What are the benefits of personalised Makar Sankranti gifts?",
        "answer": "Personalised Makar Sankranti gifts add a unique and thoughtful touch to corporate gifting. You create a more meaningful connection with the recipient by customising gifts with the recipient's name, a special message, or your company's logo. Personalised gifts are more memorable and are likely to be appreciated more than generic ones, helping to strengthen business relationships and foster loyalty.",
        "open": false
    },
    {
        "question": "How early should I place orders for Makar Sankranti corporate gifts?",
        "answer": "It's advisable to place orders well in advance, especially if you require bulk orders or customisation. Early ordering allows sufficient time to select the right gifts, customise them according to your needs, and ensure timely delivery. Giftingy offers streamlined processes for early orders, helping you avoid last-minute rushes and ensuring your gifts are timely.",
        "open": false
    },
    {
        "question": "Can Makar Sankranti corporate gifts be eco-friendly?",
        "answer": "Yes, Makar Sankranti corporate gifts can be eco-friendly. Many companies, including Giftingy, offer a variety of sustainable and environmentally conscious gifting options, such as reusable packaging, organic products, and gifts made from recycled materials. Choosing eco-friendly gifts aligns with the principles of sustainability and reflects a company's commitment to environmental responsibility.",
        "open": false
    },
    {
        "question": "What should I consider when selecting Makar Sankranti gifts for clients?",
        "answer": "When selecting Makar Sankranti gifts for clients, assess their preferences, cultural significance, and the message you want to convey. It is important to choose meaningful gifts that reflect your business relationship. High-quality, personalised, or branded gifts are ideal as they demonstrate thoughtfulness and appreciation. Giftingy provides a wide range of options that cater to different client preferences, ensuring your gifts are well-received.",
        "open": false
    },
    {
        "question": "How do Makar Sankranti gifts for employees boost morale?",
        "answer": "Makar Sankranti gifts for employees boost morale by recognising their hard work and contributions in a culturally meaningful way. Gifting during this festival creates a sense of belonging and appreciation among employees, increasing motivation and job satisfaction. Thoughtful and personalised gifts make employees feel valued, enhancing their loyalty to the company.",
        "open": false
    },
    {
        "question": "Are there luxury Makar Sankranti corporate gift options available?",
        "answer": "Luxury Makar Sankranti corporate gifts are available and perfect for clients or partners who appreciate high-end, exclusive gifts. These include luxury hampers, branded gourmet products, premium handcrafted items, or personalised luxury goods. Giftingy offers a selection of luxury gifts that can be customised to align with your brand's prestige and the recipient's preferences.",
        "open": false
    },
    {
        "question": "What are some cost-effective Makar Sankranti gift ideas for small businesses?",
        "answer": "Cost-effective Makar Sankranti gift ideas for small businesses include personalised tokens, small traditional gift boxes, branded items, and eco-friendly gifts. These gifts can be both budget-friendly and impactful, ensuring that they still convey appreciation and foster goodwill. Giftingy provides affordable options that do not compromise quality or thoughtfulness, making them ideal for small businesses.",
        "open": false
    }
]

return (
<>
<MetaTags
        title="Premium Makar Sankranti Corporate Gifts for Clients and Employees"
        description="Giftingy offers a wide range of Makar Sankranti corporate gifts. Discover personalised hampers, branded gifts, and bulk options perfect for employees and clients."
        keywords="Makar Sankranti corporate gifts, Makar Sankranti corporate gifting, Makar Sankranti corporate gift ideas, Makar Sankranti corporate gifts for clients, Makar Sankranti corporate gifts for employees, Makar Sankranti corporate gifts India, Makar Sankranti gifting bulk, Makar Sankranti gifting hampers, Makar Sankranti gift hampers, Branded Makar Sankranti corporate gifts, Personalised Makar Sankranti corporate gifts"
      />
<ServiceHeader title="Makar Sankranti Corporate Gifts" imageUrl={`${process.env.PUBLIC_URL}/images/makarbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Significance of Makar Sankranti Corporate Gifting</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Makar Sankranti, a significant harvest festival celebrated across India, marks the sun's transition into the zodiac sign of Capricorn. This festival symbolises new beginnings, prosperity, and the joy of giving. Corporate gifting during Makar Sankranti is a thoughtful way to strengthen business relationships, show appreciation to employees, and convey goodwill to clients. Companies across India embrace this opportunity to express gratitude and foster a positive work environment through meaningful gifts.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Corporate gifting for Makar Sankranti can range from traditional items like sweets and tilgul (sesame and jaggery) delicacies to modern, branded hampers. The diversity in gifting options allows businesses to tailor their gifts to suit their recipients' preferences and cultural sentiments. Whether you are looking for high-end gifts for clients or unique, personalised items for employees, Makar Sankranti corporate gifts are a perfect way to connect with stakeholders and enhance your brand image.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Key Benefits of Makar Sankranti Corporate Gifting:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Strengthens business relationships with clients and partners</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhances employee morale and loyalty</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Reflects cultural sensitivity and appreciation</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes brand visibility and recall</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Offers a range of personalised and branded options</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Suitable for both bulk orders and individual gifting</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Encourages the spirit of sharing and celebration</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Ideal for corporate gifting in various sectors</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Supports local artisans and traditional crafts</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Creates lasting impressions with thoughtful gifts</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>Makar Sankranti corporate gifts go beyond mere transactions; they celebrate the essence of the festival by fostering connections and goodwill among all recipients.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Makar Sankranti Corporate Gifts?</h2>
      <p >Giftingy, a leading name in the corporate gifting industry, specialises in creating exceptional Makar Sankranti corporate gifts that resonate with the festival's spirit. Here's why Giftingy should be your preferred partner for Makar Sankranti corporate gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/makarimg2.jpg`} alt="Unique Makar Sankranti Corporate Gifting Ideas" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we understand the importance of cultural celebrations in corporate environments. Our Makar Sankranti corporate gifting solutions are thoughtfully curated to reflect the essence of the festival while aligning with your company's branding and values. Whether you are looking for branded Makar Sankranti gifts, personalised hampers, or unique gift ideas, Giftingy offers a diverse range of options that cater to various corporate needs.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               Our expertise lies in offering customisation that adds a personal touch to your gifts. From personalised messages to branded packaging, we ensure each gift is unique and memorable. As a trusted supplier of Makar Sankranti corporate gifts in India, Giftingy guarantees high-quality products delivered on time, making your corporate gifting experience seamless and satisfying.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy specialises in bulk gifting, making us the perfect partner for corporate celebrations. Our seamless and hassle-free process ensures that your gifts are delivered on time and in perfect condition. From unique Dhanteras gifting ideas to premium festive hampers, we offer various choices to cater to your needs. Our commitment to customer satisfaction and ability to create lasting impressions make us the preferred choice for Dhanteras corporate gifting in India.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     <p className='textWhite text-center'>Why Choose Giftingy?</p>
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive range of Makar Sankranti corporate gift ideas
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality products with a focus on traditional and modern gifts

            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Personalised and branded gifting options to enhance brand visibility
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Timely delivery across India, ensuring your gifts reach recipients on time
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise in bulk gifting solutions for large corporations
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Commitment to customer satisfaction with dedicated support services
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Eco-friendly and sustainable gifting options are available

            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Collaboration with local artisans to promote traditional crafts
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Competitive pricing for premium and luxury gifting
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Trusted by top companies for corporate gifting in India
            </p>
         </div>
         <p className='textWhite'>With Giftingy, your Makar Sankranti corporate gifting is not just a gesture; it's an experience that strengthens relationships and builds lasting connections.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy for Makar Sankranti Gifts</h2>
            <h3 className='textYellow'>Giftingy: Your Partner in Celebrating Makar Sankranti with Thoughtful Corporate Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            Giftingy is committed to delivering exceptional Makar Sankranti corporate gifts that reflect the festival's significance and your brand's values. Our unique selling propositions (USPs) make us the go-to choice for companies looking to make a lasting impression through corporate gifting.
            </p>
       
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/makarimg3.jpg`} alt="Makar Sankranti Corporate Gifts India" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="luxuryHomeDecor" />

<FaqPage faqs={faqs} />
</>
);
};
export default MakarSankrantiCorporateGifts;