import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import './ServiceHeader.css';

const ServiceHeader = ({ title, imageUrl }) => {
    return (
        <div className="services-header">
            <section className="hero">
                <div className="hero-overlay">
                    <h1 className="wow fadeInUp">{title}</h1>
                </div>
                <img className="hero-img" src={imageUrl} alt={title} />
                
                <div className="form-container">
                    {/* Your form elements go here */}
                </div>
            </section>
        </div>
    );
};

ServiceHeader.propTypes = {
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
};

export default ServiceHeader;
