import React, { useState } from 'react';
import '../components/FourImagesSection.css';
import Popup from './Popup'; // Import the Popup component

const FourImagesSection = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const products = [
    {
      id: 1,
      img: '/images/newimg1.jpg',
      description: 'Product 1 Description',
      price: '₹999',
    },
    {
      id: 2,
      img: '/images/newimg3.jpg',
      description: 'Product 2 Description',
      price: '₹1299',
    },
    {
      id: 3,
      img: '/images/newimg2.jpg',
      description: 'Product 3 Description',
      price: '₹1599',
    },
    {
      id: 4,
      img: '/images/newimg4.jpg',
      description: 'Product 4 Description',
      price: '₹1999',
    },
  ];

  return (
    <div className="four-images-section">
      {products.map((product) => (
        <div className="image-container" key={product.id}>
          <img src={product.img} alt={`Product ${product.id}`} />
          <div className="overlay1">
            <div className="details">
              <button className="price-button" onClick={togglePopup}>
                Enquire Now 
              </button>
              <div className="arrow">→</div>
            </div>
          </div>
        </div>
      ))}

      <Popup showPopup={showPopup} togglePopup={togglePopup} />
    </div>
  );
};

export default FourImagesSection;
