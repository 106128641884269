import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import './TopHeader.css';

function TopHeader() {
  return (
    <div className="top-header">
      <div className=" custom-container">
        <div className="top-header-left">
         Corporate Gifting Company
        </div>
        <div className="top-header-right">
         <a className='anchor' href='tel:+91-9990158777'> <FontAwesomeIcon icon={faPhoneAlt} /> +91-9990158777</a>
        </div>
      </div>
    </div>
  );
}

export default TopHeader;
