import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/milestoneimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalised gifts tailored to your preferences." },
    { title: "Branding on Gifts", detail: "Keeps your brand memorable and visible." },
    { title: "Value for Money", detail: "High-quality gifts within your budget." },
    { title: "Concept Creation", detail: "Unique and innovative gift ideas." },
    { title: "Diverse Range of Gifts", detail: "Extensive selection for all occasions." },
    { title: "Quality Assurance", detail: "Premium quality guaranteed." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible gifting." },
];
const MilestonesAndCelebrations = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
        
      }, []);
const faqs = [
    {
      "question": "What are good corporate gifts for milestones?",
      "answer": "Good corporate gifts for milestones include luxury accessories, personalised items, and branded products. Popular choices are elegant watches, premium leather goods, and sophisticated office accessories. The key is to choose gifts that reflect the recipient's achievements and the milestone's significance. Consulting a corporate gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "Why is corporate gifting for milestones and celebrations important?",
      "answer": "Corporate gifting for milestones and celebrations is important because it expresses appreciation and recognises achievements. These gifts strengthen professional relationships and foster a positive work environment. Thoughtful and well-chosen gifts reflect the company's appreciation and commitment to celebrating success. They also promote brand loyalty and enhance company culture.",
      "open": false
    },
    {
      "question": "What are some popular corporate gifts for employee anniversaries?",
      "answer": "Popular corporate gifts for employee anniversaries include personalised accessories, luxury gift sets, and branded products. Handcrafted, elegant watches, premium leather goods, and sophisticated office accessories are also appreciated. Consider custom-designed gifts, luxury grooming sets, and exclusive branded products. These gifts reflect the company's appreciation and celebrate the achievements of employees.",
      "open": false
    },
    {
      "question": "How do I choose the right corporate gifts for milestones?",
      "answer": "Choosing the right corporate gifts for milestones involves understanding the recipient's preferences and achievements. Opt for high-quality items that reflect thoughtfulness and significance. Personalisation can add a special touch, making the gifts more meaningful. Consider the practicality and relevance of the gifts to ensure they are appreciated and used. Consulting a corporate gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "What are the benefits of working with a corporate gifting company for milestone gifts?",
      "answer": "Working with a corporate gifting company ensures you receive high-quality, well-crafted items that reflect the recipient's achievements and the company's appreciation. These companies offer various customisation options to make your gifts unique and personal. They provide expertise in selecting and designing gifts that suit your needs and preferences. Reliable delivery services and excellent customer support are additional benefits. Partnering with a reputable company ensures a seamless and satisfactory gifting experience.",
      "open": false
    },
    {
      "question": "Can I order corporate gifts for milestones in bulk?",
      "answer": "You can order corporate gifts for milestones in bulk from various gifting companies. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a corporate gifting company can provide valuable insights and options for bulk orders.",
      "open": false
    },
    {
      "question": "What are some premium corporate gift options for milestones?",
      "answer": "Premium corporate gift options for milestones include bespoke luxury accessories, personalised high-end products, and branded gift sets. Handcrafted, elegant watches, premium leather goods, and sophisticated office accessories are also appreciated. Consider custom-designed gifts, luxury grooming sets, and exclusive branded products. These gifts reflect thoughtfulness and are ideal for premium corporate gifting.",
      "open": false
    },
    {
      "question": "How do milestone gifts enhance corporate celebrations?",
      "answer": "Milestone gifts enhance corporate celebrations by adding a touch of significance and appreciation. They are ideal for company anniversaries, client appreciation events, and employee recognition programs. These gifts reflect the company's commitment to celebrating success and achievements. Customised gifts make the celebration more memorable and meaningful. They foster a positive and respectful corporate culture.",
      "open": false
    },
    {
      "question": "What are some unique milestone gift ideas for corporate events?",
      "answer": "Unique milestone gift ideas for corporate events include bespoke luxury accessories, customised gift boxes, and handcrafted items. High-end items such as elegant watches, premium leather goods, and sophisticated office accessories are also popular. Consider custom-designed gifts, luxury grooming sets, and exclusive branded products. The key is to choose gifts that reflect the recipient's achievements and the event's significance.",
      "open": false
    },
    {
      "question": "Why should companies invest in branded milestone gifts?",
      "answer": "Companies should invest in branded milestone gifts to show appreciation and celebrate achievements among their employees, clients, and partners. These gifts enhance corporate relationships and convey a commitment to recognising success. Branded gifts are highly valued and leave a lasting impression on the recipients. They also foster a positive and respectful corporate culture. Investing in branded gifts promotes a strong and prestigious brand image.",
      "open": false
    },
    {
      "question": "How can I ensure the quality of milestone gifts for corporate gifting?",
      "answer": "Ensuring the quality of milestone gifts for corporate gifting involves selecting reputable gifting companies and suppliers. Check reviews and testimonials to gauge the reliability and quality of their products. Opt for companies with a proven track record of high-quality craftsmanship and customisation. Regularly review samples and provide clear specifications for the customisation. Partnering with a trusted corporate gifting company ensures the quality and satisfaction of your gifts.",
      "open": false
    },
    {
      "question": "What are the latest trends in corporate gifting for milestones?",
      "answer": "The latest trends in corporate gifting for milestones include eco-friendly products, luxury gift sets, and bespoke high-end accessories. Sustainable and environmentally friendly items are increasingly popular. Personalised gifts such as custom-designed accessories and engraved high-end products are in demand. Handcrafted items that reflect traditional craftsmanship are also trending. Staying updated with these trends ensures your gifts are relevant and appreciated.",
      "open": false
    },
    {
      "question": "What are the benefits of customising milestone corporate gifts?",
      "answer": "Customising milestone corporate gifts adds a personal touch that enhances the gift's value and significance. It shows that extra thought and effort have been put into selecting the gift. Personalisation can include engraving names, adding personal messages, or incorporating the company logo. Customised gifts are more memorable and appreciated by the recipients. They help foster stronger personal and corporate relationships.",
      "open": false
    },
    {
      "question": "How do milestone gifts benefit employee recognition programs?",
      "answer": "Milestone gifts benefit employee recognition programs by adding a touch of appreciation and recognition to the recognition process. They make employees feel valued and appreciated, boosting morale and motivation. Customised gifts reflect the company's commitment to recognising individual achievements. They enhance job satisfaction and loyalty, promoting a positive work environment. Thoughtful and well-chosen gifts are more impactful and meaningful than generic awards.",
      "open": false
    },
    {
      "question": "Can milestone gifts be ordered in bulk?",
      "answer": "Yes, milestone gifts can be ordered in bulk from most gifting companies. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a corporate gifting company can provide valuable insights and options for bulk orders.",
      "open": false
    },
    {
      "question": "What are some cost-effective milestone gift options?",
      "answer": "Cost-effective milestone gift options include branded accessories, personalised products, and festive hampers. Consider premium gift sets, custom-designed products, and engraved high-end accessories. The key is to choose gifts that reflect the recipient's achievements and the significance of the milestone without compromising on quality. Cost-effective gifts can still make a significant impact when chosen thoughtfully.",
      "open": false
    },
    {
      "question": "Why is personalisation important in milestone corporate gifting?",
      "answer": "Personalisation is important in milestone corporate gifting because it adds a unique and meaningful touch to the gifts. It shows that the giver has put thought into selecting the gift, making it more memorable and appreciated. Personalised gifts reflect the recipient's achievements and the significance of the milestone. They enhance the overall impact of the gift, fostering stronger relationships. Personalisation also helps in promoting brand recall and loyalty.",
      "open": false
    },
    {
      "question": "How can milestone gifts be used for client retention?",
      "answer": "Milestone gifts can be used to promote client retention by showing appreciation and celebrating achievements. Customised gifts reflect thoughtfulness and attention to detail, making clients feel valued. They create a positive association with the brand, fostering loyalty. Regularly gifting thoughtful items can enhance client satisfaction and retention rates. These gifts are a constant reminder of the company's appreciation and commitment to recognising success.",
      "open": false
    },
    {
      "question": "What are some good milestone gifts for employees?",
      "answer": "Good milestone employee gifts include customised accessories, branded products, and premium gift sets. Consider elegant watches, luxury leather goods, and personalised office accessories. These gifts reflect the company's appreciation and foster a positive work environment. Customisation with the company's logo or personal messages adds a special touch. Consulting a corporate gifting company can help select the best gifts for employees.",
      "open": false
    },
    {
      "question": "How do milestone gifts enhance personal celebrations?",
      "answer": "Milestone gifts enhance personal celebrations by adding a touch of appreciation and recognition to the festivities. They are ideal for birthdays, anniversaries, and special events. These gifts reflect thoughtfulness and the recipient's achievements, making the celebration more memorable and meaningful. Customised gifts show appreciation for the recipients and foster stronger personal connections.",
      "open": false
    },
    {
      "question": "What are some unique milestone gift ideas for corporate events?",
      "answer": "Unique milestone gift ideas for corporate events include bespoke luxury accessories, customised gift boxes, and handcrafted items. High-end items such as elegant watches, premium leather goods, and sophisticated office accessories are also popular. Consider custom-designed gifts, luxury grooming sets, and exclusive branded products. The key is to choose gifts that reflect the recipient's achievements and the event's significance.",
      "open": false
    },
    {
      "question": "Why should companies invest in branded milestone gifts?",
      "answer": "Companies should invest in branded milestone gifts to show appreciation and celebrate achievements among their employees, clients, and partners. These gifts enhance corporate relationships and convey a commitment to recognising success. Branded gifts are highly valued and leave a lasting impression on the recipients. They also foster a positive and respectful corporate culture. Investing in branded gifts promotes a strong and prestigious brand image.",
      "open": false
    },
    {
        "question": "What are some premium milestone gifts for senior executives?",
        "answer": "Premium milestone gifts for senior executives include bespoke luxury accessories, personalised high-end products, and branded gift sets. Handcrafted, elegant watches, premium leather goods, and sophisticated office accessories are also appreciated. Consider custom-designed gifts, luxury grooming sets, and exclusive branded products. The key is to choose gifts that reflect the recipient's achievements and milestone significance.",
        "open": false
      },
      {
        "question": "Why is eco-friendly gifting becoming popular in milestone corporate gifting?",
        "answer": "Eco-friendly gifting is becoming popular in milestone corporate gifting because it reflects a commitment to sustainability and environmental responsibility. Eco-friendly gifts are made from sustainable materials and processes, reducing the environmental impact. Recipients who value sustainability highly appreciate these gifts. Choosing eco-friendly gifts also enhances the company's socially responsible and environmentally conscious image.",
        "open": false
      },
      {
        "question": "How do I choose the best vendor for milestone gifts?",
        "answer": "Choosing the best vendor for milestone gifts involves researching reputable gifting companies and suppliers. Check reviews, testimonials, and portfolios to gauge their products' and services' quality and reliability. Opt for vendors with a proven track record of high-quality craftsmanship and customisation. Request samples and provide clear specifications for the customisation. Partnering with a trusted corporate gifting company ensures a seamless and satisfactory gifting experience.",
        "open": false
      },
      {
        "question": "What are some innovative milestone gift ideas for tech-savvy employees?",
        "answer": "Innovative milestone gift ideas for tech-savvy employees include personalised tech gadgets, branded tech accessories with high-end products, and luxury tech devices paired with elegant gifts. Consider wireless chargers with custom-designed accessories, Bluetooth speakers with premium products, and smartwatches with bespoke high-end gifts. Customising these items with the company logo or personal messages adds a special touch.",
        "open": false
      },
      {
        "question": "How do milestone gifts enhance corporate culture?",
        "answer": "Milestone gifts enhance corporate culture by showcasing the company's values and appreciation for its employees, clients, and partners. Thoughtful and well-chosen gifts demonstrate the company's commitment to recognising achievements, inclusivity, and success. Customisation with the company logo or branding elements enhances the corporate identity. Gifts that reflect the company's culture foster a positive and respectful work environment.",
        "open": false
      },
      {
        "question": "What are some good milestone gifts for clients under 5000?",
        "answer": "Good milestone gifts for clients under 5000 include bespoke luxury accessories, custom-designed products, and branded gift sets. Consider elegant watches, premium leather goods, and personalised office accessories. The key is choosing gifts that reflect the recipient's achievements and the milestone's significance without compromising quality. Consulting a corporate gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How do milestone gifts promote corporate social responsibility?",
        "answer": "Milestone gifts promote corporate social responsibility by reflecting the company's sustainability and community engagement commitment. Recipients highly appreciate eco-friendly and socially responsible gifts, which enhance the company's image as socially responsible and environmentally conscious. Choosing gifts that support local artisans and charitable causes also promotes corporate social responsibility.",
        "open": false
      },
      {
        "question": "What are some unique milestone gift ideas for startups?",
        "answer": "Unique milestone gift ideas for startups include branded tech gadgets with luxury accessories, personalised high-end products with startup branding, and luxury tech devices paired with elegant gifts. Consider wireless chargers with custom-designed accessories, personalised notebooks with engraved products, and premium high-end gifts with startup logos. The key is to choose gifts that reflect the recipient's achievements and innovative spirit.",
        "open": false
      },
      {
        "question": "How can milestone gifts be used for marketing and promotion?",
        "answer": "Milestone gifts can be used for marketing and promotion by enhancing brand visibility and recall. Customising gifts with the company logo or branding elements increases brand awareness. Thoughtful and well-chosen gifts create positive associations with the brand and foster goodwill. Regularly gifting thoughtful items helps maintain and strengthen business relationships, promoting long-term success.",
        "open": false
      },
      {
        "question": "What are some good milestone gifts for employees under 1000?",
        "answer": "Good milestone gifts for employees under 1000 include personalised accessories, branded products, and festive hampers. Consider premium gift sets, custom-designed products, and engraved high-end accessories. The key is choosing gifts that reflect the recipient's achievements and the milestone's significance without compromising quality. Consulting a corporate gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How do milestone gifts enhance client retention?",
        "answer": "Milestone gifts enhance client retention by showing appreciation and fostering goodwill. Thoughtful and well-chosen gifts reflect the company's gratitude for the client's support and partnership. Regularly gifting thoughtful items helps maintain and strengthen client relationships, promoting long-term business success. Customised gifts with the company logo or personal messages add a special touch, making the gifts more memorable.",
        "open": false
      },
      {
        "question": "What are some unique milestone gift ideas for corporate events?",
        "answer": "Unique milestone gift ideas for corporate events include bespoke luxury accessories, customised gift boxes, and handcrafted items. High-end items such as elegant watches, premium leather goods, and sophisticated office accessories are also popular. Consider custom-designed gifts, luxury grooming sets, and exclusive branded products. The key is to choose gifts that reflect the recipient's achievements and the event's significance.",
        "open": false
      },
      {
        "question": "How do milestone gifts benefit employee engagement programs?",
        "answer": "Milestone gifts benefit employee engagement programs by showing appreciation and recognition for employees' efforts and contributions. Thoughtful and well-chosen gifts enhance job satisfaction and motivation. Customised gifts with the company logo or personal messages add a special touch. Regularly gifting thoughtful items during corporate events fosters a positive work environment and strengthens employee engagement.",
        "open": false
      },
      {
        "question": "What are some good milestone gifts for clients under 3000?",
        "answer": "Good milestone gifts for clients under 3000 include luxury gift sets, bespoke high-end items, and personalised products. Consider high-end accessories, fine luxury products, and exclusive branded items. The key is choosing gifts that reflect the recipient's achievements and the milestone's significance without compromising quality. Consulting a corporate gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How can milestone gifts be used for team building?",
        "answer": "Milestone gifts can be used for team building by showing appreciation and fostering goodwill among team members. Thoughtful and well-chosen gifts reflect the company's gratitude for the team's efforts and contributions. Regularly gifting thoughtful items during team-building activities helps maintain and strengthen team cohesion, promoting a positive and collaborative work environment. Customised gifts with the company logo or personal messages add a special touch, making the gifts more meaningful.",
        "open": false
      }
]

return (
<>
<MetaTags
        title="Premier Corporate Gifting for Milestones and Celebrations"
        description="Discover exquisite corporate gifts for milestones and celebrations from India's leading corporate gifting company. Perfect for employee anniversaries, company achievements, and special occasions."
        keywords="Corporate gifting for milestones, Employee anniversary gifts by year, Company anniversary gifts for owners, Company anniversary gift for management, Corporate milestone gift ideas, Company gifting for annual day, Company gifting for the special day, Company gifting for special occasions, Company gifting for achievements, Company gifting for achievement announcements, Company gifting for celebration, Corporate gifting for celebration"
      />
<ServiceHeader title="Corporate Gifting for Milestones and Celebrations" imageUrl={`${process.env.PUBLIC_URL}/images/milestonebn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Importance of Corporate Gifting for Milestones and Celebrations</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Recognising milestones and celebrations within a corporate setting is crucial for fostering a positive work environment and enhancing employee morale. Corporate gifting for milestones such as employee anniversaries, company achievements, and special occasions effectively shows appreciation, celebrates success, and strengthens professional relationships. High-quality, thoughtful gifts can leave a lasting impression, showcasing the company's dedication to its employees and partners.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Corporate milestone gifts should be meaningful, elegant, and reflect the occasion's significance. The options are extensive, from employee anniversary gifts to company celebration gifts. These gifts can range from personalised gifts for management to elegant gifts for owners. By choosing the right corporate gifts, you can create lasting memories and foster a culture of recognition and appreciation.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Here are some key reasons why corporate gifting for milestones and celebrations is important:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Show appreciation and recognition</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Celebrate achievements and milestones</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Foster a positive work environment</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Strengthen professional relationships</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhance company culture and values</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promote brand loyalty and visibility</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Encourage motivation and productivity</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Create lasting memories and connections</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Support employees and clients in celebrating achievements</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Reflect the company's commitment to its people</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>Corporate gifting for milestones and celebrations is more than presents; it celebrates's achievements, dedication, and success.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Corporate Gifting for Milestones and Celebrations?</h2>
      <p >Selecting the perfect corporate gifts for milestones and celebrations requires a keen understanding of quality, personalisation, and significance. Giftingy is a premier corporate gifting company offering a curated selection of the finest gifts for various milestones and celebrations. Here's why Giftingy should be your top choice for corporate gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/milestoneimg2.jpg`} alt="Unique Corporate Milestone Gift Ideas" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we specialise in creating corporate gifts that resonate with the recipient and reflect the company's appreciation and celebratory spirit. Our corporate gift ideas include beautifully crafted items, from personalised anniversary gifts to contemporary celebration hampers. Whether you are looking for gifts for employees, management, or company owners, our collection caters to diverse tastes and preferences.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               One of our key strengths is our ability to offer personalised gifts that reflect the recipient's achievements and the occasion. We pride ourselves on being a leading corporate gifting company that delivers excellence in craftsmanship. Our products are sourced from the best vendors, ensuring that every item meets the highest standards of quality and significance.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy specialises in bulk gifting, making us the perfect partner for corporate celebrations. Our seamless and hassle-free process ensures that your gifts are delivered on time and in perfect condition. From unique milestone gift ideas to premium celebration hampers, we offer various choices to cater to your needs. Our commitment to customer satisfaction and ability to create lasting impressions make us the preferred choice for corporate gifting in India.
             </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisable gifts to suit individual preferences
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality products with meticulous craftsmanship
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide range of gifting ideas for various recipients

            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise as a corporate gifting company in India
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Convenient online ordering and customisation options
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable and timely delivery services
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Commitment to customer satisfaction
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive selection of beautifully crafted gifts
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your corporate gifting needs ensures that your gifts are significant, meaningful, and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Source for Exceptional Corporate Gifts for Milestones and Celebrations</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on offering exceptional corporate gifting solutions catering to your needs and preferences. Our unique selling propositions (USPs) set us apart as a leading corporate gifting company in India. Here's what makes us unique:
            </p>
            <p className='wow fadeInUp section-partpara section-partlheight'>Giftingy offers custom designs that add a personal touch to your gifts, making them more meaningful. Our branding on gifts ensures that your company remains at the forefront of the recipient's mind, enhancing brand recall and visibility. We provide value for money by offering high-quality gifts that fit within your budget, ensuring you get the best return on your investment.</p>
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/milestoneimg3.jpg`} alt="High-End Corporate Gifts for Management" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="KartikayGifting" />

<FaqPage faqs={faqs} />
</>
);
};
export default MilestonesAndCelebrations;