import React, { useEffect } from 'react';
import WOW from 'wowjs';
import ProductList from '../components/ProductList';
import HomeSlider from '../components/HomeSlider';
import HomeSection from '../components/HomeSection';
import Categories from '../components/Categories';
import ThreeColumnsSection from '../components/ThreeColumnsSection'; // Import the new ThreeColumnsSection component
import OnSaleProductsSection from '../components/OnSaleProductsSection'; // Import the new OnSaleProductsSection component
import CategoryList from '../components/CategoryList';
import CategorySection from '../components/CategorySection';
import SaleProduct from '../components/SaleProduct';
import FourImagesSection from '../components/FourImagesSection';
import InfoSection from '../components/InfoSection';
import FaqPage from '../components/FaqPage'; 
import ToggleSection from '../components/ToggleSection';
import FAQ from '../components/FaqHome';
function Home() {
    

    useEffect(() => {
        new WOW.WOW().init();
    
        // Set meta title, description, and keywords
        document.title = "Premier Corporate Gifting Company in India. | Giftingy";
        const metaDescription = document.querySelector('meta[name="description"]');
     
      }, []);
  const faqs =[
    {
        "question": "What is corporate gifting?",
        "answer": "Corporate gifting gives thoughtful gifts to employees, clients, and partners to show appreciation, celebrate milestones, and strengthen business relationships. This can include many items such as branded merchandise, luxury hampers, and personalised gifts. Corporate gifting aims to foster goodwill, enhance brand visibility, and create lasting impressions. By investing in high-quality corporate gifts, companies can demonstrate their commitment to their stakeholders. Giftingy, a top corporate gifting company in India, offers a diverse selection of unique and branded corporate gifts. Our products are designed to meet your recipients' specific needs and preferences. We understand the importance of making a positive impact through thoughtful gifting. Whether you need gifts for special occasions or regular employee appreciation, corporate gifting is valuable for building strong relationships—partner with Giftingy to elevate your corporate gifting experience.",
        "open": false
    },
    {
        "question": "Why is corporate gifting important?",
        "answer": "Corporate gifting is important because it helps build and strengthen professional relationships. By giving thoughtful gifts, companies can show appreciation to their employees, clients, and partners. This practice fosters goodwill and enhances the company's reputation. Corporate gifts can also increase brand visibility when branded with the company's logo. Employees who feel appreciated are more likely to be motivated and productive. Clients receiving corporate gifts are more likely to remain loyal to the company. Giftingy, a leading corporate gifting company in India, understands the value of corporate gifting. We provide high-quality gifts that make a lasting impression. By investing in corporate gifting, businesses can create a positive work environment and foster long-term business relationships.",
        "open": false
    },
    {
        "question": "How do I choose the right corporate gifts?",
        "answer": "Choosing the right corporate gifts involves understanding the recipient's preferences and the occasion. Start by identifying the purpose of the gift and the message you want to convey. Consider the recipient's cultural background and professional relationship with your company. High-quality and practical gifts are always appreciated. Personalised gifts add a special touch and make the recipient feel valued. Giftingy offers a variety of unique and branded corporate gifts to suit different needs. We can help you select the perfect gifts that reflect your brand's persona and assist with customisation to ensure the gifts are memorable. Timely delivery is also crucial for the success of your corporate gifting strategy. Partner with Giftingy to make your corporate gifting process seamless and impactful.",
        "open": false
    },
    {
        "question": "When should I engage in corporate gifting?",
        "answer": "Corporate gifting can be done on various occasions to maximise its impact. Common times for corporate gifting include festive seasons, company anniversaries, employee recognition events, and client appreciation moments. Additionally, corporate gifting can be used to celebrate milestones, such as achieving business targets or launching new projects. Giftingy, a top corporate gifting company in India, offers tailored solutions for all these occasions. We provide a diverse selection of gifts that cater to different needs and preferences. Whether you need gifts for Diwali, Christmas, or a company anniversary, we have you covered. Engaging in corporate gifting during these times helps build stronger relationships. It shows that you value and appreciate the contributions of your employees and clients—partner with Giftingy to make your corporate gifting memorable and effective.",
        "open": false
    },
    {
        "question": "Why should companies follow a corporate gifting strategy?",
        "answer": "Companies should follow a corporate gifting strategy to ensure that their gifting efforts are effective and aligned with their business goals. A well-planned strategy helps select the right gifts, timing the delivery, and personalising the items to make a lasting impact. Corporate gifting can enhance brand visibility, foster goodwill, and strengthen relationships with employees, clients, and partners. Giftingy, a leading corporate gifting company in India, can assist you in developing a successful corporate gifting strategy. We offer a wide range of high-quality, unique, and branded corporate gifts. Our team can help you choose gifts that reflect your brand and meet the needs of your recipients. Following a corporate gifting strategy can create positive experiences and build long-term relationships—partner with Giftingy to elevate your corporate gifting efforts.",
        "open": false
    },
    {
        "question": "What are the benefits of personalised corporate gifting?",
        "answer": "Personalised corporate gifting adds a special touch that makes the recipient feel valued and appreciated. Customised gifts can include the recipient's name, company logo, or a special message. This personal touch enhances the emotional connection and makes the gift more memorable. Personalised corporate gifts can also reflect the recipient's preferences, making them more meaningful. Giftingy, a top corporate gifting company in India, offers a wide range of personalised gifts. Our team can help you customise your gifts to suit the occasion and the recipient's preferences. Personalised corporate gifts also enhance brand visibility and promote brand loyalty. They show that your company values its relationships and pays attention to detail. You can create lasting impressions and foster strong relationships by choosing Giftingy for your personalised corporate gifting needs.",
        "open": false
    },
    {
        "question": "How can corporate gifting enhance employee morale?",
        "answer": "Corporate gifting can significantly boost employee morale by showing appreciation for their hard work and dedication. Recognising employees with thoughtful gifts makes them feel valued and motivated. This, in turn, enhances their productivity and job satisfaction. Corporate gifts can be given on various occasions, such as employee anniversaries, achievements, or during festive seasons. Giftingy, a leading corporate gifting company in India, offers employees a diverse selection of gifts. Our high-quality and unique gifts are designed to make employees feel appreciated. Personalised gifts add an extra touch of thoughtfulness and recognition. By investing in corporate gifting, companies can create a positive work environment. This fosters loyalty and reduces employee turnover—partner with Giftingy to enhance employee recognition programs with thoughtful corporate gifts.",
        "open": false
    },
    {
        "question": "What is the purpose of corporate gifting for clients?",
        "answer": "Corporate gifting for clients aims to show appreciation and strengthen business relationships. Thoughtful gifts can make clients feel valued and respected, fostering loyalty and goodwill. Corporate gifts can also serve as a reminder of your company's partnership. Giftingy, a premier corporate gifting company in India, provides a wide range of gifts tailored for clients. Our high-quality and branded gifts are designed to make a lasting impression. Personalised gifts add a touch of thoughtfulness and help in building stronger connections. Corporate gifting for clients can be done during festive seasons, business anniversaries, or significant milestones. By choosing Giftingy for your client gifting needs, you can enhance your business relationships and promote brand loyalty.",
        "open": false
    },
    {
        "question": "How does corporate gifting promote brand visibility?",
        "answer": "Corporate gifting promotes brand visibility by showcasing your company's branding on the gifts. Branded gifts serve as a constant reminder of your company to the recipients. They enhance brand recall and create positive associations with your brand. Giftingy, a top corporate gifting company in India, specialises in branded corporate gifts. Our high-quality and unique gifts are designed to reflect your brand's identity. Incorporating your company logo and branding elements ensures that your gifts leave a lasting impression. Corporate gifting also helps spread brand awareness among the recipients. This can increase brand recognition and loyalty—partner with Giftingy to enhance your brand visibility through thoughtful corporate gifting.",
        "open": false
    },
    {
        "question": "How do I decide on the right corporate gifting company?",
        "answer": "Deciding on the right corporate gifting company involves evaluating several factors. Look for a company with a wide range of high-quality products that cater to diverse needs. Check their reputation and customer reviews to ensure reliability and customer satisfaction. Giftingy is a leading corporate gifting company in India, known for its unique and branded corporate gifts. We offer a comprehensive range of products and customisation options for various occasions and preferences. Our team is dedicated to providing exceptional customer service and ensuring timely delivery. Partnering with Giftingy ensures that your corporate gifting needs are met with excellence. We help you create memorable gifting experiences that reflect your brand and enhance your relationships with employees and clients.",
        "open": false
    },
    {
        "question": "What are some unique corporate gifting ideas?",
        "answer": "Unique corporate gifting ideas include personalised items, luxury gift hampers, eco-friendly products, and branded merchandise. Personalised gifts, such as engraved pens, custom-made planners, or monogrammed accessories, add a special touch. Luxury gift hampers can include gourmet treats, fine wines, and premium products. Eco-friendly gifts, such as reusable water bottles, sustainable stationery, and organic products, are increasingly popular. Giftingy, a top corporate gifting company in India, offers many unique and high-quality corporate gifts. Our creative team constantly explores new trends and ideas to bring you innovative gifting solutions. We also provide customisation options to make your gifts more meaningful and memorable—partner with Giftingy to discover unique corporate gifting ideas that leave a lasting impression.",
        "open": false
    },
    {
        "question": "Why should you brand your corporate gifts?",
        "answer": "Branding your corporate gifts enhances brand visibility and promotes brand loyalty. Branded gifts serve as a constant reminder of your company to the recipients, increasing brand recall. They also create positive associations with your brand, fostering goodwill and loyalty. Giftingy, a premier corporate gifting company in India, specialises in branded corporate gifts. Our high-quality and unique gifts are designed to reflect your brand's identity. Incorporating your company logo and branding elements ensures that your gifts leave a lasting impression. Branded corporate gifts also help spread brand awareness among the recipients. This can increase brand recognition and loyalty—partner with Giftingy to make your corporate gifting more impactful through thoughtful branding.",
        "open": false
    },
    {
        "question": "What types of corporate gifts are suitable for different occasions?",
        "answer": "Different occasions call for various types of corporate gifts. For festive seasons, consider gifts that reflect the holiday spirit, such as personalised ornaments or themed gift hampers. For employee recognition, high-quality items like engraved pens or luxury desk accessories are suitable. During client appreciation events, thoughtful gifts such as custom-made gift boxes or gourmet treats can make a lasting impression. Giftingy, a top corporate gifting company in India, offers a diverse selection of gifts for various occasions. Our team can help you choose the perfect gifts that align with the event and recipient's preferences. By selecting the right gifts for each occasion, you can create memorable experiences and strengthen your business relationships—partner with Giftingy for tailored corporate gifting solutions.",
        "open": false
    }
]
const faqDataPage1 = [
    { 
        question: 'Why Should You Engage in Corporate Gifting?', 
        answer: (
            <ul>
                <li><b>Show Appreciation:</b> Recognize the efforts and contributions of employees and clients.</li>
                <li><b>Promote Brand Loyalty:</b> Thoughtful gifts enhance brand loyalty and recall.</li>
                <li><b>Celebrate Milestones:</b> Mark significant events and achievements with special gifts.</li>
                <li><b>Enhance Corporate Culture:</b> Foster a positive and inclusive corporate culture.</li>
                <li><b>Create Lasting Impressions:</b> Memorable gifts leave a lasting impression on recipients.</li>
            </ul>
        )
    },
    { 
        question: 'When Should You Engage in Corporate Gifting?', 
        answer: (
            <ul>
                <li><b>Festive Seasons:</b> Celebrate holidays and festivals with thoughtful gifts.</li>
                <li><b>Company Anniversaries:</b> Mark significant milestones and achievements.</li>
                <li><b>Employee Recognition:</b> Recognize and reward employees' hard work and dedication.</li>
                <li><b>Client Appreciation:</b> Show clients that you value their partnership.</li>
                <li><b>Special Events:</b> Celebrate special corporate events and occasions.</li>
            </ul>
        )
    },
    { 
        question: 'How Can You Source Corporate Gifting?', 
        answer: (
            <ul>
                <li><b>Identify Your Needs:</b> Understand the purpose and recipient of the gift.</li>
                <li><b>Set a Budget:</b> Determine your budget and find gifts that fit within it.</li>
                <li><b>Choose Quality Products:</b> Select high-quality products that reflect your brand's values.</li>
                <li><b>Personalise Your Gifts:</b> Add a personal touch to make the gifts more meaningful.</li>
                <li><b>Partner with a Reliable Supplier:</b> Work with a trusted corporate gifting company like Giftingy.</li>
            </ul>
        )
    },
    { 
        question: 'How Do You Decide on Corporate Gifting?', 
        answer: (
            <ul>
                <li><b>Know Your Audience:</b> Understand the preferences and needs of the recipients.</li>
                <li><b>Set Clear Objectives:</b> Define the purpose and goals of your corporate gifting strategy.</li>
                <li><b>Choose Appropriate Gifts:</b> Select relevant and meaningful gifts for the recipients.</li>
                <li><b>Consider Branding:</b> Customise the gifts with your company logo or branding elements.</li>
                <li><b>Plan Ahead:</b> Ensure timely delivery and distribution of the gifts.</li>
            </ul>
        )
    },
    { 
        question: 'What is Corporate Gifting?', 
        answer: (
            <ul>
                <li><b>What is Corporate Gifting?</b> Corporate gifting involves giving thoughtful gifts to employees, clients, and partners to show appreciation, celebrate milestones, and strengthen relationships. It is an essential aspect of business strategy that helps promote goodwill, enhance brand visibility, and foster loyalty. Corporate gifts range from personalised items and branded merchandise to luxury hampers and bespoke gifts.</li>
            </ul>
        )
    },
    { 
        question: 'Do Companies Follow a Corporate Gifting Strategy?', 
        answer: (
            <ul>
                <li><b>Do Companies Follow a Corporate Gifting Strategy?</b> Many companies follow a corporate gifting strategy to ensure their gifts are meaningful, timely, and aligned with their business objectives. A well-planned corporate gifting strategy can enhance brand loyalty, foster relationships, and create lasting impressions. Key elements of a corporate gifting strategy include understanding the recipients, setting clear objectives, choosing appropriate gifts, and ensuring timely delivery.</li>
            </ul>
        )
    },
    { 
        question: 'Why Should You Brand Your Corporate Gifts?', 
        answer: (
            <ul>
                <li><b>Enhances Brand Recall:</b> Customised gifts with your company logo increase brand recall.</li>
                <li><b>Promotes Brand Loyalty:</b> Branded gifts foster loyalty among recipients.</li>
                <li><b>Creates a Professional Image:</b> High-quality branded gifts reflect your company's professionalism and attention to detail.</li>
                <li><b>Increases Visibility:</b> Branded gifts serve as a constant reminder of your brand.</li>
                <li><b>Strengthens Relationships:</b> Personalised gifts show that you value the recipient.</li>
            </ul>
        )
    },
    { 
        question: 'Benefits of Personalised Corporate Gifting', 
        answer: (
            <ul>
                <li><b>Creates Lasting Impressions:</b> Personalised gifts leave a lasting impression on recipients.</li>
                <li><b>Enhances Emotional Connection:</b> Customised gifts create a stronger emotional connection.</li>
                <li><b>Reflects Thoughtfulness:</b> Personalization shows that you have put thought into the gift.</li>
                <li><b>Increases Brand Loyalty:</b> Personalised gifts foster loyalty and appreciation.</li>
                <li><b>Promotes Brand Visibility:</b> Custom gifts with your company logo enhance brand visibility.</li>
            </ul>
        )
    }
];

  return (
    <>
      <HomeSlider />
      <div className="container-94vw">

      <CategoryList />
      <CategorySection />
        <HomeSection />
        </div>
        <SaleProduct />
        <div className="container-94vw">
        <Categories />
        </div>
        <ThreeColumnsSection /> {/* Add the ThreeColumnsSection component */}
        <FAQ faqs={faqDataPage1} />
        <ToggleSection />
        <OnSaleProductsSection /> {/* Add the OnSaleProductsSection component */}
      
      
         
      
      
      
      <InfoSection />
      <FourImagesSection />
     
      <FaqPage faqs={faqs} />
    </>
  );
}

export default Home;
