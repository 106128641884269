import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/tradiimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalised traditional gifts tailored to your preferences." },
    { title: "Branding on Gifts", detail: "Keeps your brand memorable and visible." },
    { title: "Value for Money", detail: "High-quality traditional gifts within your budget." },
    { title: "Concept Creation", detail: "Unique and innovative gift ideas." },
    { title: "Diverse Range of Gifts", detail: "Extensive selection for all occasions." },
    { title: "Quality Assurance", detail: "Premium quality guaranteed." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible gifting." },
];
const IndianTraditionalGifts = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
       
      }, []);
const faqs = [
    {
      "question": "What are Indian traditional gifts?",
      "answer": "Indian traditional gifts reflect the country's rich cultural heritage and craftsmanship. These gifts include handcrafted textiles, jewellery, home decor items, and religious artefacts. They are ideal for personal celebrations, festivals, and corporate gifting. Traditional gifts from India are cherished for their aesthetic and symbolic value. They serve as a unique and meaningful way to convey appreciation and respect.",
      "open": false
    },
    {
      "question": "Why are Indian traditional gifts important?",
      "answer": "Indian traditional gifts are important because they celebrate the country's diverse cultural heritage and artistic excellence. They carry deep cultural and spiritual significance, making them ideal for various occasions. These gifts enhance the cultural ambience of any celebration and are highly valued by recipients. Traditional gifts also support local artisans and promote traditional craftsmanship. They are a thoughtful way to show appreciation and respect for Indian culture.",
      "open": false
    },
    {
      "question": "What are some popular traditional Indian gift ideas?",
      "answer": "Popular traditional Indian gift ideas include handcrafted textiles, intricate jewellery, decorative home accents, and religious artefacts. Items like sarees, traditional paintings, and brass idols are highly appreciated. Handicrafts such as pottery, woodwork, and metalwork are also popular choices. These gifts reflect India's rich cultural heritage and craftsmanship, and customisation options make them even more special and memorable.",
      "open": false
    },
    {
      "question": "How do I choose the right Indian traditional gift?",
      "answer": "Choosing the right Indian traditional gift involves understanding the recipient's preferences and the item's cultural significance. Opt for high-quality items that reflect the recipient's taste and the occasion. Personalisation can add a special touch, making the gift more meaningful. Consider the craftsmanship and authenticity of the gift to ensure the recipient values it. Consulting an Indian traditional gifts company can provide additional insights and recommendations.",
      "open": false
    },
    {
      "question": "What are the benefits of working with an Indian traditional gifts company?",
      "answer": "Working with an Indian traditional gifts company ensures you receive high-quality, authentic items that reflect India's rich cultural heritage. These companies offer various customisation options to make your gifts unique and personal. They provide expertise in selecting and designing traditional gifts that suit your needs and preferences. Reliable delivery services and excellent customer support are additional benefits. Partnering with a reputable company ensures a seamless and satisfactory gifting experience.",
      "open": false
    },
    {
      "question": "Can I order traditional Indian gifts online?",
      "answer": "Yes, you can order traditional Indian gifts online from various gifting companies. Online platforms offer various conventional gift items and convenient ordering processes. You can select, customise, and order gifts from the comfort of your home or office. Reliable delivery services ensure your gifts reach the recipients on time and in perfect condition. Online ordering also allows you to compare products and prices easily.",
      "open": false
    },
    {
      "question": "What are some good gifts for Hindu friends?",
      "answer": "Good gifts for Hindu friends include religious artefacts like Ganesha idols, Lakshmi statues, and Krishna murtis. Handcrafted items such as traditional paintings, decorative diyas, and intricate jewellery are also appreciated. Consider pooja thalis, brass God idols, and personalised religious books. These gifts reflect respect for the recipient's spiritual beliefs and cultural heritage, and customisation options make them even more meaningful.",
      "open": false
    },
    {
      "question": "How do traditional Indian gifts enhance corporate events?",
      "answer": "Indian traditional gifts enhance corporate events by adding a touch of cultural richness and authenticity. They are ideal for corporate anniversaries, festivals, and cultural celebrations. These gifts reflect the company's respect for Indian culture and heritage. Customised traditional gifts make the event more memorable and meaningful. They foster a positive and respectful corporate culture.",
      "open": false
    },
    {
      "question": "What are some unique traditional Hindu gift ideas?",
      "answer": "Unique traditional Hindu gift ideas include customised deity idols, gold-plated Lakshmi statues, and bespoke Ganesha murtis. Handcrafted pooja thalis, decorative diyas, and traditional paintings are also popular. Consider items like brass God idols and personalised religious artefacts for special occasions. These gifts reflect the recipient's spiritual beliefs and cultural heritage, and customization options make them even more special and memorable.",
      "open": false
    },
    {
      "question": "Why should companies invest in traditional corporate gifts?",
      "answer": "Companies should invest in traditional corporate gifts to show respect and appreciation for their clients' and employees' cultural heritage. These gifts enhance corporate relationships and convey goodwill. Traditional gifts are highly valued in Indian culture, making them ideal for fostering positive business relationships. They also serve as a constant reminder of the company's thoughtfulness and respect. Investing in traditional corporate gifts promotes a positive company image and culture.",
      "open": false
    },
    {
      "question": "How can I ensure the quality of traditional Indian gifts?",
      "answer": "Ensuring the quality of traditional Indian gifts involves selecting reputable gifting companies and suppliers. Check reviews and testimonials to gauge the reliability and quality of their products. Opt for companies with a proven track record of high-quality craftsmanship and customisation. Regularly review samples and provide clear specifications for the customisation. Partnering with a trusted Indian traditional gifts company ensures the quality and satisfaction of your gifts.",
      "open": false
    },
    {
      "question": "What are the latest trends in Indian traditional gifting?",
      "answer": "The latest trends in traditional Indian gifting include eco-friendly handicrafts, gold-plated statues, and customisable religious artefacts. Sustainable and environmentally friendly items are increasingly popular. Personalised conventional gifts, such as custom deity idols and bespoke gift sets, are in demand. Handcrafted items that reflect traditional craftsmanship are also trending. Staying updated with these trends ensures your gifts are relevant and appreciated.",
      "open": false
    },
    {
      "question": "What are the benefits of using handicrafts as corporate gifts?",
      "answer": "Using handicrafts as corporate gifts promotes and supports local artisans and traditional craftsmanship. These gifts reflect cultural richness and authenticity, making them highly valued by recipients. Handicrafts are unique and often customised, adding a personal touch to the gifts. They enhance the company's image as culturally aware and supportive of local arts. Handicraft gifting also fosters positive business relationships and goodwill.",
      "open": false
    },
    {
      "question": "How do traditional Indian gifts benefit employee recognition programs?",
      "answer": "Traditional Indian gifts benefit employee recognition programs by adding a cultural touch to the recognition process. They make employees feel valued and appreciated, boosting morale and motivation. Customised traditional gifts reflect the company's commitment to recognising individual achievements. They enhance job satisfaction and loyalty, promoting a positive work environment. Traditional gifts are more impactful and memorable than generic awards.",
      "open": false
    },
    {
      "question": "Can Indian traditional gifts be ordered wholesale?",
      "answer": "Yes, traditional Indian gifts can be ordered wholesale from various gifting companies. Wholesale ordering is ideal for large events, corporate programs, and cultural celebrations. It ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for wholesale orders. Wholesale ordering also allows for efficient planning and timely delivery.",
      "open": false
    },
    {
      "question": "What are some cost-effective traditional Indian gift options?",
      "answer": "Cost-effective traditional Indian gift options include handcrafted textiles, small deity statues, and decorative diyas. These practical items can be easily customised with names or special messages. Other affordable options include traditional paintings, personalised religious books, and handcrafted jewellery. The key is to choose meaningful and appreciated items by the recipients. Cost-effective gifts can still make a significant impact when chosen thoughtfully.",
      "open": false
    },
    {
      "question": "Why is personalisation important in traditional Indian gifting?",
      "answer": "Personalisation is important in traditional Indian gifting because it adds a unique and meaningful touch to the gifts. It shows that the giver has put thought into selecting the gift, making it more memorable and appreciated. Personalised gifts reflect the recipient's cultural beliefs and preferences. They enhance the overall impact of the gift, fostering stronger relationships. Personalisation also helps in promoting brand recall and loyalty.",
      "open": false
    },
    {
      "question": "How can traditional Indian gifts be used for client retention?",
      "answer": "Traditional Indian gifts can be used to retain clients by showing appreciation and strengthening relationships. Customised gifts reflect thoughtfulness and attention to detail, making clients feel valued. They create a positive association with the brand, fostering loyalty. Regular gifting of traditional items can enhance client satisfaction and retention rates. Traditional gifts are a constant reminder of the company's appreciation and respect.",
      "open": false
    },
    {
      "question": "What are some good traditional gifts for foreigners?",
      "answer": "Traditional gifts for foreigners include handcrafted textiles, intricate jewellery, and decorative home accents. Religious artefacts like Ganesha idols, Lakshmi statues, and traditional paintings are also popular. Consider items like brass God idols and personalised pooja thalis for a unique touch. These gifts offer a glimpse into India's rich cultural heritage and artistic excellence. Customisation options make them even more special and memorable.",
      "open": false
    },
    {
      "question": "How do traditional gifts from India enhance personal celebrations?",
      "answer": "Traditional gifts from India enhance personal celebrations by adding a touch of cultural richness and authenticity. They are ideal for weddings, religious ceremonies, and festivals. These gifts reflect the giver's respect for the recipient's cultural heritage. Customised traditional gifts make the celebration more memorable and meaningful. They also serve as cherished keepsakes, reminding the recipients of the special occasion and the giver's thoughtfulness.",
      "open": false
    }
  ]
  

return (
<>
<MetaTags
        title="Premier Indian Traditional Gifts Company"
        description="Discover exquisite Indian traditional gifts and luxury traditional gifting ideas from the leading Indian traditional gifts company. Perfect for personal and corporate gifting."
        keywords="Traditional Indian gift ideas, Indian traditional gifts online, Indian traditional gifts company, Traditional gifting ideas, Traditional Hindu gifts, Indian traditional Hindu gifts, Traditional gifts from India, What is a good gift for a Hindu, Indian traditional gifts online, Indian traditional gifts for foreigners, Luxury traditional Indian gifts company, Luxury traditional gifts company, Traditional corporate gifts, Handicraft gifting company, Handicrafts as corporate gifting"
      />
<ServiceHeader title="Indian Traditional Gifts" imageUrl={`${process.env.PUBLIC_URL}/images/tradiimgbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Essence of Indian Traditional Gifts </h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Indian traditional gifts are a beautiful reflection of the country's rich cultural heritage and craftsmanship. Deeply rooted in tradition, these gifts are perfect for personal celebrations, festivals, and corporate gifting. They carry the essence of India's diverse cultural tapestry and are cherished for their aesthetic and symbolic value. Whether you are looking for traditional Hindu gifts or unique handicrafts, traditional Indian gifts make a lasting impression.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Traditional gifting ideas often include handcrafted textiles, intricate jewellery, decorative home accents, and religious artefacts. These gifts are not only aesthetically pleasing but also hold cultural significance. They are ideal for various occasions, from weddings and religious ceremonies to corporate and festive celebrations. Indian traditional gifts for foreigners are particularly popular as they offer a glimpse into India's rich traditions and artistry.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Choosing the right traditional Indian gift involves understanding the recipient's preferences and the item's cultural significance. Here are some key aspects that make Indian traditional gifts a popular choice:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Symbolises faith and spirituality</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Ideal for various personal and corporate occasions</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Offers a wide range of items, from handicrafts to religious artefacts</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Suitable for both local and international recipients</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhances the cultural ambience of any celebration</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Available in luxury options for special occasions</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Customisable options for personalised gifting</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes and supports local artisans and craftsmanship</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Can be conveniently ordered online</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Serves as a unique and memorable gift</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>Indian traditional gifts are more than presents; they celebrate India's vibrant culture and artistic excellence.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Indian Traditional Gifts?</h2>
      <p >Selecting the perfect Indian traditional gifts requires an appreciation for cultural nuances and a commitment to quality. Giftingy is a premier Indian traditional gifts company offering a curated selection of traditional gifting ideas. Here's why Giftingy should be your top choice for Indian traditional gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/tradiimg2.jpg`} alt="Premium Traditional Hindu Gifts" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we understand the cultural significance and aesthetic appeal of traditional Indian gifts. Our range of conventional Indian gift ideas includes beautifully crafted items that are perfect for personal celebrations, corporate events, and gifts for foreigners. Whether you are looking for conventional Hindu or luxury traditional gifts, our collection caters to diverse tastes and preferences.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               One of our key strengths is our ability to offer high-quality and customisable traditional gifts. We pride ourselves on being a luxury traditional Indian gifts company that delivers excellence in craftsmanship. Our products are sourced from the best artisans, ensuring that every item meets the highest standards of quality and authenticity.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy also specialises in corporate gifting with traditional handicrafts. Our seamless and hassle-free process ensures that your gifts are delivered on time and in perfect condition. From traditional corporate gifts to unique handicrafts, we offer various options to cater to your needs. Our commitment to customer satisfaction and our ability to create lasting impressions make us the preferred choice for Indian traditional gifts.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisable traditional gifts to suit individual preferences
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality products with meticulous craftsmanship
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide range of conventional gifting ideas for various occasions
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise as an Indian conventional gifts company
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Convenient online ordering and customisation options
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable and timely delivery services
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Commitment to customer satisfaction
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive selection of beautifully crafted traditional items
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your Indian traditional gifting needs ensures that your gifts are beautiful, meaningful, and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Source for Exceptional Indian Traditional Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on offering exceptional Indian traditional gifting solutions that cater to your specific needs and preferences. Our unique selling propositions (USPs) set us apart as a leading Indian traditional gifts company. Here's what makes us unique:
            </p>
            <p className='wow fadeInUp section-partpara section-partlheight'>Giftingy offers custom designs that add a personal touch to your traditional gifts, making them more meaningful and memorable. Our branding on gifts ensures that your company remains at the forefront of the recipient's mind, enhancing brand recall and visibility. We provide value for money by offering high-quality gifts that fit within your budget, ensuring you get the best return on your investment.</p>
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/tradiimg3.jpg`} alt="Traditional Indian Gifting Solutions" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="luxuryHomeDecor" />

<FaqPage faqs={faqs} />
</>
);
};
export default IndianTraditionalGifts;