import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/divinityimg2.jpg)`,
  backgroundSize: 'cover', // Change to 'cover' to fill the container
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  padding: '30px',
};

const features = [
    { title: "Custom Designs", detail: "Personalised divinity gifts tailored to your preferences." },
    { title: "Branding on Gifts", detail: "Keeps your brand memorable and visible." },
    { title: "Value for Money", detail: "High-quality divinity gifts within your budget." },
    { title: "Concept Creation", detail: "Unique and innovative gift ideas." },
    { title: "Diverse Range of Gifts", detail: "Extensive selection for all occasions." },
    { title: "Quality Assurance", detail: "Premium quality guaranteed." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible gifting." },
];
const DivinityGifts = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
      }, []);
const faqs = [
    {
      "question": "What are divinity gifts?",
      "answer": "Divinity gifts hold spiritual and religious significance, often featuring idols and statues of deities. They are ideal for personal celebrations, religious ceremonies, and corporate gifting. These gifts are cherished for their cultural and spiritual value, serving as symbols of faith and goodwill. Common divinity gift items include murtis (statues), brass God idols, and gold-plated statues. They help convey blessings and positive energy."
    },
    {
      "question": "Why are divinity gifts important?",
      "answer": "Divinity gifts symbolize faith, spirituality, and positive energy, making them a meaningful way to show respect and appreciation for the recipient's beliefs. They enhance the spiritual ambience of any space, making them perfect for personal and corporate gifting. Divinity gifts also serve as a constant reminder of the giver's thoughtfulness and respect and are highly valued in Indian culture for their religious and spiritual significance."
    },
    {
      "question": "What are some popular divinity gift items?",
      "answer": "Popular divinity gift items include brass God idols, gold-plated statues, and murtis of various deities. Corporate gift sculptures and God idols are also highly appreciated. Hindu religious gift ideas often feature Ganesha idols, Lakshmi statues, and Krishna murtis. These gifts can be customized and are available in bulk for special occasions and corporate events. They are ideal for conveying blessings and fostering goodwill."
    },
    {
      "question": "How do I choose the right divinity gift?",
      "answer": "Choosing the right divinity gift involves understanding the recipient's spiritual inclinations and the occasion. Opt for high-quality items that reflect the recipient's beliefs and preferences. Customization can add a personal touch, making the gift more meaningful. Consider the idol or statue's size, material, and design to ensure it complements the recipient's decor. Consulting a divinity gifting company can provide additional insights and recommendations."
    },
    {
      "question": "What are the benefits of working with a divinity gifting company?",
      "answer": "Working with a divinity gifting company ensures you receive high-quality, well-crafted items that reflect your spiritual and cultural values. These companies offer various customization options to make your gifts unique and personal. They provide expertise in selecting and designing divinity gifts that suit your needs and preferences. Reliable delivery services and excellent customer support are additional benefits. Partnering with a reputable company ensures a seamless and satisfactory gifting experience."
    },
    {
      "question": "Can I order divinity gifts online in India?",
      "answer": "Yes, you can order divinity gifts online in India from various gifting companies. Online platforms offer a wide variety of divinity gift items and convenient ordering processes. You can select, customize, and order gifts from the comfort of your home or office. Reliable delivery services ensure your gifts reach the recipients on time and in perfect condition. Online ordering also allows you to compare products and prices easily."
    },
    {
      "question": "What are the benefits of customized divinity gifts?",
      "answer": "Customized divinity gifts add a personal touch that enhances the gift's spiritual and cultural significance. They show that extra thought and effort have been put into selecting the gift. Personalization can include adding names, dates, or special messages to the idols or statues. Customized gifts are more meaningful and appreciated by the recipients and help in fostering stronger personal and corporate relationships."
    },
    {
      "question": "How do divinity gifts enhance personal celebrations?",
      "answer": "Divinity gifts enhance personal celebrations by adding a touch of spirituality and goodwill. They are ideal for housewarming, weddings, and religious ceremonies. These gifts reflect the giver's respect for the recipient's beliefs and enhance the spiritual ambience of the event. Customized divinity gifts make the celebration more memorable and meaningful. They are cherished as symbols of blessings and positive energy."
    },
    {
      "question": "What are some unique Hindu religious gift ideas?",
      "answer": "Unique Hindu religious gift ideas include customized Ganesha idols, gold-plated Lakshmi statues, and bespoke Krishna murtis. Corporate gift God sculptures and personalized deity statues are also popular. Consider items like brass God idols and decorative pooja thalis for special occasions. These gifts are meaningful and reflect the recipient's spiritual beliefs. Customization options make them even more special and memorable."
    },
    {
      "question": "Why should companies invest in divinity gifts for corporate gifting?",
      "answer": "Companies should invest in divinity gifts for corporate gifting to show respect and appreciation for their clients' and employees' beliefs. These gifts enhance corporate relationships and convey positive energy and blessings. Divinity gifts are highly valued in Indian culture, making them ideal for fostering goodwill. They also serve as a constant reminder of the company's thoughtfulness and respect. Investing in divinity gifts promotes a positive company image and culture."
    },
    {
      "question": "How can I ensure the quality of divinity gifts?",
      "answer": "Ensuring the quality of divinity gifts involves selecting reputable gifting companies and suppliers. Check reviews and testimonials to gauge the reliability and quality of their products. Opt for companies with a proven track record of high-quality craftsmanship and customization. Regularly review samples and provide clear specifications for the customization. Partnering with a trusted divinity gifting company ensures the quality and satisfaction of your gifts."
    },
    {
      "question": "What are the latest trends in divinity gifting?",
      "answer": "The latest trends in divinity gifting include eco-friendly idols, gold-plated statues, and customizable deity sculptures. Sustainable and environmentally friendly items are increasingly popular. Personalized deity idols, such as custom Ganesha or Lakshmi statues, are in demand. Bespoke gift sets tailored to individual preferences are also trending. Staying updated with these trends ensures your gifts are relevant and appreciated."
    },
    {
      "question": "What are the benefits of bulk ordering god idols for gifts?",
      "answer": "Bulk ordering god idols for gifts is cost-effective and ensures consistency in quality and design. It is ideal for large events, corporate programs, and religious ceremonies. Bulk orders often come with discounts and special packages. They ensure timely delivery and efficient planning for your gifting needs. Partnering with a reputable divinity gifting company can streamline the bulk ordering process."
    },
    {
      "question": "How do divinity gifts enhance corporate events?",
      "answer": "Divinity gifts enhance corporate events by adding a touch of spirituality and goodwill. They are ideal for corporate anniversaries, festivals, and religious celebrations. These gifts reflect the company's respect for the spiritual beliefs of its employees and clients. Customized divinity gifts make the event more memorable and meaningful. They foster a positive and respectful corporate culture."
    },
    {
      "question": "What are some popular corporate divinity gift items?",
      "answer": "Popular corporate divinity gift items include brass God idols, gold-plated deity statues, and customized murti (statues). Corporate gift sculptures and personalized deity idols are also highly appreciated. Consider items like decorative pooja thalis and bespoke gift sets for special occasions. These gifts reflect the company's respect for spiritual beliefs and enhance corporate relationships. Customization options make them even more special and memorable."
    },
    {
      "question": "What should I consider when choosing a divinity gifting company?",
      "answer": "When choosing a divinity gifting company, consider its reputation, product range, and customization options. Look for companies with a proven track record of high-quality products and excellent customer service. Check reviews and testimonials to gauge its reliability. Ensure it offers a wide range of divinity gift items that suit your needs. Partnering with a reputable company ensures a seamless and satisfactory gifting experience."
    },
    {
      "question": "How do divinity gifts benefit employee recognition programs?",
      "answer": "Divinity gifts benefit employee recognition programs by adding a personal touch to the recognition process. They make employees feel valued and appreciated, boosting morale and motivation. Customized deity idols or spiritual items reflect the company's commitment to recognizing individual achievements. They enhance job satisfaction and loyalty, promoting a positive work environment. Divinity gifts are more impactful and memorable than generic awards."
    },
    {
      "question": "Can divinity gifts be ordered wholesale in India?",
      "answer": "Yes, divinity gifts can be ordered wholesale in India from various gifting companies. Wholesale ordering is ideal for large events, corporate programs, and religious ceremonies. It ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for wholesale orders. Wholesale ordering also allows for efficient planning and timely delivery."
    },
    {
      "question": "What are some cost-effective divinity gift options?",
      "answer": "Cost-effective divinity gift options include brass God idols, small deity statues, and customized pooja thalis. These practical items can be easily customized with names or special messages. Other affordable options include decorative diyas, incense holders, and personalized religious books. The key is to choose items that are meaningful and appreciated by the recipients. Cost-effective gifts can still make a significant impact when chosen thoughtfully."
    },
    {
      "question": "Why is personalization important in divinity gifting?",
      "answer": "Personalization is important in divinity gifting because it adds a unique and meaningful touch to the gifts. It shows that the giver has put thought into selecting the gift, making it more memorable and appreciated. Personalized gifts reflect the recipient's spiritual beliefs and preferences. They enhance the overall impact of the gift, fostering stronger relationships. Personalization also helps in promoting brand recall and loyalty."
    }
  ]
  

return (
<>
<MetaTags
        title="Premier Divinity Gifting Company in India"
        description="Discover exquisite divinity gifts, including god idols and statues, from India's leading divinity gifting company. Perfect for personal and corporate gifting."
        keywords="Divinity gifting, Divinity gift items, Divinity gift company, Divinity gifting company, Hindu religious gift ideas, Hindu religious gifts online, Hindu gift ideas, Gift for an Indian friend, Gift for Hindu friends, Divinity idols and statue gifting, Corporate gift murti, Gift murti, Corporate gift sculpture, The corporate gift God sculpture, Corporate gift god statue, God idols for gift, God idols for gift bulk order, God idols for gift bulk buy, God statue wholesale in India, Brass God Idols India, God statue wholesale in India, Divinity God idols company, Divine gifts company, Divine gifting ideas, Divine gifting idols, Gold-plated idol manufacturers"
      />
<ServiceHeader title="Divinity Gifts" imageUrl={`${process.env.PUBLIC_URL}/images/divinitybn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Significance of Divinity Gifts</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Divinity gifts are special in Indian culture, symbolising faith, spirituality, and goodwill. These gifts, often comprising idols and statues of deities, are ideal for personal celebrations, religious ceremonies, and corporate gifting. They carry deep meaning and are cherished for their cultural and spiritual significance. Whether it's a gift for an Indian friend or a corporate gift, divinity gifts are perfect for conveying blessings and positive energy.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Divinity gifting has evolved, with many companies offering a variety of items that cater to different tastes and preferences. The options are diverse and exquisite, from brass God idols to gold-plated statues. Hindu religious gift ideas such as murti (statue) and corporate gift sculptures are highly appreciated. These gifts enhance the spiritual ambience and reflect the giver's thoughtfulness and respect for the recipient's beliefs.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Choosing the right divinity gift involves understanding the recipient's spiritual inclinations and the occasion. Here are some key aspects that make divinity gifts a popular choice:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Symbolises faith and spirituality</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Ideal for various religious ceremonies and celebrations</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhances the spiritual ambience of any space</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Suitable for both personal and corporate gifting</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Offers a wide range of idols and statues</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Reflects the giver's respect and thoughtfulness</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Customisable options available for special occasions</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Available in bulk for large orders</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Crafted with high-quality materials</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Can be conveniently ordered online</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>Divinity gifts are more than presents; they reflect deep-rooted traditions and values.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Divinity Gifts?</h2>
      <p >Selecting the perfect divinity gifts requires a keen eye for detail and a commitment to quality. Giftingy stands out as a premier divinity gifting company, offering a curated selection of divine gift items. Here's why Giftingy should be your top choice for divinity gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/divinityimg1.jpg`} alt="Hindu Religious Gifts Online" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we understand the significance of divinity gifts in conveying blessings and positive energy. Our divinity gift items include beautifully crafted idols and statues of deities, ideal for personal and corporate gifting. Whether you are looking for Hindu religious gifts online or a special gift for a Hindu friend, our collection has something for everyone.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               One of our key strengths is our ability to offer high-quality and customisable divine gifts. We pride ourselves on being a divine gifts company that delivers quality and craftsmanship. Our products are sourced from the best manufacturers, ensuring that every item meets the highest standards of artistry.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy also specialises in bulk orders for corporate gifting. Our seamless and hassle-free process ensures that your gifts are delivered on time and in perfect condition. From gold-plated to brass God idols, we offer a wide range of options to cater to your needs. Our commitment to customer satisfaction and ability to create lasting impressions make us the preferred choice for divinity gifts.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisable divinity gifts to suit individual preferences
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality materials and craftsmanship
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide range of divine gifting ideas for various occasions
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise as a divinity gifting company
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Convenient online ordering and customisation options
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable and timely delivery services
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Commitment to customer satisfaction
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive selection of beautifully crafted idols and statues
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your divinity gifting needs ensures that your gifts are beautiful, meaningful, and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Source for Exquisite Divinity Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on offering exceptional divinity gifting solutions that cater to your specific needs and preferences. Our unique selling propositions (USPs) set us apart as a leading divinity gift company. Here's what makes us unique:
            </p>
            <p className='wow fadeInUp section-partpara section-partlheight'>Giftingy offers custom designs that add a personal touch to your divinity gifts, making them more meaningful and memorable. Our branding on gifts ensures that your company remains at the forefront of the recipient's mind, enhancing brand recall and visibility. We provide value for money by offering high-quality gifts that fit within your budget, ensuring you get the best return on your investment.</p>
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/divinityimg3.jpg`} alt="Premium God Idols for Gift" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="luxuryHomeDecor" />

<FaqPage faqs={faqs} />
</>
);
};
export default DivinityGifts;