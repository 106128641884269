import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 
import './Testimonials.css'; 

const testimonialsData = [
    {
        serviceId: "corporateGifting",
        testimonials: [
            {
                id: "RajeshSharma",
                quote: "Amazing service and quality products. Giftingy made our corporate event a huge success with their unique gift ideas.",
                name: "Rajesh Sharma",
                location: "Delhi"
            },
            {
                id: "PriyaMehta",
                quote: "Humare office ka favourite hai Giftingy! Sabko bahut pasand aaya unke gifts. Highly recommended!",
                name: "Priya Mehta",
                location: "Gurgaon"
            },
            {
                id: "AnjaliVerma",
                quote: "Giftingy provides the best corporate gifting solutions. The customised gifts for our employees were a big hit. We will definitely order again!",
                name: "Anjali Verma",
                location: "Noida"
            }
        ]
    },
    {
        serviceId: "luxuryHomeDecor",
        testimonials: [
            {
                id: "AnkitSharma",
                quote: "The luxury home decor gifts from Giftingy added an elegant touch to our corporate event. Highly recommended!",
                name: "Ankit Sharma",
                location: "Mumbai"
            },
            {
                id: "PriyaMalhotra",
                quote: "Mere personal celebrations ke liye Giftingy se gifts liye the. Sabko bohot pasand aaye. Great quality and service!",
                name: "Priya Malhotra",
                location: "Gurgaon"
            },
            {
                id: "NehaVerma",
                quote: "We ordered bulk luxury home decor gifts for our wedding return gifts. The guests loved them! Excellent craftsmanship and timely delivery.",
                name: "Neha Verma",
                location: "Jaipur"
            }
        ]
    },
    {
        serviceId: "incentiveGifts",
        testimonials: [
            {
                id: "RahulKapoor",
                quote: "Our sales team was thrilled with the incentive gifts from Giftingy. Excellent quality and service!",
                name: "Rahul Kapoor",
                location: "Delhi"
            },
            {
                id: "PriyaSingh",
                quote: "Humne apne customers ko Giftingy ke complimentary gifts diye. Sabko bahut pasand aaye! Best gifting company.",
                name: "Priya Singh",
                location: "Gurgaon"
            },
            {
                id: "AnjaliVerma",
                quote: "Giftingy provided unique customer appreciation gifts for our annual event. The feedback was overwhelmingly positive. Highly recommended!",
                name: "Anjali Verma",
                location: "Noida"
            }
        ]
    },
    {
        serviceId: "PersonalisedCorporateGifts",
        testimonials: [
            {
                id: "RameshMehta",
                quote: "Giftingy's personalised corporate gifts were a hit with our clients. The attention to detail was impressive!",
                name: "Ramesh Mehta",
                location: "Mumbai"
            },
            {
                id: "PriyaVerma",
                quote: "Mujhe Giftingy ke customised corporate gifts bohot pasand aaye. Bahut hi unique aur high-quality hai. Highly recommended!",
                name: "Priya Singh",
                location: "Delhi"
            },
            {
                id: "AnjaliKapoor",
                quote: "We ordered personalised corporate gifts online from Giftingy for our employees. The feedback was overwhelmingly positive. Excellent service!",
                name: "Anjali Kapoor",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "DivinityGifting",
        testimonials: [
            {
                id: "AnilSharma",
                quote: "The divinity gifts from Giftingy were perfect for our corporate event. Everyone appreciated the quality and craftsmanship!",
                name: "Anil Sharma",
                location: "Mumbai"
            },
            {
                id: "PriyaSingh",
                quote: "Mujhe Giftingy ke god idols bohot pasand aaye. Mere friends bhi impressed hue! Best divine gifts company.",
                name: "Priya Singh",
                location: "Delhi"
            },
            {
                id: "AnjaliVerma",
                quote: "We ordered bulk god idols for our office inauguration from Giftingy. The delivery was timely and the idols were beautifully crafted. Highly recommended!",
                name: "Anjali Verma",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "IndianTraditionalGifting",
        testimonials: [
            {
                id: "AnilSharma",
                quote: "The traditional Indian gifts from Giftingy were perfect for our cultural event. Everyone appreciated the quality and craftsmanship!",
                name: "Anil Sharma",
                location: "Mumbai"
            },
            {
                id: "PriyaSingh",
                quote: "Mujhe Giftingy ke handicraft gifts bohot pasand aaye. Mere friends bhi impressed hue! Best traditional gifts company.",
                name: "Priya Singh",
                location: "Delhi"
            },
            {
                id: "AnjaliVerma",
                quote: "We ordered traditional corporate gifts for our office from Giftingy. The delivery was timely and the items were beautifully crafted. Highly recommended!",
                name: "Anjali Verma",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "PongalGifting",
        testimonials: [
            {
                id: "VenkateshIyer",
                quote: "The Pongal corporate gifts from Giftingy were perfect for our employees. Everyone appreciated the quality and cultural significance!",
                name: "Venkatesh Iyer",
                location: "Chennai"
            },
            {
                id: "RajeshKumar",
                quote: "Mujhe Giftingy ke branded Pongal gifts bohot pasand aaye. Mere colleagues bhi impressed hue! Best gifting company for festive gifts.",
                name: "Rajesh Kumar",
                location: "Hyderabad"
            },
            {
                id: "AnithaReddy",
                quote: "We ordered luxury Pongal hampers for our clients from Giftingy. The delivery was timely and the items were beautifully crafted. Highly recommended!",
                name: "Anitha Reddy",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "KartikayGifting",
        testimonials: [
            {
                id: "SureshKumar",
                quote: "The Kartikay Dibam corporate gifts from Giftingy were perfect for our employees. Everyone appreciated the quality and cultural significance!",
                name: "Suresh Kumar",
                location: "Chennai"
            },
            {
                id: "AnilVerma",
                quote: "Mujhe Giftingy ke branded Kartikay Dibam gifts bohot pasand aaye. Mere colleagues bhi impressed hue! Best gifting company for festive gifts.",
                name: "Anil Verma",
                location: "Hyderabad"
            },
            {
                id: "DeepakSharma",
                quote: "We ordered luxury Kartikay Dibam hampers for our clients from Giftingy. The delivery was timely and the items were beautifully crafted. Highly recommended!",
                name: "Deepak Sharma",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "KartikayGifting",
        testimonials: [
            {
                id: "ArjunPatel",
                quote: "The corporate milestone gifts from Giftingy were perfect for our employee anniversaries. Everyone appreciated the quality and thoughtfulness!",
                name: "Arjun Patel",
                location: "Mumbai"
            },
            {
                id: "AnilVerma",
                quote: "Mujhe Giftingy ke celebration gifts bohot pasand aaye. Mere colleagues bhi impressed hue! Best gifting company for special occasions.",
                name: "Rakesh Kumar",
                location: "Delhi"
            },
            {
                id: "DeepakSharma",
                quote: "We ordered luxury gifts for our company anniversary from Giftingy. The delivery was timely and the items were beautifully crafted. Highly recommended!",
                name: "Sunita Rao",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "KartikayGifting",
        testimonials: [
            {
                id: "SandeepMehra",
                quote: "The golf corporate gifts from Giftingy were perfect for our clients. Everyone appreciated the quality and elegance!",
                name: "Sandeep Mehra",
                location: "Gurgaon"
            },
            {
                id: "RaviKumar",
                quote: "Mujhe Giftingy ke luxury golf gifts bohot pasand aaye. Mere colleagues bhi impressed hue! Best gifting company for golf accessories.",
                name: "Ravi Kumar",
                location: "Noida"
            },
            {
                id: "AnjaliGupta",
                quote: "We ordered branded golf gifts for our company management from Giftingy. The delivery was timely and the items were beautifully crafted. Highly recommended!",
                name: "Anjali Gupta",
                location: "Delhi"
            }
        ]
    },
    {
        serviceId: "LeatherGifting",
        testimonials: [
            {
                id: "RahulGupta",
                quote: "The leather accessories we ordered from Giftingy were of outstanding quality. Our clients loved them!",
                name: "Rahul Gupta",
                location: "Delhi"
            },
            {
                id: "ShwetaKumar",
                quote: "Mujhe Giftingy ke leather bags bohot pasand aaye. Classy and durable!",
                name: "Shweta Kumar",
                location: "Mumbai"
            },
            {
                id: "SameerRathi",
                quote: "Giftingy provided excellent service, and the leather gifts were perfect for our employees. I highly recommend it!",
                name: "Sameer Rathi",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "HealthAndWellnessGifts",
        testimonials: [
            {
                id: "RajeshKumar",
                quote: "Our employees loved the wellness hampers from Giftingy. They felt appreciated and motivated to maintain healthy habits.",
                name: "Rajesh Kumar",
                location: "Hyderabad"
            },
            {
                id: "SunitaPatel",
                quote: "Maine Giftingy ke wellness gifts apne clients ke liye order kiye. Sab ne bohot pasand kiya, especially mental health gifts.",
                name: "Sunita Patel",
                location: "Mumbai"
            },
            {
                id: "AnkitMehta",
                quote: "Giftingy's selection of luxury wellness gifts for our corporate event was excellent. The products were high-quality and beautifully packaged.",
                name: "Ankit Mehta",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "OnboardingEmployeeKits",
        testimonials: [
            {
                id: "NehaGupta",
                quote: "Giftingy's onboarding kits made our new hires feel truly welcome. The quality and personalisation were spot on!",
                name: "Neha Gupta",
                location: "Bangalore"
            },
            {
                id: "RajeshMehta",
                quote: "Humare naye employees ko Giftingy ke personalised welcome kits bohot pasand aaye. Great way to make them feel part of the team!",
                name: "Rajesh Mehta",
                location: "Delhi"
            },
            {
                id: "AnjaliVerma",
                quote: "The new joint kits from Giftingy were a perfect blend of practicality and thoughtfulness. Highly recommend them for corporate gifting needs!",
                name: "Anjali Verma",
                location: "Mumbai"
            }
        ]
    },
    {
        serviceId: "TravelGiftsforBusinesses",
        testimonials: [
            {
                id: "RohitGupta",
                quote: "The travel accessories from Giftingy were a huge hit among our employees. The quality and personalisation were top-notch!",
                name: "Rohit Gupta",
                location: "Pune"
            },
            {
                id: "MeeraSharma",
                quote: "Mujhe Giftingy ke travel gifts bohot pasand aaye. Mere clients bhi impressed hue! Highly recommend their corporate travel gifting solutions.",
                name: "Meera Sharma",
                location: "Delhi"
            },
            {
                id: "AnilVerma",
                quote: "We ordered luxury travel kits for our executive clients, and Giftingy exceeded our expectations with their premium quality and attention to detail.",
                name: "Anil Verma",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "BrandedApparelGifting",
        testimonials: [
            {
                id: "RohitMalhotra",
                quote: "The branded t-shirts we ordered from Giftingy were a hit at our event. Great quality and timely delivery!",
                name: "Rohit Malhotra",
                location: "Mumbai"
            },
            {
                id: "SnehaVerma",
                quote: "Humare employees ko Giftingy ke branded t-shirts bohot pasand aaye. Stylish and comfortable!",
                name: "Sneha Verma",
                location: "Delhi"
            },
            {
                id: "AnilKumar",
                quote: "We’ve been using Giftingy for all our branded apparel needs. The quality and service are always top-notch!",
                name: "Anil Kumar",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "PlantersCorporateGifting",
        testimonials: [
            {
                id: "RakeshMehta",
                quote: "We chose Giftingy for our corporate event, and the planters were a huge hit. The quality was top-notch, and they arrived right on time.",
                name: "Rakesh Mehta",
                location: "Mumbai"
            },
            {
                id: "SnehaVerma",
                quote: "Giftingy's indoor plants are the perfect gift for our clients. They are elegant, easy to care for, and make a lasting impact.",
                name: "Sunita Agarwal",
                location: "Delhi"
            },
            {
                id: "PriyaSharma",
                quote: "Mujhe Giftingy ke planters corporate gifts bohot pasand aaye. Har koi impressed tha! Definitely recommend!",
                name: "Priya Sharma",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "Dussehracorporategifts",
        testimonials: [
            {
                id: "RajeshGupta",
                quote: "The Dussehra corporate gifts from Giftingy were a hit with our clients. The quality and presentation were outstanding!",
                name: "Rajesh Gupta",
                location: "New Delhi"
            },
            {
                id: "NehaAgarwal",
                quote: "We ordered Dussehra gift hampers for our employees, and everyone loved them. The service was top-notch!",
                name: "Neha Agarwal",
                location: "Mumbai"
            },
            {
                id: "PriyankaSharma",
                quote: "Giftingy ke Dussehra gifts ne humare clients ko bahut khush kar diya. Excellent quality and timely delivery!",
                name: "Priyanka Sharma",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "EasterCorporateGifts",
        testimonials: [
            {
                id: "RakeshGupta",
                quote: "The Easter corporate gifts from Giftingy were a hit among our clients. The quality and presentation were exceptional!",
                name: "Rakesh Gupta",
                location: "Mumbai"
            },
            {
                id: "AnanyaSingh",
                quote: "Mujhe Giftingy ke handmade Easter gifts bohot pasand aaye. Employees bhi impressed hue! Best gifting company for Easter.",
                name: "Ananya Singh",
                location: "Delhi"
            },
            {
                id: "PriyaMehta",
                quote: "We ordered Easter hampers for our employees from Giftingy. The delivery was timely, and the items were beautifully crafted. Highly recommended!",
                name: "Priya Mehta",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "EasterCorporateGifts",
        testimonials: [
            {
                id: "RohanMehta",
                quote: "Our clients were thrilled with the personalised Makar Sankranti hampers from Giftingy. The quality and attention to detail were outstanding!",
                name: "Rohan Mehta",
                location: "Delhi"
            },
            {
                id: " PriyaKapoor",
                quote: "Giftingy made our Makar Sankranti corporate gifting experience smooth and hassle-free. The branded gifts were a hit with our employees.",
                name: " Priya Kapoor",
                location: "Mumbai"
            },
            {
                id: "ArjunSingh",
                quote: "Mere clients Giftingy ke makar sankranti gifts se bahut khush hue. Personalized touch ne gifts ko aur bhi khaas banaya!",
                name: "Arjun Singh",
                location: "Jaipur"
            }
        ]
    },
    {
        serviceId: "KitchenandDiningCorporateGifting",
        testimonials: [
            {
                id: "RameshVerma",
                quote: "The kitchen and dining corporate gifts from Giftingy were a hit with our clients. They loved the quality and thoughtfulness of the items!",
                name: "Ramesh Verma",
                location: "Mumbai"
            },
            {
                id: "NehaSharma",
                quote: "Mujhe Giftingy ke branded kitchen gifts bohot pasand aaye. Mere colleagues bhi impressed hue! Best gifting company for Kitchen and dining accessories.",
                name: "Neha Sharma",
                location: "Delhi"
            },
            {
                id: "ArjunPatel",
                quote: "We ordered luxury dining sets for our employees from Giftingy. The delivery was timely, and the items were beautifully crafted. Highly recommended!",
                name: "Arjun Patel",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "KitchenandDiningCorporateGifting",
        testimonials: [
            {
                id: "RohitMalhotra",
                quote: "The beauty and fashion gifts from Giftingy were a hit with our clients. The luxury skincare sets were particularly appreciated. Excellent service!",
                name: "Rohit Malhotra",
                location: "Bangalore"
            },
            {
                id: "PriyaKapoor",
                quote: "Mujhe Giftingy ke fashion gifts bohot pasand aaye. High-quality and stylish gifts that left a lasting impression on our employees.",
                name: "Priya Kapoor",
                location: "Delhi"
            },
            {
                id: "SanjayVerma",
                quote: "We ordered branded fashion accessories from Giftingy for our corporate event. The gifts were stylish, well-received, and delivered on time.",
                name: "Sanjay Verma",
                location: "Mumbai"
            }
        ]
    },
    {
        serviceId: "CorporateJewelryGifts",
        testimonials: [
            {
                id: "RajeshKumar",
                quote: "Giftingy's silver corporate gifts were a big hit at our annual event. The quality was outstanding!",
                name: "Rajesh Kumar",
                location: "Bangalore"
            },
            {
                id: "NehaSingh",
                quote: "I ordered luxury gold jewelry gifts for our clients, and they were all very impressed. Giftingy made the process so easy!",
                name: "Neha Singh",
                location: "Delhi"
            },
            {
                id: "PriyaSharma",
                quote: "Humein Giftingy ke jewellery gift boxes bohot pasand aaye. Yeh gifts humare employees ke liye perfect the!",
                name: "Priya Sharma",
                location: "Delhi"
            }
        ]
    },
   
    {
        serviceId: "PromotionalGiveawaysCorporateGifts",
        testimonials: [
            {
                id: "RajeshKumar",
                quote: "The promotional giveaways we ordered from Giftingy were a hit at our corporate event. The quality and customization were top-notch!",
                name: "Rajesh Kumar",
                location: "New Delhi"
            },
            {
                id: "ShaliniMehta",
                quote: "Giftingy ne humare promotional gifts ko aur bhi special bana diya. Our clients were impressed with the personalized touch.",
                name: "Shalini Mehta",
                location: "Mumbai"
            },
            {
                id: "AnilGupta",
                quote: "We’ve been sourcing our promotional gifts from Giftingy for years, and they’ve never disappointed. The timely delivery and customer service are excellent.",
                name: "Anil Gupta",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "FoodandSpicesGiftng",
        testimonials: [
            {
                id: "RakeshMehra",
                quote: "Giftingy's gourmet food baskets were a hit with our clients. The quality was exceptional, and the presentation was impressive!",
                name: "Rakesh Mehra",
                location: "New Delhi"
            },
            {
                id: "SunitaRao",
                quote: "I loved the luxury spice hampers from Giftingy. They made the perfect gift for our international partners, showcasing the rich flavors of India.",
                name: "Sunita Rao",
                location: "Bangalore"
            },
            {
                id: "PriyaGupta",
                quote: "The customized food hampers for our employees were a great success. Giftingy delivered on time, and the products were top-notch.",
                name: "Priya Gupta",
                location: "Mumbai"
            }
        ]
    },
    {
        serviceId: "TeaCorporateGifts",
        testimonials: [
            {
                id: "AnilKumar",
                quote: "The tea gift hampers from Giftingy were a hit with our clients. The quality of the tea and the elegant packaging were outstanding!",
                name: "Anil Kumar",
                location: "Delhi"
            },
            {
                id: "PriyaVerma",
                quote: "Our employees loved the assorted tea gift sets from Giftingy. It was a thoughtful and well-received gift that stood out from the usual corporate gifts.",
                name: "Priya Verma",
                location: "Bangalore"
            },
            {
                id: "RajeshSharma",
                quote: "We chose Giftingy for our corporate gifting needs, and their gourmet tea gifts were the perfect choice. Highly recommended!",
                name: "Rajesh Sharma",
                location: "Mumbai"
            }
        ]
    },
    {
        serviceId: "CoffeeAsCorporateGift",
        testimonials: [
            {
                id: "MehulPatel",
                quote: "The coffee hampers from Giftingy were a huge hit with our clients. The quality of the coffee and the personalized touches were truly impressive!",
                name: "Mehul Patel",
                location: "Mumbai"
            },
            {
                id: "RadhikaSharma",
                quote: "Our employees loved the coffee gift sets from Giftingy. It was a perfect way to show our appreciation and boost morale!",
                name: "Radhika Sharma",
                location: "Delhi"
            },
            {
                id: "SureshNair",
                quote: "We chose Giftingy for our corporate coffee gifts, and their service exceeded our expectations. The hampers were beautifully curated and delivered on time.",
                name: "Suresh Nair",
                location: "Bangalore"
            }
        ]
    },
    {
        serviceId: "BlanketsforCorporateGifting",
        testimonials: [
            {
                id: "AartiMehta",
                quote: "The luxury blankets from Giftingy were a huge hit with our clients. The quality and customization options were outstanding!",
                name: "Aarti Mehta",
                location: "Mumbai"
            },
            {
                id: "RajeshGupta",
                quote: "We chose Giftingy for our employee gifts this year, and the personalized blankets were just perfect. Everyone loved them!",
                name: "Rajesh Gupta",
                location: "Bangalore"
            },
            {
                id: "PriyaSingh",
                quote: "Giftingy made our corporate gifting process seamless. The blankets were of premium quality, and the delivery was right on time.",
                name: "Priya Singh",
                location: "Mumbai"
            }
        ]
    },
];

const Testimonials = ({ serviceId = "service1" }) => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        const serviceTestimonials = testimonialsData.find(data => data.serviceId === serviceId);
        if (serviceTestimonials) {
            setTestimonials(serviceTestimonials.testimonials);
        }
    }, [serviceId]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div className="testimonials-container">
            <h2 className="section-title">What Our Clients Say</h2>
            {testimonials.length > 0 ? (
                <Slider {...settings}>
                    {testimonials.map(testimonial => (
                        <div key={testimonial.id} className="testimonial-item">
                            <p className="testimonial-quote">"{testimonial.quote}"</p>
                            <p className="testimonial-author">- {testimonial.name}, {testimonial.location}</p>
                        </div>
                    ))}
                </Slider>
            ) : (
                <p>No testimonials available for this service.</p>
            )}
        </div>
    );
};

export default Testimonials;
