import React from 'react';
import { useParams, Link } from 'react-router-dom';

const ProductDetail = () => {
  const { id } = useParams();

  // Assume you fetch product details based on id
  const product = {
    id: id,
    name: `Product ${id}`,
    description: `Detailed description of Product ${id}`
  };

  return (
    <div className="container">
      <h1>{product.name}</h1>
      <p>{product.description}</p>
      <Link to="/contact" className="btn btn-primary">Enquire</Link>
    </div>
  );
};

export default ProductDetail;
