// ToggleSection.js
import React, { useState } from 'react';
import '../components/InfoSection.css';

const ToggleSection = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const questions = [
        'Why Should You Engage in Corporate Gifting?',
        'When Should You Engage in Corporate Gifting?',
        'How Can You Source Corporate Gifting?',
        'How Do You Decide on Corporate Gifting?',
        'What is Corporate Gifting?',
        'Do Companies Follow a Corporate Gifting Strategy?',
        'Why Should You Brand Your Corporate Gifts?',
        'Benefits of Personalised Corporate Gifting',
    ];

    const answers = [
        (
            <ul>
                <li><b>Show Appreciation:</b> Recognize the efforts and contributions of employees and clients.</li>
                <li><b>Promote Brand Loyalty:</b> Thoughtful gifts enhance brand loyalty and recall.</li>
                <li><b>Celebrate Milestones:</b> Mark significant events and achievements with special gifts.</li>
                <li><b>Enhance Corporate Culture:</b> Foster a positive and inclusive corporate culture.</li>
                <li><b>Create Lasting Impressions:</b> Memorable gifts leave a lasting impression on recipients.</li>
            </ul>
        ),
        (
            <ul>
                <li><b>Festive Seasons:</b> Celebrate holidays and festivals with thoughtful gifts.</li>
                <li><b>Company Anniversaries:</b> Mark significant milestones and achievements.</li>
                <li><b>Employee Recognition:</b> Recognize and reward employees' hard work and dedication.</li>
                <li><b>Client Appreciation:</b> Show clients that you value their partnership.</li>
                <li><b>Special Events:</b> Celebrate special corporate events and occasions.</li>
            </ul>
        ),
        (
            <ul>
                <li><b>Identify Your Needs:</b> Understand the purpose and recipient of the gift.</li>
                <li><b>Set a Budget:</b> Determine your budget and find gifts that fit within it.</li>
                <li><b>Choose Quality Products:</b> Select high-quality products that reflect your brand's values.</li>
                <li><b>Personalise Your Gifts:</b> Add a personal touch to make the gifts more meaningful.</li>
                <li><b>Partner with a Reliable Supplier:</b> Work with a trusted corporate gifting company like Giftingy.</li>
            </ul>
        ),
        (
            <ul>
                <li><b>Know Your Audience:</b> Understand the preferences and needs of the recipients.</li>
                <li><b>Set Clear Objectives:</b> Define the purpose and goals of your corporate gifting strategy.</li>
                <li><b>Choose Appropriate Gifts:</b> Select relevant and meaningful gifts for the recipients.</li>
                <li><b>Consider Branding:</b> Customise the gifts with your company logo or branding elements.</li>
                <li><b>Plan Ahead:</b> Ensure timely delivery and distribution of the gifts.</li>
            </ul>
        ),
        (
            <ul>
                <li><b>What is Corporate Gifting?</b> Corporate gifting involves giving thoughtful gifts to employees, clients, and partners to show appreciation, celebrate milestones, and strengthen relationships. It is an essential aspect of business strategy that helps promote goodwill, enhance brand visibility and foster loyalty. Corporate gifts range from personalised items and branded merchandise to luxury hampers and bespoke gifts.</li>
            </ul>
        ),
        (
            <ul>
                <li><b>Do Companies Follow a Corporate Gifting Strategy?</b> Many companies follow a corporate gifting strategy to ensure their gifts are meaningful, timely, and aligned with their business objectives. A well-planned corporate gifting strategy can enhance brand loyalty, foster relationships, and create lasting impressions. Key elements of a corporate gifting strategy include understanding the recipients, setting clear objectives, choosing appropriate gifts, and ensuring timely delivery.</li>
            </ul>
        ),
        (
            <ul>
                <li><b>Enhances Brand Recall:</b> Customised gifts with your company logo increase brand recall.</li>
                <li><b>Promotes Brand Loyalty:</b> Branded gifts foster loyalty among recipients.</li>
                <li><b>Creates a Professional Image:</b> High-quality branded gifts reflect your company's professionalism and attention to detail.</li>
                <li><b>Increases Visibility:</b> Branded gifts serve as a constant reminder of your brand.</li>
                <li><b>Strengthens Relationships:</b> Personalised gifts show that you value the recipient.</li>
            </ul>
        ),
        (
            <ul>
                <li><b>Creates Lasting Impressions:</b> Personalised gifts leave a lasting impression on recipients.</li>
                <li><b>Enhances Emotional Connection:</b> Customised gifts create a stronger emotional connection.</li>
                <li><b>Reflects Thoughtfulness:</b> Personalization shows that you have put thought into the gift.</li>
                <li><b>Increases Brand Loyalty:</b> Personalised gifts foster loyalty and appreciation.</li>
                <li><b>Promotes Brand Visibility:</b> Custom gifts with your company logo enhance brand visibility.</li>
            </ul>
        ),
    ];

    const handleToggle = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className="toggle-container">
            <div className="questions-half">
                {questions.map((question, index) => (
                    <p
                        key={index}
                        className={`toggle-title ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => handleToggle(index)}
                    >
                        {question}
                    </p>
                ))}
            </div>
            <div className="answers-half">
                {answers.map((answer, index) => (
                    <div
                        key={index}
                        className={`toggle-content ${activeIndex === index ? 'active' : ''}`}
                    >
                        {answer}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ToggleSection;
