import React, { useState } from 'react';
import '../components/FaqHome.css';

const FAQ = ({ faqs }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container1 container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item1">
          <div
            className="faq-question1"
            onClick={() => toggleFAQ(index)}
          >
            {faq.question}
            <span className="faq-icon1">{activeIndex === index ? '-' : '+'}</span>
          </div>
          <div className={`faq-answer1 ${activeIndex === index ? 'open' : ''}`}>
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
