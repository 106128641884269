import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/eidgifimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalised Eid corporate gifts tailored to your preferences." },
    { title: "Branding on Gifts", detail: "Keeps your brand memorable and visible." },
    { title: "Value for Money", detail: "High-quality Eid gifts within your budget." },
    { title: "Concept Creation", detail: "Unique and innovative gift ideas." },
    { title: "Diverse Range of Gifts", detail: "Extensive selection for all festive occasions." },
    { title: "Quality Assurance", detail: "Premium quality guaranteed." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible gifting." },
];
const EidCorporateGifting = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
      }, []);
const faqs = [
    {
      "question": "What are good Eid corporate gift ideas?",
      "answer": "Good Eid corporate gift ideas include personalised items, branded merchandise, and premium gift sets. Popular choices are customised accessories, unique handcrafted items, and luxury products. Consider items like festive hampers, personalised gifts, and high-end tech gadgets. The key is to choose gifts that reflect the festive spirit and the recipient's preferences. Consulting an Eid corporate gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "Why is Eid corporate gifting important?",
      "answer": "Eid corporate gifting is important because it expresses appreciation and gratitude during the festive season. These gifts enhance the joy and spirit of celebrations, fostering stronger business relationships. Thoughtful and well-chosen gifts reflect the company's appreciation for employees, clients, and partners. They also promote a positive work environment and enhance brand visibility. Investing in quality Eid gifts signifies a commitment to hospitality and goodwill.",
      "open": false
    },
    {
      "question": "What are some popular Eid gifts for employees?",
      "answer": "Popular Eid gifts for employees include branded merchandise, customised accessories, and premium gift sets. Handcrafted traditional crafts, decorative home accents, and personalised gifts are also appreciated. Consider items like festive hampers, tech gadgets, and luxury products. These gifts reflect the company's appreciation and foster a positive work environment. Consulting an Eid corporate gifting company can help select the best employee gifts.",
      "open": false
    },
    {
      "question": "How do I choose the right Eid corporate gifts?",
      "answer": "Choosing the right Eid corporate gifts involves understanding the recipients' preferences and the cultural context of Eid. Opt for high-quality items that reflect thoughtfulness and the festive spirit. Personalisation can add a special touch, making the gifts more meaningful. Consider the practicality and relevance of the gifts to ensure they are appreciated and used. Consulting an Eid corporate gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "What are the benefits of working with an Eid corporate gifting company?",
      "answer": "Working with an Eid corporate gifting company ensures you receive high-quality, well-crafted items that reflect the festive spirit and the company's appreciation. These companies offer various customisation options to make your gifts unique and personal. They provide expertise in selecting and designing gifts that suit your needs and preferences. Reliable delivery services and excellent customer support are additional benefits. Partnering with a reputable company ensures a seamless and satisfactory gifting experience.",
      "open": false
    },
    {
      "question": "Can I order Eid gifts for clients in bulk?",
      "answer": "You can order Eid gifts for clients in bulk from various gifting companies. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting an Eid corporate gifting company can provide valuable insights and options for bulk orders.",
      "open": false
    },
    {
      "question": "What are some premium Eid gift options?",
      "answer": "Premium Eid gift options include bespoke jewellery, luxury gift sets, and high-end tech gadgets. Handcrafted items such as traditional crafts, decorative home accents, and personalised accessories are also appreciated. Consider items like festive hampers, fine wines, and gourmet foods. These gifts reflect thoughtfulness and the festive spirit, making them ideal for premium Eid gifting. Consulting a premium Eid gifting company can provide additional ideas and options.",
      "open": false
    },
    {
      "question": "How do Eid gifts enhance corporate celebrations?",
      "answer": "Eid gifts enhance corporate celebrations by adding a touch of thoughtfulness and appreciation. They are ideal for occasions such as Eid al-Fitr and Eid al-Adha. These gifts reflect the company's commitment to quality and excellence. Customised Eid gifts make the celebration more memorable and meaningful. They foster a positive and respectful corporate culture. Consulting an Eid corporate gifting company can help select the best gifts for corporate celebrations.",
      "open": false
    },
    {
      "question": "What are some unique Eid gift ideas?",
      "answer": "Unique Eid gift ideas include bespoke jewellery, customised accessories, and handcrafted souvenirs. High-end luxury tech gadgets, fine wines, and gourmet foods are also popular. Consider items like festive hampers, personalised gifts, and exclusive branded merchandise. The key is to choose gifts that reflect the festive spirit and the recipient's preferences. Consulting an Eid corporate gifting company can provide additional ideas and options.",
      "open": false
    },
    {
      "question": "Why should companies invest in branded Eid gifts?",
      "answer": "Companies should invest in branded Eid gifts to show appreciation and recognition for their employees, clients, and partners. These gifts enhance corporate relationships and convey a commitment to quality and excellence. Branded Eid gifts are highly valued and leave a lasting impression on the recipients. They also foster a positive and respectful corporate culture. Investing in branded Eid gifts promotes a strong and prestigious brand image.",
      "open": false
    },
    {
      "question": "How can I ensure the quality of Eid corporate gifts?",
      "answer": "Ensuring the quality of Eid corporate gifts involves selecting reputable gifting companies and suppliers. Check reviews and testimonials to gauge the reliability and quality of their products. Opt for companies with a proven track record of high-quality craftsmanship and customisation. Regularly review samples and provide clear specifications for the customisation. Partnering with a trusted Eid corporate gifting company ensures the quality and satisfaction of your gifts.",
      "open": false
    },
    {
      "question": "What are the latest trends in Eid corporate gifting?",
      "answer": "The latest trends in Eid corporate gifting include eco-friendly products, tech gadgets, and bespoke gift sets. Sustainable and environmentally friendly items are increasingly popular. Personalised Eid gifts such as custom jewellery and engraved accessories are in demand. Handcrafted items that reflect traditional craftsmanship are also trending. Staying updated with these trends ensures your gifts are relevant and appreciated.",
      "open": false
    },
    {
      "question": "What are the benefits of customising Eid corporate gifts?",
      "answer": "Customising Eid corporate gifts adds a personal touch that enhances the gift's value and meaning. It shows that extra thought and effort have been put into selecting the gift. Personalisation can include engraving names, adding personal messages, or incorporating the company logo. Customised gifts are more memorable and appreciated by the recipients. They help in fostering stronger personal and corporate relationships.",
      "open": false
    },
    {
      "question": "How do Eid gifts benefit employee recognition programs?",
      "answer": "Eid gifts benefit employee recognition programs by adding a touch of appreciation and recognition to the festive celebrations. They make employees feel valued and appreciated, boosting morale and motivation. Customised Eid gifts reflect the company's commitment to recognising individual achievements. They enhance job satisfaction and loyalty, promoting a positive work environment. Eid gifts are more impactful and memorable than generic awards.",
      "open": false
    },
    {
      "question": "Can Eid gifts be ordered in bulk?",
      "answer": "Yes, Eid gifts can be ordered in bulk from most gifting companies. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting an Eid corporate gifting company can provide valuable insights and options for bulk orders.",
      "open": false
    },
    {
      "question": "What are some cost-effective Eid gift options?",
      "answer": "Cost-effective Eid gift options include personalised accessories, branded merchandise, and handcrafted souvenirs. Consider items like festive hampers, premium chocolates, and customised stationery. The key is choosing gifts that reflect the festive spirit and the recipient's preferences without compromising quality. Cost-effective gifts can still make a significant impact when chosen thoughtfully. Consulting an Eid corporate gifting company can provide additional ideas and options.",
      "open": false
    },
    {
      "question": "Why is personalisation important in Eid corporate gifting?",
      "answer": "Personalisation is important in Eid corporate gifting because it adds a unique and meaningful touch to the gifts. It shows that the giver has put thought into selecting the gift, making it more memorable and appreciated. Personalised gifts reflect the recipient's tastes and preferences. They enhance the overall impact of the gift, fostering stronger relationships. Personalisation also helps in promoting brand recall and loyalty.",
      "open": false
    },
    {
      "question": "How can Eid gifts be used for client retention?",
      "answer": "Eid gifts can be used to retain clients by showing appreciation and strengthening relationships. Customised gifts reflect thoughtfulness and attention to detail, making clients feel valued. They create a positive association with the brand, fostering loyalty. Regular gifting of Eid items can enhance client satisfaction and retention rates. Eid gifts are a constant reminder of the company's appreciation and recognition.",
      "open": false
    },
    {
      "question": "What are some good Eid gifts for employees?",
      "answer": "Good Eid employee gifts include customised accessories, branded merchandise, and premium gift sets. Consider items like tech gadgets, personalised stationery, and luxury products. These gifts reflect the company's appreciation and foster a positive work environment. Customisation with the company's logo or festive branding adds a personal touch. Consulting an Eid corporate gifting company can help select the best employee gifts.",
      "open": false
    },
    {
      "question": "How do Eid gifts enhance personal celebrations?",
      "answer": "Eid gifts enhance personal celebrations by adding a touch of joy and appreciation to the festivities. They are ideal for occasions such as Eid al-Fitr and Eid al-Adha. These gifts reflect thoughtfulness and the festive spirit, making the celebration more memorable. Personalised gifts, luxury items, and handcrafted souvenirs contribute to the overall enjoyment of the celebration. Consulting an Eid corporate gifting company can provide additional ideas and options.",
      "open": false
    }
  ]  
  
return (
<>
<MetaTags
        title="Premier Eid Corporate Gifting Company in India"
        description="Discover exquisite Eid corporate gifts and premium branded gifts from India's leading Eid corporate gifting company. Perfect for employees and clients."
        keywords="Eid corporate gifting company, Eid corporate gifting companies in India, Eid corporate gift ideas, Eid corporate gifting firm, Unique Eid gifting company, Personalised Eid corporate gifts, Personalised Eid corporate gifting company, Personalised Eid corporate gift ideas, Branded Eid gifting, Eid branded gifts company, Branded Eid gifting company, Eid gifts for employees, Eid gifts for clients"
      />
<ServiceHeader title="Eid Corporate Gifting" imageUrl={`${process.env.PUBLIC_URL}/images/eidgfbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Significance of Eid Corporate Gifting</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Eid is a time of celebration, gratitude, and giving. Corporate gifting during Eid holds a special significance as it fosters goodwill, strengthens business relationships, and shows appreciation to employees and clients. Eid corporate gifts range from personalised items and branded merchandise to unique and luxurious gifts. Choosing the right gift can enhance the festive spirit and leave a lasting impression.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Eid corporate gifting companies in India offer various options to cater to different tastes and preferences. From unique Eid gifting ideas to personalised and branded gifts, these companies ensure that every gift is special. Eid gifts for employees and clients are particularly important as they reflect the company's appreciation and foster a positive work environment. Customisation and branding make these gifts even more impactful.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Selecting the perfect Eid corporate gift involves understanding the recipient's preferences and the cultural context of Eid. Here are some key aspects that make Eid corporate gifting a popular choice:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhances the festive spirit and joy</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Strengthens business relationships</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Reflects appreciation and gratitude</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Suitable for various types of recipients</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Offers a wide range of customisation options</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Ideal for both employees and clients</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Available in premium and branded options</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes lasting memories and connections</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Supports local artisans and craftsmanship</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Provides a memorable and impactful gifting experience</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>Eid corporate gifting is more than just a tradition; it celebrates it's relationships and goodwill.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Eid Corporate Gifting?</h2>
      <p >Selecting the perfect Eid corporate gifts requires a keen understanding of cultural nuances and a commitment to quality. Giftingy is a premier Eid corporate gifting company offering a curated selection of the finest Eid gifts. Here's why Giftingy should be your top choice for Eid corporate gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/eidgifimg2.jpg`} alt="Customisable Eid Gifts" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we specialise in creating Eid corporate gifts that resonate with the festive spirit and reflect the company's appreciation. Our Eid corporate gift ideas include beautifully crafted items, from personalised accessories and branded merchandise to unique and luxurious gifts. Whether you are looking for Eid gifts for employees or clients, our collection caters to diverse tastes and preferences.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               One of our key strengths is our ability to offer customised Eid gifts that reflect the recipient's preferences and the cultural context of Eid. We pride ourselves on being a leading personalised Eid corporate gifting company that delivers excellence in craftsmanship. Our products are sourced from the best manufacturers, ensuring that every item meets the highest standards of quality and design.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy also specialises in branded Eid gifting, making us the perfect partner for corporate celebrations. Our seamless and hassle-free process ensures that your gifts are delivered on time and in perfect condition. From unique Eid gifting ideas to branded gifts for employees and clients, we offer various choices to cater to your needs. Our commitment to customer satisfaction and ability to create lasting impressions make us the preferred choice for Eid corporate gifting in India.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisable Eid gifts to suit individual preferences
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality products with meticulous craftsmanship
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide range of Eid gifting ideas for various recipients
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise as an Eid corporate gifting company in India
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Convenient online ordering and customisation options
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable and timely delivery services
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Commitment to customer satisfaction
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive selection of beautifully crafted Eid gifts
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your Eid corporate gifting needs ensures that your gifts are beautiful, meaningful, and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Source for Exceptional Eid Corporate Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on offering exceptional Eid corporate gifting solutions catering to your needs and preferences. Our unique selling propositions (USPs) set us apart as India's leading Eid corporate gifting company. Here's what makes us unique:
            </p>
            <p className='wow fadeInUp section-partpara section-partlheight'>Giftingy offers custom designs that add a personal touch to your Eid corporate gifts, making them more meaningful and memorable. Our branding on gifts ensures that your company remains at the forefront of the recipient's mind, enhancing brand recall and visibility. We provide value for money by offering high-quality gifts that fit within your budget, ensuring you get the best return on your investment.</p>
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/eidgifimg3.jpg`} alt="Eid Corporate Gifting Solutions" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="luxuryHomeDecor" />

<FaqPage faqs={faqs} />
</>
);
};
export default EidCorporateGifting;