import React from 'react';

import 'animate.css';
import '../pages/services/Services.css'; // Importing CSS for styling
import ServiceHeader from '../pages/services/ServiceHeader'; // Importing the ServiceHeader component

export const Thankyou = () => {
    return (
        <ServiceHeader title="Thank you for submitting!" imageUrl={`${process.env.PUBLIC_URL}/images/thankyouimgs.jpg`} />
    );
};
