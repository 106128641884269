import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/dussheraimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: " Personalised Dussehra gifts that reflect your brand's values." },
    { title: "Quality Assurance", detail: "High-quality gifts that resonate with the festive spirit." },
    { title: "Timely Delivery", detail: "Reliable service with on-time delivery." },
    { title: "Bulk Order Expertise", detail: "Efficient handling of large corporate orders." },
    { title: "Eco-Friendly Options", detail: "Sustainable gifts that align with your company's CSR goals." },
    { title: "Memorable Gifts", detail: "Festive gifts that leave a lasting impression." },
    { title: "Diverse Range", detail: "We offer a wide variety, from traditional sweets to luxury hampers." },
    { title: "Client and Employee Focus", detail: "Tailored gifts that appeal to clients and employees." },
];
const Dussehracorporategifts = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
      }, []);
const faqs =[
    {
      "question": "What are popular Dussehra corporate gifts for employees?",
      "answer": "Popular Dussehra corporate employee gifts include traditional sweets, festive gift hampers, decorative items, and personalised gifts. These gifts reflect the spirit of the festival and show appreciation for employees' hard work and dedication. Giftingy offers a wide range of thoughtful and festive Dussehra corporate gifts for employees, ensuring that your employees feel valued and appreciated during this special time of the year.",
      "open": false
    },
    {
      "question": "How can I customise Dussehra corporate gifts for clients?",
      "answer": "Customising Dussehra corporate gifts for clients involves adding your company logo, branding, or a personalised message. Giftingy offers various customisation options, from branded packaging to personalised gift tags, ensuring that your gifts reflect your company's identity. Our Dussehra corporate gifts India collection includes multiple customisable options to make your gifts stand out and leave a lasting impression on your clients.",
      "open": false
    },
    {
      "question": "What are the benefits of ordering Dussehra corporate gifts in bulk?",
      "answer": "Ordering Dussehra corporate gifts in bulk offers several advantages, including cost savings, consistency in gift presentation, and the convenience of managing large-scale corporate gifting. Giftingy specialises in Dussehra corporate gifts bulk orders, providing competitive pricing and ensuring that all gifts meet the same high standards. Bulk ordering also allows for efficient planning and timely delivery, making your corporate gifting process seamless.",
      "open": false
    },
    {
      "question": "What are the best Dussehra gift hampers for corporate clients?",
      "answer": "The best Dussehra gift hampers for corporate clients typically include a combination of traditional sweets, dry fruits, decorative items, and premium products such as gourmet chocolates or luxury tea sets. Giftingy offers a variety of Dussehra Gift Hampers for Corporate clients, each thoughtfully curated to reflect the festive spirit while conveying your appreciation. Our hampers are designed to impress, making them ideal for client gifting.",
      "open": false
    },
    {
      "question": "Why should I choose Giftingy for my Dussehra corporate gifting needs?",
      "answer": "Choosing Giftingy for your Dussehra corporate gifting needs ensures you receive high-quality, thoughtfully curated gifts reflecting the festival's spirit. With our extensive range of Corporate Dussehra Gifts, customisation options, and expertise in bulk orders, we provide a seamless and impactful gifting experience. Our commitment to quality, timely delivery, and customer satisfaction makes us the preferred choice for many businesses during the festive season.",
      "open": false
    },
    {
      "question": "What are some unique Dussehra corporate gift ideas for clients?",
      "answer": "Unique Dussehra corporate gift ideas for clients include personalised items such as engraved silverware, custom-made festive decor, or curated gourmet hampers that reflect the essence of the festival. At Giftingy, we offer a variety of Dussehra corporate gifts for clients that stand out for their uniqueness and thoughtfulness. These gifts can be tailored to your client's preferences, making them a memorable gesture that strengthens business relationships.",
      "open": false
    },
    {
      "question": "How do I choose the right supplier for Dussehra corporate gifts in India?",
      "answer": "Choosing the right supplier for Dussehra corporate gifts involves considering product quality, customisation options, delivery reliability, and pricing. Giftingy is a trusted supplier of Dussehra corporate gifts in India, known for our high-quality products and exceptional customer service. We offer a wide range of gifting solutions, from traditional hampers to modern corporate gifts, ensuring that your choices meet the diverse needs of your business.",
      "open": false
    },
    {
      "question": "What are the advantages of using a Dussehra corporate gifting vendor?",
      "answer": "Working with a Dussehra corporate gifting vendor like Giftingy provides numerous benefits, including access to a broad range of gift options, customisation services, and expertise in bulk orders. Vendors streamline the gifting process, handling everything from product selection to delivery, ensuring that your gifts are of the highest quality and reach recipients on time. This convenience allows businesses to focus on other important aspects while ensuring their corporate gifting is impactful.",
      "open": false
    },
    {
      "question": "Can I get eco-friendly Dussehra corporate gifts for my company?",
      "answer": "Giftingy offers eco-friendly Dussehra corporate gifts that align with your company's sustainability goals. These gifts are made from sustainable materials and are designed to be environmentally responsible. Whether organic food hampers, biodegradable packaging, or handmade eco-friendly decor, our collection includes various options that reflect your company's commitment to the environment while celebrating the festival.",
      "open": false
    },
    {
      "question": "What are some cost-effective Dussehra corporate gift options?",
      "answer": "Cost-effective Dussehra corporate gift options include traditional sweets, dry fruit boxes, festive decor, and small personalised items. These gifts are budget-friendly yet convey your appreciation effectively. Giftingy provides a range of affordable Dussehra corporate gifts in bulk that are perfect for businesses looking to manage costs while making a positive impression on their employees and clients. Our cost-effective options maintain quality and presentation.",
      "open": false
    },
    {
      "question": "How can Dussehra corporate gifts help in enhancing employee engagement?",
      "answer": "Dussehra corporate gifts can significantly improve employee engagement by making employees feel valued and appreciated. Thoughtful gifts during this festive season boost morale and create a sense of belonging, which can lead to higher job satisfaction and productivity. Giftingy offers a variety of Dussehra corporate gifts for employees that are designed to celebrate the festival while acknowledging your staff's hard work and dedication.",
      "open": false
    },
    {
      "question": "What makes Dussehra a significant time for corporate gifting?",
      "answer": "Dussehra is a considerable time for corporate gifting because it marks the victory of good over evil, symbolising new beginnings and success. It is an occasion that resonates deeply with cultural values, making it an ideal time to strengthen business relationships and show appreciation to employees and clients. Corporate gifting during Dussehra reflects the company's alignment with these cultural sentiments, promoting goodwill and positive business outcomes.",
      "open": false
    },
    {
      "question": "Are there any specific guidelines to follow when selecting Dussehra corporate gifts?",
      "answer": "When selecting Dussehra corporate gifts, it's important to consider the festival's cultural significance, the recipients' preferences, and the message you wish to convey. Gifts should be thoughtful, meaningful, and reflect the spirit of Dussehra. Choosing high-quality gifts that can be personalised to make a lasting impact is also advisable. Consulting with a gifting vendor like Giftingy can help you navigate these considerations effectively.",
      "open": false
    },
    {
      "question": "What are some popular Dussehra gift hampers for corporate clients?",
      "answer": "Popular Dussehra gift hampers for corporate clients often include a mix of traditional sweets, dry fruits, gourmet snacks, and festive decor items. These hampers are designed to reflect the festive spirit while offering a luxurious experience. Giftingy's selection of Dussehra Gift Hampers for Corporate clients is curated to impress, offering a blend of tradition and modernity that makes these hampers perfect for the occasion.",
      "open": false
    },
    {
      "question": "How does Giftingy ensure the quality of Dussehra corporate gifts?",
      "answer": "Giftingy ensures the quality of Dussehra corporate gifts by sourcing products from reputable suppliers, conducting thorough quality checks, and offering customisation options that meet high standards. Our commitment to quality extends from the selection of gifts to their packaging and delivery. We strive to provide corporate gifts that meet and exceed your expectations, ensuring that your Dussehra gifting is meaningful and memorable.",
      "open": false
    }
  ]
  
return (
<>
<MetaTags
        title="Top Dussehra Corporate Gifts Company in India"
        description="Explore a wide range of Dussehra corporate gifts, including gift hampers, for employees and clients. Giftingy offers customised and bulk orders for all your corporate needs."
        keywords="Dussehra Corporate gifts, Dussehra Corporate Gifts India, Dussehra Corporate Gifts Company, Dussehra corporate gifting vendor, Supplier of Dussehra corporate gifts, Dussehra corporate gifts for employees, Dussehra corporate gifts for clients, Dussehra corporate gifts bulk, Corporate Dussehra Gifts, Dussehra Gift Hampers bulk buy, Dussehra Gift Hampers for Corporate, Dussehra Gift for Corporate"
      />
<ServiceHeader title="Dussehra Corporate Gifts" imageUrl={`${process.env.PUBLIC_URL}/images/dussherabn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">Celebrate Dussehra with Thoughtful Corporate Gifts</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Dussehra, a festival symbolising the victory of good over evil, is an opportune time for companies to express gratitude towards their employees and clients. Thoughtful Dussehra Corporate Gifts can strengthen business relationships, boost employee morale, and reinforce the company's brand values. Whether you are looking for elegant Dussehra gift hampers for corporate celebrations or personalised gifts, selecting the right corporate gifts can make a significant impact.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      In India, the tradition of gifting during Dussehra has evolved, with companies seeking unique and meaningful gifts that reflect the festival's spirit. The options are vast and varied, from Dussehra corporate gifts for employees to special client hampers. These gifts serve as tokens of appreciation and reinforce the company's commitment to fostering strong relationships and promoting a positive corporate culture.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Here's why Dussehra is the perfect occasion for corporate gifting:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Cultural Significance: Dussehra is a time for new beginnings and celebrations.
               </p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Strengthens Relationships: Gifts foster goodwill and strengthen bonds with clients and employees.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Reflects Appreciation: A well-chosen gift shows that the company values its stakeholders.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes Brand Visibility: Customised gifts with the company logo enhance brand recall.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Encourages Loyalty: Thoughtful gifts can increase employee satisfaction and client retention.</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Suitable for All: A wide range of gifts can cater to different tastes and preferences.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Boosts Morale: Employees feel appreciated and motivated.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Memorable Impact: Festive gifts create lasting memories and positive associations with the brand.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Versatile Options: The options are endless, from small tokens to luxury hampers.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Bulk Buying Advantage: Bulk purchases offer cost savings while ensuring consistency in gifting.</p>
            </li>
         </div>
      </ul>
     
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Dussehra Corporate Gifts?</h2>
      <p >Here's why Giftingy is the preferred choice for Dussehra corporate gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/dussheraimg2.jpg`} alt="Festive Dussehra Corporate Gifting Ideas" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy is a leading Dussehra corporate gifts company in India, offering various festive gifts tailored to corporate needs. We understand that Dussehra is not just a festival but an opportunity to celebrate and strengthen relationships. Our carefully curated collection of Corporate Dussehra Gifts includes everything from traditional sweets and decorative items to luxury gift hampers, ensuring something special for everyone.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               At Giftingy, we are a trusted Dussehra corporate gifting vendor known for our commitment to quality and timely delivery. Our gifts are sourced from the best suppliers, ensuring that each item reflects the spirit of Dussehra while meeting corporate standards. Whether you need Dussehra corporate gifts for employees or unique hampers for clients, we have the expertise to make your corporate gifting experience seamless and memorable.
               </p>
            </div>
       
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide Range of Gifts: We offer diverse gifts catering to various tastes and budgets.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisation Available: Personalise your gifts with company logos or special messages.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Quality Assurance: Each gift is carefully selected to meet our high standards.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable Delivery: We ensure that your gifts are delivered on time, every time.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Bulk Order Expertise: Our bulk buying options allow cost-effective and consistent gifting.

            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Festive Packaging: Our gifts come in beautifully designed packaging that enhances the festive spirit.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Eco-Friendly Options: We offer sustainable gifts that align with corporate social responsibility goals.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Client and Employee-Focused: Our gifts are designed to appeal to clients and employees, making them versatile for any corporate need.
            </p>
         </div>
         <p className='textWhite'>Expertise in Corporate Gifting: With years of experience, we understand the nuances of corporate gifting and provide tailored solutions.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy </h2>
            <h3 className='textYellow'>Giftingy: Your Partner for Exceptional Dussehra Corporate Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we aim to make your Dussehra celebrations memorable with our exceptional corporate gifts. Our unique selling propositions (USPs) ensure that your gifting experience is both seamless and impactful:
            </p>
           
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/dussheraimg3.jpg`} alt="Elegant Dussehra Corporate Gifts" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="Dussehracorporategifts" />

<FaqPage faqs={faqs} />
</>
);
};
export default Dussehracorporategifts;