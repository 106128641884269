import React from 'react';
import './HomeSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function HomeSection() {
 
  

  return (
    <div className="home-section-container">
      <div className="home-section-content">
        <div className="home-section-left home4ttab">
          <div className="grid-container">
            <div className="grid-box">
              <img src="/images/square2.jpg" alt="Image 1" />
            </div>
            <div className="grid-box1">
              <h2 className="grid-box1ttext">Home Decor</h2>
              {/* <p className="grid-box1ttext">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p> */}
              {/* <button className="arrow-button">View More
                <FontAwesomeIcon icon={faArrowRight} />
              </button> */}
            </div>
            <div className="grid-box1">
              <h2 className="grid-box1ttext">Festive Gifting</h2>
              {/* <p className="grid-box1ttext">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
              <button className="arrow-button">View More
                <FontAwesomeIcon icon={faArrowRight} />
              </button> */}
            </div>
            <div className="grid-box">
              <img src="/images/square1.jpg" alt="Image 2" />
            </div>
          </div>
        </div>
        <div className="home-section-right">
          <h2 className='sideheeading'>The Need for Corporate Gifting</h2>
          <p>Corporate gifting plays a crucial role in the business world. It helps build strong relationships, fosters goodwill and enhances brand visibility. Understanding the purpose and value of corporate gifting is essential for any successful business strategy. Here are some key reasons why corporate gifting is important:</p>
          <ul className='listsit'>
            <li><b>Builds Strong Relationships:</b> Strengthen bonds with clients, employees, and partners.</li>
            <li><b>Fosters Goodwill:</b> Show appreciation and create a positive impression.</li>
            <li><b>Enhances Brand Visibility:</b> Promote your brand with customized gifts.</li>
            <li><b>Boosts Employee Morale:</b> Recognize and reward employees' hard work and dedication.</li>
            <li><b>Increases Customer Loyalty:</b> Show clients that you value their business.</li>
        </ul>
          <a href='/contact' className="right-bottom-button">Learn More</a>
        </div>
      </div>
    </div>
  );
}

export default HomeSection;
